import { useState, default as React, useEffect } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Avatar, IconButton, Button } from '@material-ui/core';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import routes from '@/admin/constants/routes';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { indexLicenses } from '@/admin/components/license/license';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { useParams, useLocation } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Variants } from '@/common/components/messages/CommonMessage';
import dayjs from 'dayjs';
import { handleApiError } from '@/common/utils/errorHandler';


const styles = (theme: Theme) => createStyles({
    button: {
        borderRadius: '50px',
        padding: '7px 17px',
        marginRight: '16px',
        minWidth: '120px',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            padding: '6px 14px',
            minWidth: '100px',
            fontSize: '0.9em',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '5px 10px',
            minWidth: '80px',
            fontSize: '0.8em',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '4px 8px',
            minWidth: '60px',
            fontSize: '0.7em',
        },
    },
    buttonText: {
        margin: '0 8px',
    },
    licenseKey: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '5px 5px',
        marginRight: '0px',
        whiteSpace: 'nowrap',
        display: 'block',
    },
    licenseBox: {
        fontSize: '1.0em',
        margin: '0px',
        width: '220px',
        display: 'inline-block',
        flexShrink: 0,
    },
    smallIconButton: {
        padding: '5px',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    headerItem: {
        marginLeft: '20px',
    },
    scrollableContainerWrapper: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '8px',
        maxWidth: '1200px',
        marginLeft: '30px',
    },
    scrollableContainer: {
        maxHeight: '800px',
        minWidth: '500px',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    avatarIcon: {
        borderRadius: 0,
        width: 24,
        height: 24,
        opacity: 0.6,
    },
    avatarIconWhite: {
        borderRadius: 0,
        width: 24,
        height: 24,
        filter: 'invert(1)',
    },
    avatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: 30,
        height: 30,
        overflow: 'hidden',
        border: '1px solid #ccc',
        flexShrink: 0,
    },
});

interface RouteParams {
    id: string;
}

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const { id } = useParams<RouteParams>();
    useTitle(locale.t(locale.keys.orderOCRLicenseDetail.index));
    const appContainer = AdminAppContainer.useContainer();
    const { classes } = props;
    const [licenses, setLicenses] = useState<schema.V1ObjectsLicenseNew[]>([]);
    const [firstPlanName, setFirstPlanName] = useState<string | null>(null);
    const [limit, setLimit] = useState<number | undefined>(undefined);
    const isLargeScreen = useMediaQuery('(min-width:1500px)');
    const isMediumScreen = useMediaQuery('(min-width:1100px)');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await indexLicenses(appContainer.values.authorizationCode, id);
                if ('newLicenses' in result) {
                    setLicenses(result.newLicenses || []);
                    setLimit(result.newLicenses ? result.newLicenses.length : 0);
                    if (result.newLicenses && result.newLicenses.length > 0) {
                        setFirstPlanName(result.newLicenses[0].plan.name);
                    }
                } else {
                    setLicenses([]);
                }
            }
            catch (error) {
                handleApiError( appContainer,error,);
            }
        };
        fetchData();
    }, []);

    const handleCopy = (licenseKey: string) => {
        navigator.clipboard.writeText(formatLicenseKey(licenseKey));
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen: true,
            message: locale.t(locale.keys.orderOCRLicenseDetail.button.description1),
            variant: Variants.success,
        });
    };

    const handleBulkCopy = () => {
        const allLicenseKeys = licenses.map(license => license.licenseKey && (formatLicenseKey(license.licenseKey))).join('\n');
        navigator.clipboard.writeText(allLicenseKeys);
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen: true,
            message: locale.t(locale.keys.orderOCRLicenseDetail.button.description2),
            variant: Variants.success,
        });
    };

    const handleDownload = () => {
        const allLicenseKeys = licenses.map(license => license.licenseKey && (formatLicenseKey(license.licenseKey))).join('\n');
        const blob = new Blob([allLicenseKeys], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        const formattedDate = dayjs().format('YYYYMMDDHHmmss');
        const fileName = `${firstPlanName}_${formattedDate}.txt`;
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
    };

    const formatLicenseKey = (key: string) => {
        return key.replace(/(.{3})(.{4})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4-$5');
    };

    return (
        <>
            <SubHeader title={locale.t(locale.keys.orderOCRLicenseDetail.index)} isBack={routes.license.order} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <h4 style={{ marginLeft: '20px' }}>
                        {locale.t(locale.keys.orderOCRLicenseDetail.plan)}
                        {firstPlanName && ` ${firstPlanName}`}
                    </h4>
                </div>
                <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleBulkCopy}
                    >
                        <Avatar className={classes.avatarIconWhite} src="/assets/images/logo/copy.svg" />
                        <div>
                            {locale.t(locale.keys.orderOCRLicenseDetail.button.copy)}
                        </div>
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleDownload}
                    >
                        <GetAppIcon style={{ marginRight: '8px' }} />
                        <div>
                            {locale.t(locale.keys.orderOCRLicenseDetail.button.download)}
                        </div>
                    </Button>
                </div>
            </div>
            <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                <h4>{locale.t(locale.keys.orderOCRLicenseDetail.count, { n: limit })}</h4>
            </div>
            <div className={classes.scrollableContainerWrapper}>
                <div className={classes.scrollableContainer}>
                    <Grid className={classes.headerContainer} container direction="row"  spacing={0}>
                        {licenses.map((license, index) => (
                            <Grid key={index} item>
                                <div className={classes.licenseBox}>
                                    {license.licenseKey && <span className={classes.licenseKey}>{formatLicenseKey(license.licenseKey)}</span>}
                                </div>
                                <IconButton onClick={() => license.licenseKey && handleCopy(license.licenseKey)}>
                                    <div className={classes.avatarWrapper}>
                                        <Avatar className={classes.avatarIcon} src="/assets/images/logo/copy.svg" />
                                    </div>
                                </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);