import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Dialog, DialogContent, DialogActions, Button, DialogTitle, IconButton } from '@material-ui/core';
import locale from '@/common/utils/locale';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
    createStyles({
        actionButton: {
            marginRight: theme.spacing.unit * 2,
            '&& button': {
                textTransform: 'unset',
            },
        },
        okBtn: {
            color: '#ff7300',
            border: 'none',
        },
        body: {
            '& *': {
                fontSize: '1.25rem',
                color: 'black',
                fontWeight: 400,
            },
        },
        title: {
            '& h6': {
                fontWeight: 600,
                color: '#FFFFFF',
                fontSize: '0,875rem',
            },
        },
        closeButton: {
            color: '#FFFFFF',
            position: 'absolute',
            right: 0,
            top: 0,
        },
    });

interface AlertActionsProps extends WithStyles<typeof styles> {
    onClose: () => void;
}

const AlertActions = withStyles(styles)((props: AlertActionsProps) => {
    return (
        <>
            <Button size="small" color="primary" onClick={props.onClose}>
                {locale.t(locale.keys.action.close)}
            </Button>
        </>
    );
});

interface ConfirmActionsProps extends WithStyles<typeof styles> {
    callBack: (value?: string) => void;
    onClose: () => void;
    disabled?: boolean; // okボタンのdisabled
}

const ConfirmActions = withStyles(styles)((props: ConfirmActionsProps) => {
    const { classes } = props;
    return (
        <>
            <Button size="small" color="primary" onClick={props.onClose}>
                {locale.t(locale.keys.action.cancel)}
            </Button>
            <Button
                variant="outlined"
                className={classes.okBtn}
                onClick={() => {
                    props.callBack();
                }}
                disabled={props.disabled}
            >
                {locale.t(locale.keys.action.ok)}
            </Button>
        </>
    );
});

interface NoticeActionsProps extends WithStyles<typeof styles> {
    callBack: (value?: string) => void;
    onClose: () => void;
}

const NoticeActions = withStyles(styles)((props: NoticeActionsProps) => {
    const { classes } = props;
    return (
        <Button
            variant="outlined"
            className={classes.okBtn}
            onClick={() => {
                props.callBack();
            }}
        >
            {locale.t(locale.keys.action.ok)}
        </Button>
    );
});

export interface LicenseConfirmDialogBaseProps {
    callBack: (value?: string) => void;
    onClose: () => void;
    isOpen: boolean;
    title: string;
    renderChildren: () => JSX.Element;
    type?: 'alert' | 'confirm' | 'notice';
    disabled?: boolean; // okボタンのdisabled
}

export interface Props extends WithStyles<typeof styles>, LicenseConfirmDialogBaseProps {}

const LicenseConfirmDialogBase: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    // 本文が空状態のコンポーネント表示を防ぐため
    if (!props.isOpen) return <></>;

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
             <DialogTitle
                id="alert-dialog-title"
                className={classes.title}
                style={{
                    textAlign: 'center',
                    backgroundColor: '#143b4e',
                    padding: '5px',
                    maxWidth: 'lg',
                }}
            >
                {props.title}
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.body}>{props.renderChildren()}</DialogContent>
            <DialogActions>
                <div className={classes.actionButton}>
                    {props.type === 'alert' ? (
                        <AlertActions onClose={props.onClose} />
                    ) : props.type === 'notice' ? (
                        <NoticeActions callBack={props.callBack} onClose={props.onClose} />
                    ) : (
                        <ConfirmActions callBack={props.callBack} onClose={props.onClose} disabled={props.disabled}/>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(LicenseConfirmDialogBase);
