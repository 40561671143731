import React from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Divider, RadioGroup, Radio, FormControl, FormGroup, FormControlLabel, Checkbox, Typography, FormHelperText } from '@material-ui/core';
import { EntryDetailContainer } from './EntryDetailContainer';
import ClearIcon from '@material-ui/icons/Clear';
import Schedule from '@material-ui/icons/Schedule';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import moment from 'moment';
import { DateFormat } from '@/common/constants/dateFormat';
import locale from '@/common/utils/locale';

const basicInfoStyles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '23px',
        },
        divider: {
            marginTop: '20px',
        },
        dividerFooter: {
            marginTop: '20px',
            marginBottom: '60px',
        },
        groupGrid: {
            paddingTop: '10px',
            paddingLeft: '36px',
            paddingRight: '36px',
        },
        itemGrid: {
            width: '400px',
            paddingTop: '10px',
            paddingRight: '30px',
        },
        itemName: {
            width: '125px',
        },
        itemSeparate: {
            marginLeft: '15px',
            marginRight: '15px',
        },
        clearIconColor: {
            color: '#fb1E00',
        },
        scheduleIconColor: {
            color: '#ff7300',
        },
        doneIconColor: {
            color: '#43a047',
        },
        hyphenItem: {
            marginLeft: '30px',
            marginRight: '30px',
        },
        appCategoryGrid: {
            width: '320px',
        },
        productInfoGrid: {
            width: '400px',
            marginLeft: '40px',
        },
        productInfoForm: {
            row: 'true',
        },
        cloudServiceFormGroup: {
            maxWidth: '700px',
        },
        itemSpace: {
            paddingRight: '30px',
        },
        gridIndent: {
            marginLeft: '45px',
        },
    });

interface EntryInfoProps extends WithStyles<typeof basicInfoStyles> {}
const EntryInfo = withStyles(basicInfoStyles, { withTheme: true })((props: EntryInfoProps) => {
    const { classes } = props;
    const container = EntryDetailContainer.useContainer();

    // ワークスペースIDを表示する
    const workspaceId = () => {
        const workspace = container.entryDetail.workspace;
        if (workspace) {
            return <Typography>{workspace.displayId}</Typography>;
        }
        return <Typography className={classes.hyphenItem}>-</Typography>;
    };

    //申込状況に合わせて表示するステータスを返す。
    const entryState = () => {
        switch (container.entryDetail.entryState) {
            case 'entry': //申込中
                return (
                    <Grid container>
                        <Typography>{locale.t(locale.keys.applicationList.tab1.index)}</Typography>
                    </Grid>
                );
            case 'create': //作成中
                return (
                    <Grid container>
                        <Typography>{locale.t(locale.keys.applicationList.tab2.index)}</Typography>
                    </Grid>
                );
            case 'complete': // 完了
                return (
                    <Grid container>
                        <Typography>{locale.t(locale.keys.applicationList.tab3.index)}</Typography>
                    </Grid>
                );
            case 'cancel': // 解約
                return (
                    <Grid container>
                        <Typography>{locale.t(locale.keys.applicationList.tab4.index)}</Typography>
                    </Grid>
                );
            case 'delete': // 削除
                return (
                    <Grid container>
                        <Typography>{locale.t(locale.keys.applicationList.tab5.index)}</Typography>
                    </Grid>
                );
            default:
                return (
                    <Grid container>
                        <Typography className={classes.hyphenItem}>-</Typography>
                    </Grid>
                );
        }
    };

    //ワークスペースの状態に合わせて表示するステータスを返す。
    const workspaceStatus = () => {
        if (!container.entryDetail.workspace) {
            // 申込中（workspaceがundefined）の場合”-”を返す.
            return (
                <Grid container>
                    <Typography className={classes.hyphenItem}>{locale.t(locale.keys.applicationList.applicationDetails.workspaceState.none)}</Typography>
                </Grid>
            );
        } else if (container.entryDetail.workspace.deleteFlag) {
            // 削除の場合
            return (
                <Grid container>
                    <ClearIcon className={classes.clearIconColor} />
                    <Typography className={classes.clearIconColor}>{locale.t(locale.keys.applicationList.applicationDetails.workspaceState.delete)}</Typography>
                </Grid>
            );
        } else if (container.entryDetail.workspace.active) {
            // アクティブの場合
            return (
                <Grid container>
                    <DoneIcon className={classes.doneIconColor} />
                    <Typography className={classes.doneIconColor}>{locale.t(locale.keys.applicationList.applicationDetails.workspaceState.active)}</Typography>
                </Grid>
            );
        } else if (!container.entryDetail.workspace.verified) {
            // サインアップ待ちの場合
            return (
                <Grid container>
                    <Schedule className={classes.scheduleIconColor} />
                    <Typography className={classes.scheduleIconColor}>{locale.t(locale.keys.applicationList.applicationDetails.workspaceState.waitSignup)}</Typography>
                </Grid>
            );
        } else if (!container.entryDetail.workspace.active) {
            // 停止中の場合
            return (
                <Grid container>
                    <NotInterestedIcon />
                    <Typography>{locale.t(locale.keys.applicationList.applicationDetails.workspaceState.stopped)}</Typography>
                </Grid>
            );
        } else {
            // ここには来ないはず
            return (
                <Grid container>
                    <Typography className={classes.hyphenItem}>{locale.t(locale.keys.applicationList.applicationDetails.workspaceState.none)}</Typography>
                </Grid>
            );
        }
    };

    // 日時情報を表示用にフォーマットする
    const formatDate = (datetime?: string) => {
        if (datetime) {
            return <Typography>{moment(new Date(datetime)).format(DateFormat.fullDateWithSlash)}</Typography>;
        }
        return <Typography className={classes.hyphenItem}>-</Typography>;
    };

    return (
        <Grid item className={classes.groupGrid}>
            <Typography align="left">{locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.title)}</Typography>
            <Grid container item justify={'flex-start'} className={classes.gridIndent}>
                <Grid container>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.entryNumber)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        <Typography>{container.entryDetail.entryNumber}</Typography>
                    </Grid>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.entryState)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        <Grid>{entryState()}</Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.workspace)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        <Grid>{workspaceId()}</Grid>
                    </Grid>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.workspaceStatus)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        <Grid>{workspaceStatus()}</Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.entryDate)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        {formatDate(container.entryDetail.createdAt)}
                    </Grid>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.cancelDate)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        {formatDate(container.entryDetail.cancelDate)}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.itemGrid}>
                        <Typography className={classes.itemName} align="left">
                            {locale.t(locale.keys.applicationList.applicationDetails.basicInformation.basicInfo.deleteDate)}
                        </Typography>
                        <Typography className={classes.itemSeparate}>:</Typography>
                        {formatDate(container.entryDetail.deleteDate)}
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
        </Grid>
    );
});

interface EntryCategoryProps extends WithStyles<typeof basicInfoStyles> {}
const EntryCategory = withStyles(basicInfoStyles, { withTheme: true })((props: EntryCategoryProps) => {
    const { classes } = props;
    const container = EntryDetailContainer.useContainer();
    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        container.setEntryDetail({
            ...container.entryDetail,
            category: value,
        });
    };

    return (
        <Grid className={classes.groupGrid}>
            <Typography align="left">{locale.t(locale.keys.applicationList.applicationDetails.basicInformation.category.title)}</Typography>
            <Grid container justify={'flex-start'} className={classes.gridIndent}>
                <FormControl>
                    <RadioGroup row aria-labelledby="entry" name="entry-group" value={container.entryDetail.category} onChange={handleChange}>
                        <FormControlLabel
                            value="新規"
                            control={<Radio color="secondary" />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.category.new)}
                            className={classes.itemSpace}
                        />
                        <FormControlLabel
                            value="試用"
                            control={<Radio color="secondary" />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.category.try)}
                            className={classes.itemSpace}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Divider className={classes.divider} />
        </Grid>
    );
});

interface EntryProductsProps extends WithStyles<typeof basicInfoStyles> {}
const EntryProducts = withStyles(basicInfoStyles, { withTheme: true })((props: EntryProductsProps) => {
    const { classes } = props;
    const container = EntryDetailContainer.useContainer();

    const handleChangeQs = (event: React.ChangeEvent<HTMLInputElement>) => {
        container.handleChangeEntryDetail({ ...container.entryDetail, products: { qs: event.target.checked, easyFax: container.entryDetail.products.easyFax } });
    };

    const handleChangeEasyFax = (event: React.ChangeEvent<HTMLInputElement>) => {
        container.handleChangeEntryDetail({ ...container.entryDetail, products: { qs: container.entryDetail.products.qs, easyFax: event.target.checked } });
    };

    return (
        <Grid item className={classes.groupGrid}>
            <Typography align="left">{locale.t(locale.keys.applicationList.applicationDetails.basicInformation.product.title)}</Typography>
            <Grid container item justify={'flex-start'} className={classes.gridIndent}>
                <FormControl variant="standard">
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={container.entryDetail.products.qs} name="qs" onChange={handleChangeQs} color="secondary" />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.product.scan)}
                            className={classes.itemSpace}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={container.entryDetail.products.easyFax} name="easyFax" onChange={handleChangeEasyFax} color="secondary" />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.product.fax)}
                            className={classes.itemSpace}
                        />
                    </FormGroup>
                    {container.errorProducts.products ? <FormHelperText error>{container.errorProducts.products}</FormHelperText> : <></>}
                </FormControl>
            </Grid>
            <Divider className={classes.divider} />
        </Grid>
    );
});

interface EntryCloudProps extends WithStyles<typeof basicInfoStyles> {}
const EntryCloud = withStyles(basicInfoStyles, { withTheme: true })((props: EntryCloudProps) => {
    const { classes } = props;
    const container = EntryDetailContainer.useContainer();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        container.handleChangeEntryDetail({
            ...container.entryDetail,
            cloud: {
                ...container.entryDetail.cloud,
                [event.target.name]: event.target.checked,
            },
        });
    };

    return (
        <Grid item className={classes.groupGrid}>
            <Typography align="left">{locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.title)}</Typography>
            <Grid container item justify={'flex-start'} className={classes.gridIndent}>
                <FormControl variant="standard">
                    <FormGroup row>
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.box} name="box" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.box)}
                        />
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.googleDrive} name="googleDrive" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.googleDrive)}
                        />
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.googleShareDrive} name="googleShareDrive" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.googleShareDrive)}
                        />
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.dropbox} name="dropbox" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.dropbox)}
                        />
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.oneDrive} name="oneDrive" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.oneDrive)}
                        />
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.sharePoint} name="sharePoint" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.sharePoint)}
                        />
                        <FormControlLabel
                            className={classes.itemSpace}
                            control={<Checkbox checked={container.entryDetail.cloud.aiOcr} name="aiOcr" color="secondary" onChange={handleChange} />}
                            label={locale.t(locale.keys.applicationList.applicationDetails.basicInformation.cluod.aiOcr)}
                        />
                    </FormGroup>
                    {container.errorCloud.cloud ? <FormHelperText error>{container.errorCloud.cloud}</FormHelperText> : <></>}
                </FormControl>
            </Grid>
            <Divider className={classes.dividerFooter} />
        </Grid>
    );
});

export const BasicInfo = () => {
    return (
        <Grid>
            <Grid>
                <EntryInfo />
                <EntryCategory />
                <EntryProducts />
                <EntryCloud />
            </Grid>
        </Grid>
    );
};
