import { default as React } from 'react';
import { Info, Search } from '@material-ui/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Paper, InputBase } from '@material-ui/core';
import { default as TablePagination, LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { SubWorkspaceMfpsContainer } from './SubWorkspaceMfpsContainer';
import locale from '@/common/utils/locale';
import Grid from '@material-ui/core/Grid';
import { tableStyles } from '@/admin/components/sub-workspace/css/table';
import CovasAvatar from '@/common/components/CovasAvatar';

interface Props extends WithStyles<typeof tableStyles>, RouteComponentProps {}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = SubWorkspaceMfpsContainer.useContainer();
    const pagination = () => (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={container.getRows().length}
            labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
            labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
            )}
            rowsPerPage={container.getRowsPerPage()}
            page={container.getPage()}
            backIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.previousPage),
            }}
            nextIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.nextPage),
            }}
            onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => container.handleChangePage(event, p)}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => container.handleChangeRowsPerPage(event)}
        />
    );
    const table = (headerRows: JSX.Element, bodyRows: JSX.Element, modal?: JSX.Element) => (
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableHead>{headerRows}</TableHead>
                <TableBody>{bodyRows}</TableBody>
            </Table>
            {pagination()}
            {modal != null && modal}
        </div>
    );
    // プロファイル ====================================
    const header = (
        <>
            <TableRow>
                <TableCell variant="head" className={classes.headerCell}>
                    <div className={classes.gridInsideHeaderCell} >
                        <p className={classes.gridInsideHeaderCellText} >{locale.t(locale.keys.subWorkspaceMfpManagement.mfpNumber)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell variant="head" className={classes.headerCell}>
                    <div className={classes.gridInsideHeaderCell} >
                        <p className={classes.gridInsideHeaderCellText} >{locale.t(locale.keys.subWorkspaceMfpManagement.mfpType)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell variant="head" className={classes.headerCell}>
                    <div className={classes.gridInsideHeaderCell} >
                        <p className={classes.gridInsideHeaderCellText} >{locale.t(locale.keys.subWorkspaceMfpManagement.organizationName)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell align="right" padding="checkbox" variant="head" style={{ textAlign: 'right', width: '20px' }} />
                <TableCell variant="head" className={classes.headerCell}>
                    <div className={classes.gridInsideHeaderCell} >
                        <p className={classes.gridInsideHeaderCellText} >{locale.t(locale.keys.subWorkspaceMfpManagement.displayId)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell variant="head" className={classes.headerCell}>
                    &nbsp;{locale.t(locale.keys.subWorkspaceMfpManagement.detail)}
                </TableCell>
            </TableRow>
        </>
    );
    const body = (
        <>
            {container
                .getRows()
                .slice(container.getPage() * container.getRowsPerPage(), container.getPage() * container.getRowsPerPage() + container.getRowsPerPage())
                .map((row, index) => (
                    <TableRow key={row.id} className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                        <TableCell scope="row" className={classes.tableCell}>
                            {row.mfpNumber}&nbsp;
                        </TableCell>
                        <TableCell className={classes.tableCell}>{row.mfpType}&nbsp;</TableCell>
                        <TableCell className={classes.tableCell}>{row.organizationDetail && row.organizationDetail.name != null ? row.organizationDetail!.name : ''}</TableCell>
                        <TableCell align="right" padding="checkbox">
                            <CovasAvatar size={40} seed={row.objId} src={row.logoUrl} color="disabled" />
                        </TableCell>
                        <TableCell>{row.displayId}</TableCell>
                        <TableCell className={classes.tableCell}>
                            {row.info &&
                                <a href={`${container.isSigninWorkspace(row.objId) ? '/workspace' : '/sub-workspace/detail/' + row.displayId}?mfp=true`} target="covas_ws_mfp_detail">
                                    <IconButton aria-label="Edit">
                                        <Info
                                            style={{
                                                width: '15px',
                                                height: '15px',
                                                color: '#ff7300',
                                            }}
                                        />
                                    </IconButton>
                                </a>
                            }
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );

    return (
        <>
            <Paper className={classes.root}>
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <Paper className={classes.searchPager}>
                            <IconButton className={classes.searchIconButton} aria-label="Search">
                                <Search />
                            </IconButton>
                            <InputBase
                                className={classes.searchInput}
                                value={container.searchText}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={container.handleChangeSearchText()}
                                fullWidth={true}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {table(header, body)}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
export default withRouter(withStyles(tableStyles)(Component));
