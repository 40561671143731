import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export interface ResponseFindEntryDetail extends Entry {
    workspaceId: string;
    workspaceStatus: string;
}

export interface Entry {
    id: string;
    entryNumber: string;
    category: string;
    products: {
        qs: boolean;
        easyFax: boolean;
    };
    cloud: {
        box: boolean;
        googleDrive: boolean;
        googleShareDrive: boolean;
        dropbox: boolean;
        oneDrive: boolean;
        sharePoint: boolean;
        aiOcr: boolean;
    };
    clientCompany: string;
    clientDepartment: string;
    clientFirstName: string;
    clientLastName: string;
    clientMail: string;
    clientPhone: string;
    otsSalesDepartment: string;
    otsSalesFirstName: string;
    otsSalesLastName: string;
    otsSalesMail: string;
    otsSalesPhone: string;
    otsEngineerFirstName: string;
    otsEngineerLastName: string;
    otsEngineerMail: string;
    cancelDate?: string;
    deleteDate?: string;
    entryState: string;
    createdAt: string;
    updatedAt: string;
}

// 申込詳細取得
export const findEntryDetail = async (entryNumber: string, auth: string): Promise<schema.V1ObjectsEntryIndex> => {
    const response = await axiosFactory.get<schema.V1ObjectsEntryIndex>(`https://${consts.environment.api.hostname}/v1/entry/${entryNumber}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// 申込情報更新
export const updateEntryDetail = async (entryDetail: schema.V1EntryUpdateRequest, auth: string): Promise<schema.V1ObjectsEntryIndex> => {
    const responce = await axiosFactory.put<schema.V1ObjectsEntryIndex>(`https://${consts.environment.api.hostname}/v1/entry/`, entryDetail, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

// ワークスペース新規作成
export const createWorkspaceFromEntry = async (entryId: string, auth: string): Promise<schema.V1WorkspaceCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceCreateResponse>(
        `https://${consts.environment.api.hostname}/v1/workspaces/entry/${entryId}`,
        {},
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};

// 申込キャンセル
export const cancelEntry = async (body: schema.V1EntryUpdateCancelRequest, auth: string): Promise<schema.V1ObjectsEntryIndex> => {
    const response = await axiosFactory.post<schema.V1ObjectsEntryIndex>(`https://${consts.environment.api.hostname}/v1/entry/cancel`, body, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// 申込解約取消
export const withdrawCancellation = async (body: schema.V1EntryPatchCancelRequest, auth: string): Promise<schema.V1ObjectsEntryIndex> => {
    const responce = await axiosFactory.put<schema.V1ObjectsEntryIndex>(`https://${consts.environment.api.hostname}/v1/entry/cancel`, body, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

// 申込削除
export const deleteEntry = async (entryId: string, auth: string): Promise<schema.V1ObjectsEntryIndex> => {
    const response = await axiosFactory.delete<schema.V1ObjectsEntryIndex>(`https://${consts.environment.api.hostname}/v1/entry/${entryId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const initialEntryDetail: schema.V1ObjectsEntryIndex = {
    id: '',
    entryNumber: '',
    category: '',
    products: {
        qs: true,
        easyFax: false,
    },
    cloud: {
        box: false,
        googleDrive: false,
        googleShareDrive: false,
        dropbox: false,
        oneDrive: false,
        sharePoint: false,
        aiOcr: false,
    },
    clientCompany: '',
    clientDepartment: '',
    clientFirstName: '',
    clientLastName: '',
    clientMail: '',
    clientPhone: '',
    otsSalesDepartment: '',
    otsSalesFirstName: '',
    otsSalesLastName: '',
    otsSalesMail: '',
    otsSalesPhone: '',
    otsEngineerFirstName: '',
    otsEngineerLastName: '',
    otsEngineerMail: '',
    cancelDate: '',
    deleteDate: '',
    entryState: schema.V1ObjectsEntryState.Entry,
    createdAt: '',
};
