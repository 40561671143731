import * as React from 'react';
import { default as Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface Props {
    children?: React.ReactNode;
    width: number;
    height: number;
    datauri?: string;
    cropperRef: React.MutableRefObject<Cropper | null>;
}

export const CovasCropper: React.FC<Props> = (props) => {
    const { cropperRef } = props;

    return (
        <div
            style={{
                textAlign: 'center',
                // paddingTop: '10px',
            }}
        >
            <Cropper
                ref={(cropper: null | Cropper) => {
                    if (cropper) {
                        cropper.setCropBoxData({
                            width: props.width,
                            height: props.height,
                        });
                    }
                    cropperRef.current = cropper;
                    return 'cropper';
                }}
                src={props.datauri!}
                style={{
                    height: props.height,
                    width: props.width,
                    margin: '0px auto',
                }}
                aspectRatio={1}
                guides={false}
                minContainerHeight={props.height}
                minContainerWidth={props.width}
                checkOrientation={true}
            />
            {props.children}
        </div>
    );
};
export default CovasCropper;
