import * as schema from '@/bundles/schema/typescript/schema';
import moment from 'moment';
import { DateFormat } from '@/common/constants/dateFormat';
import locale from '@/common/utils/locale';

export const createCSV = (entry: schema.V1ObjectsEntryIndex[], tenant: schema.Brand) => {
    const scan = tenant === schema.Brand.Ricoh ? "Speedoc" : "Quickスキャン";
    const fax = tenant === schema.Brand.Ricoh ? "スマートecoファクス" : "Easyファクス";
    const result = [
        [
            "申込番号",
            "申込状況",
            "申込日",
            "解約日",
            "削除日",
            "ワークスペースID",
            "ワークスペース状態",
            "申込区分",
            scan,
            fax,
            "Box",
            "Googleドライブ",
            "Google共有ドライブ",
            "Dropbox",
            "Microsoft 365 OneDrive for Business",
            "Microsoft 365 SharePoint Online",
            "AI-OCR",
            "顧客：会社名",
            "顧客：部署名",
            "顧客：担当者名：姓",
            "顧客：担当者名：名",
            "顧客：メールアドレス",
            "顧客：電話番号",
            "営業：部署名",
            "営業：担当者名：姓",
            "営業：担当者名：名",
            "営業：メールアドレス",
            "営業：電話番号",
            "エンジニア：担当者名：姓",
            "エンジニア：担当者名：名",
            "エンジニア：メールアドレス",
        ]
    ]
    for (let column of entry) {
        result.push(createColumn(column))
    }

    // カンマ区切り文字列に変換
    let csv = ""
    let ctrlCode = "\n" // デフォルトはLF
    if (window.navigator.userAgent.toLocaleLowerCase().indexOf("windows nt") !== -1) {
        ctrlCode = "\r\n" // WindowsのときだけCR+LF
    }

    for (let column of result) {
        csv += column.join(",")
        csv += ctrlCode
    }

    // BOM生成（UTF-8）
    const bom = "\uFEFF"
    csv = bom + csv

    const blob = new Blob([csv], { type: "text/csv" })
    const uri = URL.createObjectURL(blob)

    // 空のダウンロードリンクを作成してクリック
    const link = document.createElement("a")
    link.download = `ワークスペース申込一覧_${moment(new Date()).format(DateFormat.fullDateTimeWithNonSeparate)}.csv`
    link.href = uri
    document.body.appendChild(link)
    link.click()

    // 後片付け
    document.body.removeChild(link)
}

const createColumn = (entry: schema.V1ObjectsEntryIndex) => {
    return [
        entry.entryNumber,
        entry.entryState ? formatEntryState(entry.entryState) : "",
        entry.createdAt ? formatDate(entry.createdAt) : "",
        entry.cancelDate ? formatDate(entry.cancelDate) : "-",
        entry.deleteDate ? formatDate(entry.deleteDate) : "-",
        entry.workspace ? entry.workspace.displayId ? entry.workspace.displayId : "" : "",
        entry.workspace ? checkWorkspaceState(entry.workspace) : locale.t(locale.keys.applicationList.applicationDetails.workspaceState.none),
        entry.category,
        entry.products.qs ? "true" : "false",
        entry.products.easyFax ? "true" : "false",
        entry.cloud.box ? "true" : "false",
        entry.cloud.googleDrive ? "true" : "false",
        entry.cloud.googleShareDrive ? "true" : "false",
        entry.cloud.dropbox ? "true" : "false",
        entry.cloud.oneDrive ? "true" : "false",
        entry.cloud.sharePoint ? "true" : "false",
        entry.cloud.aiOcr ? "true" : "false",
        entry.clientCompany,
        entry.clientDepartment,
        entry.clientLastName,
        entry.clientFirstName,
        entry.clientMail,
        entry.clientPhone,
        entry.otsSalesDepartment,
        entry.otsSalesLastName,
        entry.otsSalesFirstName,
        entry.otsSalesMail,
        entry.otsSalesPhone,
        entry.otsEngineerLastName,
        entry.otsEngineerFirstName,
        entry.otsEngineerMail
    ]
}

const formatDate = (datetime: string): string => {
    return moment(new Date(datetime)).format(DateFormat.fullDateWithSlash)
}

const formatEntryState = (entryState: schema.V1ObjectsEntryState) => {
    locale.t(locale.keys.table.rowsPerPage)
    switch (entryState) {
        case schema.V1ObjectsEntryState.Entry:
            return locale.t(locale.keys.applicationList.tab1.index)
        case schema.V1ObjectsEntryState.Create:
            return locale.t(locale.keys.applicationList.tab2.index)
        case schema.V1ObjectsEntryState.Complete:
            return locale.t(locale.keys.applicationList.tab3.index)
        case schema.V1ObjectsEntryState.Cancel:
            return locale.t(locale.keys.applicationList.tab4.index)
        case schema.V1ObjectsEntryState.Delete:
            return locale.t(locale.keys.applicationList.tab5.index)
    }
}

const checkWorkspaceState = (workspace: schema.V1ObjectsWorkspace): string => {
    if (workspace.deleteFlag) return locale.t(locale.keys.applicationList.applicationDetails.workspaceState.delete)
    if (workspace.active) return locale.t(locale.keys.applicationList.applicationDetails.workspaceState.active)
    if (!workspace.verified) return locale.t(locale.keys.applicationList.applicationDetails.workspaceState.waitSignup)
    if (!workspace.active) return locale.t(locale.keys.applicationList.applicationDetails.workspaceState.stopped)
    throw new Error("Cannnot format workspace state")  // ここまでたどり着くことはない
}