import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { tableStyles } from '@/admin/components/sub-workspace/css/table';
import CovasAvatar from '@/common/components/CovasAvatar';
import { AvailableUpdateAllWs } from '@/common/constants/environment';
import locale from '@/common/utils/locale';
import {
    createStyles,
    IconButton,
    InputBase,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { default as TablePagination, LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Block, Check, Edit, Search } from '@material-ui/icons';
import { default as React, Fragment } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { SelectedTab, SubWorkspacesContainer } from './SubWorkspacesContainer';

interface Props extends WithStyles<typeof tableStyles>, RouteComponentProps {}

const StyledTabs = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '16px 0',
            minHeight: 32,
            display: 'inline-block',
            borderBottom: '2px solid #ff7300',
        },
        indicator: {
            display: 'none',
        },
    }),
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 32,
            minWidth: 107,
            padding: 0,
            margin: '0 5px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '&:hover': {
                opacity: 1,
            },
            '&$selected': {
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
            },
            '&$selected :active': {
                color: 'white',
            },
            '&:active': {
                color: theme.palette.secondary.main,
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab {...props} />);

interface StyledTabProps {
    label: string;
    value: any;
}
export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const container = SubWorkspacesContainer.useContainer();
    const pagination = (tab: SelectedTab) => (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={container.getRows().length}
            labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
            labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                <span>
                    {locale.t(locale.keys.table.displayedRowsArgs, {
                        from: paginationInfo.from,
                        to: paginationInfo.to,
                        count: paginationInfo.count,
                    })}
                </span>
            )}
            rowsPerPage={container.getRowsPerPageByTab(tab)}
            page={container.getPageByTab(tab)}
            backIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.previousPage),
            }}
            nextIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.nextPage),
            }}
            onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) =>
                container.handleChangePageByTab(event, p, tab)
            }
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                container.handleChangeRowsPerPageByTab(tab, event)
            }
        />
    );
    const table = (headerRows: JSX.Element, bodyRows: JSX.Element, tab: SelectedTab, modal?: JSX.Element) => (
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableHead>{headerRows}</TableHead>
                <TableBody>{bodyRows}</TableBody>
            </Table>
            {pagination(tab)}
            {modal != null && modal}
        </div>
    );
    // プロファイル ====================================
    const profileHeader = (
        <>
            <TableRow>
                <TableCell />
                <TableCell className={classes.headerCell}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>
                            {locale.t(locale.keys.invitationNewWorkspace.tab1.workspaceId)}
                        </p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>
                            {locale.t(locale.keys.invitationNewWorkspace.tab1.workspaceName)}
                        </p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell}>
                    {locale.t(locale.keys.invitationNewWorkspace.tab1.memberCount)}
                </TableCell>
                <TableCell className={classes.headerCell}>
                    {locale.t(locale.keys.invitationNewWorkspace.tab1.subWorkspace.index)}
                </TableCell>
                <TableCell className={classes.headerCell}>
                    {locale.t(locale.keys.invitationNewWorkspace.tab1.state.index)}
                </TableCell>
                <TableCell className={classes.headerCell}>
                    {locale.t(locale.keys.invitationNewWorkspace.tab1.operation)}
                </TableCell>
            </TableRow>
        </>
    );
    const profileBody = (
        <>
            {container
                .getRows()
                .slice(
                    container.getPageByTab(SelectedTab.tab1) * container.getRowsPerPageByTab(SelectedTab.tab1),
                    container.getPageByTab(SelectedTab.tab1) * container.getRowsPerPageByTab(SelectedTab.tab1) +
                        container.getRowsPerPageByTab(SelectedTab.tab1),
                )
                .map((row, index) => (
                    <TableRow key={row.id} className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                        <TableCell>
                            <CovasAvatar size={40} seed={row.objId} src={row.logoUrl} color="disabled" />
                        </TableCell>
                        <TableCell scope="row" className={classes.tableCell}>
                            {row.displayId}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{row.displayName}</TableCell>
                        <TableCell className={classes.tableCell} align="right">
                            {row.memberCount}
                        </TableCell>
                        {row.enableSubWorkspace ? (
                            <TableCell>
                                <span className={classes.enableSub}>
                                    ○{locale.t(locale.keys.subWorkspaceManagement.subWorkspace.enable)}
                                </span>
                            </TableCell>
                        ) : (
                            <TableCell>
                                <span className={classes.disableSub}>
                                    ×{locale.t(locale.keys.subWorkspaceManagement.subWorkspace.disable)}
                                </span>
                            </TableCell>
                        )}
                        {row.active ? (
                            <TableCell className={classes.active} align="center">
                                <Check style={{ marginBottom: -5 }} />
                                {locale.t(locale.keys.subWorkspaceManagement.state.active)}
                            </TableCell>
                        ) : (
                            <TableCell className={classes.inActive} align="center">
                                <Block style={{ marginBottom: -5 }} />
                                {locale.t(locale.keys.subWorkspaceManagement.state.inactive)}
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            {/** 親ワークスぺースまたはots-customers,ricoh-customersのどちらかなら編集を開ける*/}
                            {(appContainer.values.signinWorkspaceObject.id === row.parentWorkspaceId ||
                                AvailableUpdateAllWs.includes(appContainer.values.signinWorkspaceObject.displayId)) && (
                                <Link to={`/sub-workspace/detail/${row.displayId}`}>
                                    <IconButton aria-label="Edit">
                                        <Edit className={classes.pencil} />
                                    </IconButton>
                                </Link>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );

    // 組織情報 ====================================
    const organizationHeader = (
        <>
            <TableRow>
                <TableCell
                    className={classes.headerCell}
                    variant="head"
                    align="left"
                    style={{ width: '5px', border: 'none' }}
                />
                <TableCell className={classes.headerCell} variant="head" align="left" style={{ border: 'none' }}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>
                            {locale.t(locale.keys.invitationNewWorkspace.tab2.workspaceId)}
                        </p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell} variant="head" align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab2.corporateOrganization)}
                </TableCell>
                <TableCell className={classes.headerCell} variant="head" align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab2.customerNumber)}
                </TableCell>
                <TableCell className={classes.headerCell} variant="head" align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab2.plan)}
                </TableCell>
                <TableCell className={classes.headerCell} variant="head" align="left" style={{ border: 'none' }}>
                    {locale.t(locale.keys.invitationNewWorkspace.tab2.edit)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.headerCell} variant="head" align="left" style={{ width: '5px' }} />
                <TableCell className={classes.headerCell} variant="head" align="left" />
                <TableCell className={classes.headerCell} variant="head" align="left">
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>
                            {locale.t(locale.keys.invitationNewWorkspace.tab2.contactEmail)}
                        </p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell} variant="head" align="left" />
                <TableCell className={classes.headerCell} variant="head" align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab2.tel)}
                </TableCell>
                <TableCell className={classes.headerCell} variant="head" align="left" />
            </TableRow>
        </>
    );
    const organizationBody = (
        <>
            {container
                .getRows()
                .slice(
                    container.getPageByTab(SelectedTab.tab2) * container.getRowsPerPageByTab(SelectedTab.tab2),
                    container.getPageByTab(SelectedTab.tab2) * container.getRowsPerPageByTab(SelectedTab.tab2) +
                        container.getRowsPerPageByTab(SelectedTab.tab2),
                )
                .map((row, index) => (
                    <Fragment key={row.id}>
                        <TableRow className={index % 2 ? classes.evenRow : classes.oddRow}>
                            <TableCell className={classes.tableCell} align="left" style={{ border: 'none' }}>
                                <CovasAvatar size={40} seed={row.objId} src={row.logoUrl} color="disabled" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left" style={{ border: 'none' }}>
                                {row.displayId}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left" component="th" scope="row">
                                {row.organizationDetail != null && row.organizationDetail.name != null
                                    ? row.organizationDetail.name
                                    : ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                {row.organizationDetail != null && row.organizationDetail.customerId != null
                                    ? row.organizationDetail.customerId
                                    : ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                {row.billingPlan}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{ border: 'none' }}>
                                {/** 親ワークスぺースまたはots-customers,ricoh-customersのどちらかなら編集を開ける*/}
                                {(appContainer.values.signinWorkspaceObject.id === row.parentWorkspaceId ||
                                    AvailableUpdateAllWs.includes(
                                        appContainer.values.signinWorkspaceObject.displayId,
                                    )) && (
                                    <Link to={`/sub-workspace/detail/${row.displayId}`}>
                                        <IconButton aria-label="Edit">
                                            <Edit className={classes.pencil} />
                                        </IconButton>
                                    </Link>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow className={index % 2 ? classes.evenRow : classes.oddRow}>
                            <TableCell className={classes.tableCell} align="left" />
                            <TableCell className={classes.tableCell} align="left" />
                            <TableCell
                                className={classes.tableCell}
                                align="left"
                                colSpan={2}
                                component="th"
                                scope="row"
                            >
                                {row.contactEmail}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                {row.organizationDetail != null && row.organizationDetail.phoneNumber != null
                                    ? row.organizationDetail.phoneNumber
                                    : ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left" />
                        </TableRow>
                    </Fragment>
                ))}
        </>
    );
    return (
        <>
            <Paper className={classes.root}>
                <Grid container>
                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <StyledTabs
                            value={container.tab}
                            onChange={(e: React.ChangeEvent<{}>, v: string) => container.handleTabChange(e, v)}
                        >
                            <StyledTab
                                value={SelectedTab.tab1}
                                label={locale.t(locale.keys.invitationNewWorkspace.tab1.index)}
                            />
                            <StyledTab
                                value={SelectedTab.tab2}
                                label={locale.t(locale.keys.invitationNewWorkspace.tab2.index)}
                            />
                        </StyledTabs>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.searchPager}>
                            <IconButton className={classes.searchIconButton} aria-label="Search">
                                <Search />
                            </IconButton>
                            <InputBase
                                className={classes.searchInput}
                                value={container.searchText}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={container.handleChangeSearchText()}
                                fullWidth={true}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {container.tab === SelectedTab.tab1 && table(profileHeader, profileBody, SelectedTab.tab1)}
                        {container.tab === SelectedTab.tab2 &&
                            table(organizationHeader, organizationBody, SelectedTab.tab2)}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
export default withRouter(withStyles(tableStyles)(Component));
