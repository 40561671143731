import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import subHeader from '@/common/css/subHeader';
import { Divider, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { default as React } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface Props extends WithStyles<typeof subHeader> {
    theme: Theme;
    title: string;
    isBack?: string;
    backTitle?: string;
    pageDescription?: string;
    Fab?: JSX.Element;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const history = useHistory();

    const handleClick = () => {
        if (props.backTitle) {
            document.title = props.backTitle;
        }
    };
    const render = () => {
        return (
            <>
                <div className={classes.content}>
                    {props.isBack && !appContainer.onBackError && (
                        <Link className={classes.link} onClick={handleClick} to={props.isBack}>
                            <ArrowBackIos />
                        </Link>
                    )}
                    {/**
                     * TODO: appContainer.onBackErrorはおそらくエラーが発生した場合に戻るボタンを非表示にしたかったのだと思うが、そもそもエラー表示画面が機能していないので不要。
                     */}
                    {!props.isBack && appContainer.onBackError && (
                        <div className={classes.link} onClick={() => history.goBack()}>
                            <ArrowBackIos />
                        </div>
                    )}
                    {!props.isBack && <></>}
                    <Grid container direction="column" justify="center" alignItems="flex-start">
                        <Typography className={classes.title}>{props.title}</Typography>
                        <Grid item container direction="row" wrap="nowrap">
                            <Grid item container direction="column" className={classes.descGrid}>
                                {props.pageDescription &&
                                    props.pageDescription.split('\n').map((value, index) => {
                                        return (
                                            <Typography
                                                className={classes.desc}
                                                key={index}
                                                variant="caption"
                                                color="inherit"
                                            >
                                                {value}
                                            </Typography>
                                        );
                                    })}
                            </Grid>
                            {props.Fab && (
                                <Grid item container direction="column" className={classes.fabGrid} alignItems="flex-end">
                                    {/*
                                            dividerに重ねるようにFAB UIを配置するためのGrid。
                                            classes.marginGridはpageDescriptionの高さに合わせてFAB UIの位置を調整するために必要。
                                        */}
                                    <Grid item className={classes.marginGrid} />
                                    <Grid item className={classes.fab}>
                                        {props.Fab}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <Divider className={classes.divider} />
            </>
        );
    };

    return <>{render()}</>;
};
export default withStyles(subHeader, { withTheme: true })(Component);
