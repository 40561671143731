import announcement from './announcement';
import auth from './auth';
import dashboard from './dashboard';
import member from './member';
import subWorkspace from './sub-workspace';
import entry from './entry';
import workspace from './workspace';
import registrationEndpoint from './registration-endpoint';
import device from './device';
import cloudSetting from './cloud-setting';
import PrivateRoute from './PrivateRoute';
import Private from './Private';
import Public from './Public';
import authManagement from './auth-management';
import googleMigration from './migration/google-migration';
import aiOcrManagement from './ai-ocr';
import license from './license';

export default {
    announcement,
    auth,
    dashboard,
    member,
    subWorkspace,
    entry,
    workspace,
    registrationEndpoint,
    device,
    cloudSetting,
    PrivateRoute,
    Private,
    Public,
    authManagement,
    googleMigration,
    license,
    aiOcrManagement,
};
