import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import routes from '@/admin/constants/routes';
import { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import * as errorHandler from '@/common/utils/errorHandler';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import { createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import { parse } from 'query-string';
import { default as React, useEffect } from 'react';
import Config from './Config';
import Control from './Control';
import Remove from './Remove';
import { SubWorkspaceDetailContainer } from './SubWorkspaceDetailContainer';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            paddingTop: '23px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
    displayId: string;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const swdc = SubWorkspaceDetailContainer.useContainer();

    useEffect(() => {
        appContainer.updateLoadingState(swdc.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swdc.ui]);

    useEffect(() => {
        if (props.skipEffect || swdc.ui.current !== UI.Loading) {
            return;
        }
        logger.debug(`Sub Workspace Detail useEffect Loading`);

        if (props.displayId === appContainer.values.signinWorkspaceObject.displayId) {
            logger.debug(`Error: ${props.displayId} is not subworkspace`);
            swdc.updateUIStatus(UI.Error);
        }
        (async () => {
            try {
                await swdc.loadWorkspace(props.displayId, appContainer.values.authorizationCode);

                swdc.updateUIStatus(UI.Loaded);
            } catch (e) {
                swdc.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {parse(window.location.search).mfp && <SubHeader title={locale.t(locale.keys.subWorkspaceDetail.index)} />}
            {!parse(window.location.search).mfp && (
                <SubHeader
                    title={locale.t(locale.keys.subWorkspaceDetail.index)}
                    isBack={
                        parse(window.location.search).invite
                            ? routes.subWorkspace.invite.index
                            : parse(window.location.search).entrydetail
                              ? `${routes.entry.index}/detail/${parse(window.location.search).entrydetail}`
                              : routes.subWorkspace.index
                    }
                />
            )}
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    {swdc.ui.current === UI.Loading && (
                        <div data-testid={UI.Loading}>
                            <Loading />
                        </div>
                    )}
                    {swdc.ui.current === UI.Loaded && (
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={8}>
                                <Config />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Control />
                                <br />
                                <Remove />
                            </Grid>
                        </Grid>
                    )}
                    {swdc.ui.current === UI.Saving && (
                        <div data-testid={UI.Saving}>
                            <Saving />
                        </div>
                    )}
                    {swdc.ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
