import { useState, useEffect, default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, FormControl, InputLabel, Select, FilledInput, Typography, MenuItem, ListItemText } from '@material-ui/core';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import Fab from '@material-ui/core/Fab';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import Table, { Row } from './Table';
import * as schema from '@/bundles/schema/typescript/schema';
import { createLicenseOrder, indexLicensePlans, indexOrderLicenses } from './license';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import dayjs from 'dayjs';

const styles = (theme: Theme) => createStyles({
    inviteGrid: {
        textAlign: 'left',
        marginTop: '20px',
        marginLeft: '30px',
    },
    selectPlan: {
        minWidth: '280px',
    },
    selectAmount:{
        minWidth: '200px',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: '-5px',
        minWidth: '280px',
    },
    selectInput: {
        padding: '28px 12px 9px 12px',
    },
    orderButton: {
        color: 'white',
        minWidth: 200,
        marginTop: '30px',
        marginLeft: '-50px',
    },
});

interface Props extends WithStyles<typeof styles> {
}


export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.pageTitle.admin.orderOCRLicense));
    const { classes } = props;
    const [form, setForm] = useState<schema.V1LicensesCreateRequest>({
        amount: 100,
        licensePlan: {
            code: '001',
            name: '',
            description: '',
            expiration: '',
            unit: '枚',
            volume: 0,
            price: 0,
            currency: 'JPY',
        } as schema.V1ObjectsLicensePlan,
        licenseProduct: {
            code: '01',
            name: '',
            description: '',
        } as schema.V1ObjectsLicenseProduct,
    });
    const [planRow, setPlanRow] = useState<schema.V1ObjectsLicensePlan[]>();
    const [productRow, setProductRow] = useState<schema.V1ObjectsLicenseProduct[]>();
    const [historyRow, setHistoryRow] = useState<Row[]>([]);
    const ui = useUI(UI.Loaded);
    const appContainer = AdminAppContainer.useContainer();
    const [code, setCode] = useState<string>('001');

    // ライセンスプランを変更する
    const onChangeLicense = (event: React.ChangeEvent<{ value: string }>) => {
        // handle change
        setCode(event.target.value);
    };
    // ライセンスの発行数を変更する
    const onChangeLicenseCount = (event: React.ChangeEvent<{ value: unknown }>) => {
        // handle change
        const value = event.target.value as number;
        setForm({
            ...form,
            amount: value,
        });
    }
    // 全てのライセンスプランをDBから取得する
    const getPlanList = async () => {
        try {
            appContainer.updateLoadingState(UI.Loading);
            const res = await indexLicensePlans(appContainer.values.authorizationCode);
            if (res) {
                setPlanRow(res.licensePlans);
                setProductRow(res.licenseProducts);
            } else {
                throw Error('Response is not found.');
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        } finally {
            appContainer.updateLoadingState(UI.Loaded);
        }
    }
    // 発行するライセンスプランを切り替える
    const checkLicenseCode = (code: string) => {
        const searchRow = planRow ? planRow.find((plan) => plan.code === code) : undefined;
        setForm({
            ...form,
            licenseProduct: {
                ...form.licenseProduct,
                code: productRow ? productRow[0].code : '',
                name: productRow ? productRow[0].name : '',
                description: productRow ? productRow[0].description : '',
                id: productRow ? productRow[0].id : '',
            },
            licensePlan: {
                ...form.licensePlan,
                id: searchRow ? searchRow.id : '',
                code: code,
                name: searchRow ? searchRow.name : '',
                description: searchRow ? searchRow.description : '',
                expiration: searchRow ? searchRow.expiration : '',
                unit: searchRow ? searchRow.unit : '',
                volume: searchRow ? searchRow.volume : 0,
                price: searchRow ? searchRow.price : 0,
                currency: searchRow ? searchRow.currency : '',
            },
        });
    }
    // ライセンスを発行する
    const orderAIOCRLicense = async () => {
        // handle order
        try {
            appContainer.updateLoadingState(UI.Loading);
            const res = await createLicenseOrder(form, appContainer.values.authorizationCode);
            if(res) {
                // 先頭に追加する
                setHistoryRow([{
                    planName: form.licensePlan.name,
                    amount: form.amount,
                    createdAt: dayjs(res.createdAt).format('YYYY/MM/DD'),
                    createRequestId: res.id || '',
                },
                    ...historyRow,
                ])

            } else {
                throw Error('Response is not found.');
            }
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.createLicense),
                variant: Variants.success,
            });
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        } finally {
            appContainer.updateLoadingState(UI.Loaded);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            getPlanList();
            // ライセンスの発行履歴を取得する
            try {
                appContainer.updateLoadingState(UI.Loading);
                const licenseRows = await indexOrderLicenses(appContainer.values.authorizationCode);
                if (licenseRows) {
                    let createdLicenseRow: Row[] = [];
                    licenseRows.requests.map((license) => {
                        createdLicenseRow.push({
                            planName: license.plan ? license.plan.name : '',
                            amount: license.amount ? license.amount : 0,
                            createdAt: license.createdAt ? dayjs(license.createdAt).format('YYYY/MM/DD') : '',
                            createRequestId: license.id ? license.id : '',
                        });
                    });
                    setHistoryRow(createdLicenseRow);
                } else {
                    setHistoryRow([]);
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            } finally {
                appContainer.updateLoadingState(UI.Loaded);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        checkLicenseCode(code);
    }, [code, planRow, productRow, form.amount]);

    return (
        <div>
            <SubHeader title={locale.t(locale.keys.pageTitle.admin.orderOCRLicense)}
                pageDescription={locale.t(locale.keys.pageTitle.adminHeaderDescription.orderOCRLicense)} />
            {ui.current !== UI.Loading && (
            <div>
                <Grid item xs={11} sm={11} lg={4} className={classes.inviteGrid} style={{ display: 'flex' }}>
                    <FormControl className={classes.textField}>
                        <InputLabel htmlFor="order-licensePlan" variant="filled" shrink>
                            <div style={{ height: '24px' }}></div>
                            {locale.t(locale.keys.orderOCRLicense.plan.index)}
                        </InputLabel>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <div style={{ height: '24px' }}></div>
                                <Select
                                    className={classes.selectPlan}
                                    id="period1"
                                    value={code}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => onChangeLicense(e as React.ChangeEvent<{ value: string }>)}
                                    input={<FilledInput id="order-licensePlan" classes={{ input: classes.selectInput }} />}
                                    inputProps={{style: { height: '1.46em', lineHeight: 'normal'}}}
                                >
                                    {planRow && planRow.map((plan) => (
                                        <MenuItem key={plan.code} value={plan.code}>
                                            <ListItemText primary={plan.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className={classes.textField}>
                        <InputLabel htmlFor="order-licenseCount" variant="filled" shrink>
                            <div style={{ height: '24px' }}></div>
                            {locale.t(locale.keys.orderOCRLicense.licenseCount)}
                        </InputLabel>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <div style={{ height: '24px' }}></div>
                                <Select
                                    className={classes.selectAmount}
                                    id="period2"
                                    value={form.amount}
                                    onChange={onChangeLicenseCount}
                                    input={<FilledInput id="order-licenseCount" classes={{ input: classes.selectInput }} />}
                                    inputProps={{
                                        style: { height: '1.46em', lineHeight: 'normal'},
                                    }}
                                >
                                    <MenuItem value={50}>
                                        <ListItemText primary={50} />
                                    </MenuItem>
                                    <MenuItem value={100}>
                                        <ListItemText primary={100} />
                                    </MenuItem>
                                    <MenuItem value={200}>
                                        <ListItemText primary={200} />
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <Fab
                        variant="extended"
                        color="secondary"
                        aria-label="Add"
                        size="large"
                        className={classes.orderButton}
                        onClick={orderAIOCRLicense}
                    >
                    {locale.t(locale.keys.orderOCRLicense.order)}
                    </Fab>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ marginTop: '60px', marginLeft: '40px' }} align="left">
                        {locale.t(locale.keys.orderOCRLicense.desc1)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ marginTop: '10px', marginLeft: '40px'}} align="left">
                        {locale.t(locale.keys.orderOCRLicense.desc2)}
                    </Typography>
                </Grid>
                {historyRow && <Table licenseRow={historyRow} />}
            </div>
            )}
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);