import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import routes from '@/admin/constants/routes';
import * as workspace from '@/common/api/workspace/workspace';
import { State as UI } from '@/common/components/hooks/useUI';
import { Variants } from '@/common/components/messages/CommonMessage';
import { AvailableUpdateAllWs } from '@/common/constants/environment';
import * as errorHandler from '@/common/utils/errorHandler';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import {
    Card,
    CardContent,
    createStyles,
    Fab,
    FormControl,
    Grid,
    TextField,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { parse } from 'query-string';
import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SubWorkspaceDetailContainer } from './SubWorkspaceDetailContainer';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        button: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        buttonGrid: {
            textAlign: 'left',
        },
        removeButton: {
            color: 'white',
            margin: theme.spacing.unit,
        },
        buttonText: {
            margin: '0 8px',
        },
    });
interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const swdc = SubWorkspaceDetailContainer.useContainer();
    const { classes } = props;

    const handleDeleteWorkspace = () => {
        logger.debug('handleDeleteWorkspace');
        swdc.updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (!swdc.values || !swdc.values.workspace) {
                    swdc.openMessage(locale.t(locale.keys.error.unknown), Variants.error);
                    swdc.updateUIStatus(UI.Loaded);
                }
                const result = await workspace.deleteWorkspace(
                    swdc.values.workspace.id!,
                    appContainer.values.authorizationCode,
                );
                swdc.openMessage(locale.t(locale.keys.action.deleted), Variants.success);
                logger.debug('deleteWorkspace result', result);
                if (!result) {
                    swdc.openMessage(locale.t(locale.keys.error.unknown), Variants.error);
                    swdc.updateUIStatus(UI.Loaded);
                }

                // クエリパラメータで遷移場所の選択
                let backTo = routes.subWorkspace.index;
                if (parse(window.location.search).invite) {
                    backTo = routes.subWorkspace.invite.index;
                } else if (parse(window.location.search).entrydetail) {
                    backTo = `${routes.entry.index}/detail/${parse(window.location.search).entrydetail}`;
                }

                props.history.push(backTo);
            } catch (e) {
                swdc.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    return (
        <Card className={props.classes.card}>
            <CardContent>
                <FormControl fullWidth>
                    <Typography align="left" inline variant="h4">
                        {locale.t(locale.keys.subWorkspaceDetail.dangerPanel.index)}
                    </Typography>
                    <Typography align="left" inline color="textSecondary">
                        {locale.t(locale.keys.subWorkspaceDetail.dangerPanel.warning)}
                    </Typography>
                </FormControl>
                <Grid item xs={12}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.subWorkspaceDetail.dangerPanel.workspaceIdConfirm)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={swdc.values.workspaceIdConfirm}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
                        ) => swdc.handleChangeWorkspaceIdConfirm(event.target.value)}
                        disabled={
                            !AvailableUpdateAllWs.includes(appContainer.values.signinWorkspaceObject.displayId) &&
                            appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace
                        }
                    />
                </Grid>
                <Grid item xs={12} className={classes.buttonGrid}>
                    <Fab
                        variant="extended"
                        className={classes.removeButton}
                        size="small"
                        color="secondary"
                        disabled={
                            swdc.removeButtonDisabled() ||
                            (!AvailableUpdateAllWs.includes(appContainer.values.signinWorkspaceObject.displayId) &&
                                appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace)
                        }
                        onClick={() => handleDeleteWorkspace()}
                    >
                        <span className={classes.buttonText}>
                            {locale.t(locale.keys.subWorkspaceDetail.dangerPanel.removeWorkspace)}
                        </span>
                    </Fab>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default withRouter(withStyles(styles)(Component));
