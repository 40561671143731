// @ts-nocheck
import * as validator from '@/common/utils/validator';
import { csvFormat4Endpoint, csvColumnLimitEachVersion } from '@/common/constants/csvFormat';
import locale from '@/common/utils/locale';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as endpointModel from '@/common/api/endpoints/collective/endpoint';
import * as schema from '@/bundles/schema/typescript/schema';
import * as errLocale from '@/common/utils/locale/error-locale';
import { splitString, zenkaku2Hankaku } from '@/common/utils/webappUtil';
import { convertClientApp } from '@/common/constants/clientApp';
import { validateMfpNumberFormat } from '@/common/models/device/validate';
import { CLIENT_APP } from '@/common/constants/clientApp';
import { is, values } from 'ramda';

// エラー時に返却するデータのオブジェクトを作る。
// 検証エラー時にtemporaryに返す空オブジェクト。
const endpoint: schema.V1ObjectsEndpointsCollectiveSetting = {
    invitationEmail: '',
    endpointName: '',
    iconName: '',
    buttonColor: '',
    uploadable: '',
    downloadable: '',
    completeNotification: '',
    errorNotification: '',
    service: '',
    account: '',
    site: '',
    library: '',
    directory: '',
    itemList: '',
    dateFormatYear: '',
    dateFormatMonth: '',
    dateFormatDay: '',
    dateFormatTime: '',
    fixed: '',
    fileOptional: '',
    separator: '',
    sequenceType: '',
    docnameFormatLock: '',
    wordListName1: '',
    wordList1: [],
    wordListName2: '',
    wordList2: [],
    folderDepth: '',
    folderIconVisible: '',
    autoSelect: '',
    folderDisplayFormat: '',
    folderTitle1: '',
    folderTitle2: '',
    folderTitle3: '',
    folderTitle4: '',
    endpointPassword: '',
    scanPreview: '',
    scanOverwriteAlert: '',
    overwrite: '',
    preset1Vertical: '',
    preset1Horizontal: '',
    preset2Vertical: '',
    preset2Horizontal: '',
    preset3Vertical: '',
    preset3Horizontal: '',
    outputType: '',
    rotate: '',
    paperSide: '',
    batch: '',
    sadf: '',
    paperSize: '',
    mixedSize: '',
    aggregation: '',
    resolution: '',
    resolutionLock: '',
    colorType: '',
    colorTypeLock: '',
    density: '',
    autoDensity: '',
    eraseBorder: '',
    eraseBorderWidthSame: '',
    eraseBorderWidthTop: '',
    eraseBorderWidthBottom: '',
    eraseBorderWidthLeft: '',
    eraseBorderWidthRight: '',
    eraseCenterWidth: '',
    omitBlankPage: '',
    printDocumentFormat: '',
    printColor: '',
    copies: '',
    mediaTray: '',
    sides: '',
    numberUp: '',
    presentationDirection: '',
    separatorLine: '',
    mediaSizeName: '',
    sheetCollate: '',
    staple: '',
    punch: '',
    dateSeparator: '',
    numberOfListDisplayColumns: '',
    documentThickness: '',
    verticalJudge: '',
    largerScan: '',
};

/**
 * v1.8.3以前のバージョンのCSVファイルの検証。
 * 通常業務CSVインポート機能実装はv1.8.3なので、v1.8.3以前のバージョンの検証用関数は存在しない。
 * @param blankLine 空白行を示すフラグ
 * @param value １行ずつ
 * @param index 何行目
 */
const validateV1_8_3 = (blankLine: boolean, value: string[], index: number, isMigrate: boolean = false) => {
    //通常業務の移行の場合、メールアドレスが空でもエラーにしない
    if (!isMigrate) {
        //通常業務一括登録の場合、メールアドレスが空の場合エラーにする
        // メールアドレス: 文字数の確認(54)、入力可能文字の確認
        const email = validator.Validate<{ email: string }>({ email: value[csvFormat4Endpoint.COL_IDX_EMAIL] }, endpointModel.emailValidations(index + 1), endpointModel.NewEmailValidation);
        if (email && email.email) {
            // エラー表示
            const error = {
                isOpen: true,
                message: email.email.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 通常業務名が32バイト以内か
    const endpointName = validator.Validate<{ endpointName: string }>(
        { endpointName: value[csvFormat4Endpoint.COL_IDX_NAME] },
        endpointModel.endpointNameValidations(index + 1),
        endpointModel.newEndpointNameValidations,
    );
    if (endpointName && endpointName.endpointName) {
        // エラー表示
        const error = {
            isOpen: true,
            message: endpointName.endpointName.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 通常業務のアイコンが正しいか
    const icon = validator.Validate<{ endpointIcon: string }>(
        { endpointIcon: value[csvFormat4Endpoint.COL_IDX_ICON].toLowerCase() },
        endpointModel.endpointIconValidations(index + 1),
        endpointModel.newEndpointIconValidations,
    );
    if (icon && icon.endpointIcon) {
        // エラー表示
        const error = {
            isOpen: true,
            message: icon.endpointIcon.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // ラベル色が正しいか
    const labelColor = validator.Validate<{ labelColor: string }>(
        { labelColor: value[csvFormat4Endpoint.COL_IDX_LABELCOLOR].toLowerCase() },
        endpointModel.labelColorValidations(index + 1),
        endpointModel.newLabelColorValidations,
    );
    if (labelColor && labelColor.labelColor) {
        // エラー表示
        const error = {
            isOpen: true,
            message: labelColor.labelColor.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 小文字にして渡す・dark-khakiの修正
    let labelcolor = value[csvFormat4Endpoint.COL_IDX_LABELCOLOR].toLowerCase();
    if (labelcolor === csvFormat4Endpoint.LABELCOLOR[12]) labelcolor = csvFormat4Endpoint.LABELCOLOR[13];
    // スキャン可否がbooleanか
    const scan = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_SCAN] }, endpointModel.scanValidations(index + 1), endpointModel.newScanValidations);
    if (scan && scan.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: scan.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // プリント可否がbooleanか
    const print = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_PRINT] }, endpointModel.printValidations(index + 1), endpointModel.newPrintValidations);
    if (print && print.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: print.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // Eメール通知：操作完了時がbooleanか
    const finishNotice = validator.Validate<{ boo: string }>(
        { boo: value[csvFormat4Endpoint.COL_IDX_FINISHNOTICE] },
        endpointModel.finishNoticeValidations(index + 1),
        endpointModel.newFinishNoticeValidations,
    );
    if (finishNotice && finishNotice.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: finishNotice.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // Eメール通知：エラー発生時がbooleanか
    const errorNotice = validator.Validate<{ boo: string }>(
        { boo: value[csvFormat4Endpoint.COL_IDX_ERRORNOTICE] },
        endpointModel.errorNoticeValidations(index + 1),
        endpointModel.newErrorNoticeValidations,
    );
    if (errorNotice && errorNotice.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: errorNotice.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 接続方法が正しいか
    const connectionType = value[csvFormat4Endpoint.COL_IDX_CONNECTION].toLowerCase();
    const connection = validator.Validate<{ connection: string }>({ connection: connectionType }, endpointModel.connectionValidations(index + 1), endpointModel.newConnectionValidations);
    if (connection && connection.connection) {
        // エラー表示
        const error = {
            isOpen: true,
            message: connection.connection.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // クラウド接続なし以外の場合は必須。認可IDがメールアドレスかつ256文字以内か
    if (connectionType !== csvFormat4Endpoint.CONNECTION[6]) {
        const id = validator.Validate<{ id: string }>({ id: value[csvFormat4Endpoint.COL_IDX_ID] }, endpointModel.idValidations(index + 1), endpointModel.newIdValidations);
        if (id && id.id) {
            // エラー表示
            const error = {
                isOpen: true,
                message: id.id.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // サイト名が256バイト以内か
    const site = validator.Validate<{ site: string }>({ site: value[csvFormat4Endpoint.COL_IDX_SITE] }, endpointModel.siteValidations(index + 1), endpointModel.newSiteValidations);
    if (site && site.site) {
        // エラー表示
        const error = {
            isOpen: true,
            message: site.site.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    //　接続方法がsharepointonlineのときサイト名必須
    if (connectionType === csvFormat4Endpoint.CONNECTION[4]) {
        const siteE = validator.Validate<{ existence: string }>(
            { existence: value[csvFormat4Endpoint.COL_IDX_SITE] },
            endpointModel.siteValidationsSharePoint(index + 1),
            endpointModel.newSiteValidationsSharePoint,
        );
        if (siteE && siteE.existence) {
            // エラー表示
            const error = {
                isOpen: true,
                message: siteE.existence.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // ライブラリ名が256バイト以内か
    const library = validator.Validate<{ library: string }>({ library: value[csvFormat4Endpoint.COL_IDX_LIBRARY] }, endpointModel.libraryValidations(index + 1), endpointModel.newLibraryValidations);
    if (library && library.library) {
        // エラー表示
        const error = {
            isOpen: true,
            message: library.library.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 接続方法がsharepointonlineのときライブラリ名必須
    if (connectionType === csvFormat4Endpoint.CONNECTION[4]) {
        const libraryE = validator.Validate<{ existence: string }>(
            { existence: value[csvFormat4Endpoint.COL_IDX_LIBRARY] },
            endpointModel.libraryValidationsSharePoint(index + 1),
            endpointModel.newLibraryValidationsSharePoint,
        );
        if (libraryE && libraryE.existence) {
            // エラー表示
            const error = {
                isOpen: true,
                message: libraryE.existence.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // ディレクトリ名が256バイト以内かつ \ : , ; * ? " < > | を含まない
    const directory = validator.Validate<{ directory: string }>(
        { directory: value[csvFormat4Endpoint.COL_IDX_DIRECTORY] },
        endpointModel.directoryValidations(index + 1),
        endpointModel.newDirectoryValidations,
    );
    if (directory && directory.directory) {
        // エラー表示
        const error = {
            isOpen: true,
            message: directory.directory.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 接続方法がgoogleteamdriveのときディレクトリ名必須
    if (connectionType === csvFormat4Endpoint.CONNECTION[2]) {
        if (value[csvFormat4Endpoint.COL_IDX_DIRECTORY][0] === '/') value[csvFormat4Endpoint.COL_IDX_DIRECTORY] = value[csvFormat4Endpoint.COL_IDX_DIRECTORY].slice(1);
        const directory = validator.Validate<{ existence: string }>(
            { existence: value[csvFormat4Endpoint.COL_IDX_DIRECTORY] },
            endpointModel.directoryValidationsGoogleShareDrive(index + 1),
            endpointModel.newDirectoryValidationsGoogleShareDrive,
        );
        if (directory && directory.existence) {
            // エラー表示
            const error = {
                isOpen: true,
                message: directory.existence.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 全角を半角に変換してからセパレータ除いて検証する
    const formatHankaku = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_NAMEFORMAT]);
    const formatTrim = typeof formatHankaku === 'string' ? formatHankaku.trim() : '';
    const format = formatTrim.replaceAll(csvFormat4Endpoint.FORMAT_SEPARATOR, '%');
    // nonecloud以外の時かファイル名形式が空以外の場合、QSのファイル名形式は正しいか
    if (connectionType !== csvFormat4Endpoint.CONNECTION[6] || format) {
        // 存在チェック
        if (!value[csvFormat4Endpoint.COL_IDX_NAMEFORMAT]) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08029, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_NAMEFORMAT + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        // ファイル名形式の先頭一文字目が%以外の時はエラーとする
        if (formatTrim.charAt(0) !== csvFormat4Endpoint.NAMEFORMAT_SPLIT) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08169, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_NAMEFORMAT + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        // ファイル名形式が正しいか検証する
        const nameFormat = validator.Validate<{ nameFormat: string }>({ nameFormat: format.toLowerCase() }, endpointModel.nameFormatValidations(index + 1), endpointModel.newNameFormatValidations);
        if (nameFormat && nameFormat.nameFormat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: nameFormat.nameFormat.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 年はbooleanか
    const year = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_YEAR] }, endpointModel.yearValidations(index + 1), endpointModel.newYearValidations);
    if (year && year.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: year.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 月はbooleanか
    const month = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_MONTH] }, endpointModel.monthValidations(index + 1), endpointModel.newMonthValidations);
    if (month && month.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: month.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 日はbooleanか
    const day = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_DAY] }, endpointModel.dayValidations(index + 1), endpointModel.newDayValidations);
    if (day && day.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: day.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 時分秒はbooleanか
    const time = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_TIME] }, endpointModel.timeValidations(index + 1), endpointModel.newTimeValidations);
    if (time && time.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: time.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    // 年、月、日、時分秒が全てfalseかどうか検証する
    const timeCombination = validator.timeCombinationFalse(
        value[csvFormat4Endpoint.COL_IDX_YEAR].toLowerCase(),
        value[csvFormat4Endpoint.COL_IDX_MONTH].toLowerCase(),
        value[csvFormat4Endpoint.COL_IDX_DAY].toLowerCase(),
        value[csvFormat4Endpoint.COL_IDX_TIME].toLowerCase(),
    );
    if (timeCombination) {
        // エラー表示
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08178, {
                index: index + 1,
                rowStart: csvFormat4Endpoint.COL_IDX_YEAR + 1,
                rowEnd: csvFormat4Endpoint.COL_IDX_TIME + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // ファイル形式の固定文字は順番に格納されているか確認する
    const fixedWords = (format.match(new RegExp(csvFormat4Endpoint.FIXED_NEED, 'gi')) || []).map((str) => str.toLowerCase());
    // 固定文字の個数分の文字列配列を作成
    const fixedWordsSort = new Array(fixedWords.length).fill('').map((_, index) => csvFormat4Endpoint.FIXED_ORDER + (index + 1).toString());
    // 作成した配列と同じ順番か検証する
    if (!(fixedWords.length === fixedWordsSort.length && fixedWords.every((str, index) => str === fixedWordsSort[index]))) {
        // エラー表示
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08177, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_NAMEFORMAT + 1,
                format: csvFormat4Endpoint.FIXED_ORDER_STRICT,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    // 固定文字はファイル名形式の%InputstrNの数以上存在するか
    const fixedList = value[csvFormat4Endpoint.COL_IDX_FIXED].split(csvFormat4Endpoint.FIXED_SPLIT); // 区切り文字で分割
    const fixedWord = fixedList.filter(Boolean); // 空の値を削除
    if (fixedWord.length < fixedWords.length) {
        // エラー表示
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08041, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_FIXED + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    //セパレータの空白を除去
    const separatorTrim = typeof value[csvFormat4Endpoint.COL_IDX_SEPARATOR] === 'string' ? value[csvFormat4Endpoint.COL_IDX_SEPARATOR].trim() : '';

    // 固定文字が存在するときのみ判定
    if (fixedWords.length > 0) {
        // 固定文字の重複禁止
        const fixedSame = validator.Validate<{ fixedSame: string }>(
            { fixedSame: value[csvFormat4Endpoint.COL_IDX_FIXED] },
            endpointModel.fixedSameValidations(index + 1),
            endpointModel.newFixedSameValidations,
        );
        if (fixedSame && fixedSame.fixedSame) {
            // エラー表示
            const error = {
                isOpen: true,
                message: fixedSame.fixedSame.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }

        // ファイル名を作成
        const formatList = format.split(csvFormat4Endpoint.NAMEFORMAT_SPLIT).filter(Boolean);
        const replacedFormat: string[] = [];
        let fixedIndex = 0;
        for (const f of formatList) {
            if (f.toLowerCase().startsWith(csvFormat4Endpoint.FIXED_ORDER)) {
                replacedFormat.push(fixedWord[fixedIndex]);
                fixedIndex += 1;
            } else {
                replacedFormat.push(f);
            }
        }
        // 仮でファイル形式をpdfにしておく
        const validate = replacedFormat.join(separatorTrim) + '.pdf';

        // 先頭が“con.”, “prn.”, “aux.”, “nul.”, “com0.”, “com1.”, “com2.”, “com3.”, “com4.”, “com5.”,“com6.”, “com7.”, “com8.”, “com9.”,
        // “lpt0.”, “lpt1.”, “lpt2.”, “lpt3.”, “lpt4.”, “lpt5.”,“lpt6.”, “lpt7.”, “lpt8.”, “lpt9.”, “.”(ドット), “~ $”, “desktop.ini”, “ ”(半角スペース)でない
        const startFixed = validator.Validate<{ startFixed: string }>({ startFixed: validate }, endpointModel.startFixedValidations(index + 1), endpointModel.newStartFixedValidations);
        if (startFixed && startFixed.startFixed) {
            // エラー表示
            const error = {
                isOpen: true,
                message: startFixed.startFixed.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }

        // ファイル名が _vti_ ..(連続したドット) \ ¥ : , ; * ? " < > | / を含まない
        const fixed = validator.Validate<{ fixed: string }>({ fixed: validate }, endpointModel.fixedValidations(index + 1), endpointModel.newFixedValidations);
        if (fixed && fixed.fixed) {
            // エラー表示
            const error = {
                isOpen: true,
                message: fixed.fixed.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 任意文字未入力時読み取り許可がbooleanか
    const optional = validator.Validate<{ boo: string }>(
        { boo: value[csvFormat4Endpoint.COL_IDX_OPTIONAL] },
        endpointModel.optionalFixedValidations(index + 1),
        endpointModel.newOptionalFixedValidations,
    );
    if (optional && optional.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: optional.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // セパレーターが正しいか
    const separator = validator.Validate<{ separator: string }>({ separator: separatorTrim }, endpointModel.separatorValidations(index + 1), endpointModel.newSeparatorValidations);
    if (separator && separator.separator) {
        // エラー表示
        const error = {
            isOpen: true,
            message: separator.separator.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 連番付が正しいか
    const sequence = validator.Validate<{ sequence: string }>(
        { sequence: value[csvFormat4Endpoint.COL_IDX_SEQUENCE] },
        endpointModel.sequenceValidations(index + 1),
        endpointModel.newSequenceValidations,
    );
    if (sequence && sequence.sequence) {
        // エラー表示
        const error = {
            isOpen: true,
            message: sequence.sequence.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 表示形式ロックがbooleanか
    const formatLock = validator.Validate<{ boo: string }>(
        { boo: value[csvFormat4Endpoint.COL_IDX_FORMATLOCK] },
        endpointModel.formatLockValidations(index + 1),
        endpointModel.newFormatLockValidations,
    );
    if (formatLock && formatLock.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: formatLock.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 単語帳1が32バイト以内か（存在は任意）
    const wordlist1 = validator.Validate<{ wordlist: string }>(
        { wordlist: value[csvFormat4Endpoint.COL_IDX_WORD1] },
        endpointModel.wordListValidations(index + 1, csvFormat4Endpoint.COL_IDX_WORD1 + 1, 1),
        endpointModel.newWordListValidations,
    );
    if (wordlist1 && wordlist1.wordlist) {
        // エラー表示
        const error = {
            isOpen: true,
            message: wordlist1.wordlist.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 未設定の場合
    if (!value[csvFormat4Endpoint.COL_IDX_WORD1]) value[csvFormat4Endpoint.COL_IDX_WORD1] = csvFormat4Endpoint.WORD1_DEF;
    // 単語帳1の配列作成
    // \ ¥ / : , ; * ? " < > | は入力不可
    const wordList1: string[] = [];
    for (let i = 0; i < csvFormat4Endpoint.WORDLIST_MAX; i++) {
        if (value[csvFormat4Endpoint.COL_IDX_WORD1_1 + i]) {
            const word1 = validator.Validate<{ word: string }>(
                { word: value[csvFormat4Endpoint.COL_IDX_WORD1_1 + i] },
                endpointModel.wordValidations(index + 1, csvFormat4Endpoint.COL_IDX_WORD1_1 + i + 1, 1, i + 1),
                endpointModel.newWordValidations,
            );
            if (word1 && word1.word) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: word1.word.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
            wordList1.push(value[csvFormat4Endpoint.COL_IDX_WORD1_1 + i]);
        }
    }
    // 単語帳2が32バイト以内か（存在は任意）
    const wordlist2 = validator.Validate<{ wordlist: string }>(
        { wordlist: value[csvFormat4Endpoint.COL_IDX_WORD2] },
        endpointModel.wordListValidations(index + 1, csvFormat4Endpoint.COL_IDX_WORD2 + 1, 2),
        endpointModel.newWordListValidations,
    );
    if (wordlist2 && wordlist2.wordlist) {
        // エラー表示
        const error = {
            isOpen: true,
            message: wordlist2.wordlist.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 未設定の場合
    if (!value[csvFormat4Endpoint.COL_IDX_WORD2]) value[csvFormat4Endpoint.COL_IDX_WORD2] = csvFormat4Endpoint.WORD2_DEF;
    // 単語帳2の配列作成
    // \ ¥ / : , ; * ? " < > | は入力不可
    const wordList2: string[] = [];
    for (let i = 0; i < csvFormat4Endpoint.WORDLIST_MAX; i++) {
        if (value[csvFormat4Endpoint.COL_IDX_WORD2_1 + i]) {
            const word2 = validator.Validate<{ word: string }>(
                { word: value[csvFormat4Endpoint.COL_IDX_WORD2_1 + i] },
                endpointModel.wordValidations(index + 1, csvFormat4Endpoint.COL_IDX_WORD2_1 + i + 1, 2, i + 1),
                endpointModel.newWordValidations,
            );
            if (word2 && word2.word) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: word2.word.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
            wordList2.push(value[csvFormat4Endpoint.COL_IDX_WORD2_1 + i]);
        }
    }
    // フォルダ階層の数字部分を半角に変換
    const depthStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_DEPTH]);
    // フォルダ階層が正しいか
    const depth = validator.Validate<{ depth: string }>({ depth: depthStr }, endpointModel.depthValidations(index + 1), endpointModel.newDepthValidations);
    if (depth && depth.depth) {
        // エラー表示
        const error = {
            isOpen: true,
            message: depth.depth.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // アイコン表示がbooleanか
    const display = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_DISPLAY] }, endpointModel.displayValidations(index + 1), endpointModel.newDisplayValidations);
    if (display && display.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: display.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 自動選択表示がbooleanか
    const auto = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_AUTO] }, endpointModel.autoValidations(index + 1), endpointModel.newAutoValidations);
    if (auto && auto.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: auto.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // フォルダ表示形式が正しいか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_FORM]) {
        const form = validator.Validate<{ form: string }>({ form: value[csvFormat4Endpoint.COL_IDX_FORM] }, endpointModel.formValidations(index + 1), endpointModel.newFormValidations);
        if (form && form.form) {
            // エラー表示
            const error = {
                isOpen: true,
                message: form.form.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合
    if (!value[csvFormat4Endpoint.COL_IDX_FORM]) value[csvFormat4Endpoint.COL_IDX_FORM] = csvFormat4Endpoint.FORM_DEF;
    // 階層タイトル名1~4は32バイト以内か（存在は任意）
    const title1 = validator.Validate<{ title: string }>(
        { title: value[csvFormat4Endpoint.COL_IDX_TITLE1] },
        endpointModel.titleValidations(index + 1, csvFormat4Endpoint.COL_IDX_TITLE1),
        endpointModel.newTitleValidations,
    );
    if (title1 && title1.title) {
        // エラー表示
        const error = {
            isOpen: true,
            message: title1.title.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    const title2 = validator.Validate<{ title: string }>(
        { title: value[csvFormat4Endpoint.COL_IDX_TITLE2] },
        endpointModel.titleValidations(index + 1, csvFormat4Endpoint.COL_IDX_TITLE2),
        endpointModel.newTitleValidations,
    );
    if (title2 && title2.title) {
        // エラー表示
        const error = {
            isOpen: true,
            message: title2.title.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    const title3 = validator.Validate<{ title: string }>(
        { title: value[csvFormat4Endpoint.COL_IDX_TITLE3] },
        endpointModel.titleValidations(index + 1, csvFormat4Endpoint.COL_IDX_TITLE3),
        endpointModel.newTitleValidations,
    );
    if (title3 && title3.title) {
        // エラー表示
        const error = {
            isOpen: true,
            message: title3.title.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    const title4 = validator.Validate<{ title: string }>(
        { title: value[csvFormat4Endpoint.COL_IDX_TITLE4] },
        endpointModel.titleValidations(index + 1, csvFormat4Endpoint.COL_IDX_TITLE4),
        endpointModel.newTitleValidations,
    );
    if (title4 && title4.title) {
        // エラー表示
        const error = {
            isOpen: true,
            message: title4.title.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // パスワードは32、或いは72バイト以内か・半角英数記号か（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_PASSWORD].match(/暗号化/)) {
        // 「暗号化」の文字があるとき、括弧内を切り出して72バイト以内の検証
        const pass = value[csvFormat4Endpoint.COL_IDX_PASSWORD]
            .split('[')
            .slice(-1)[0]
            .split(']')[0];
        const passwordCip = validator.Validate<{ password: string }>({ password: pass }, endpointModel.passwordCipValidations(index + 1), endpointModel.newPasswordValidations);
        if (passwordCip && passwordCip.password) {
            // エラー表示
            const error = {
                isOpen: true,
                message: passwordCip.password.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    } else {
        // それ以外の時、入力値をそのまま32文字以内の検証
        const password = validator.Validate<{ password: string }>(
            { password: value[csvFormat4Endpoint.COL_IDX_PASSWORD] },
            endpointModel.passwordValidations(index + 1),
            endpointModel.newPasswordValidations,
        );
        if (password && password.password) {
            // エラー表示
            const error = {
                isOpen: true,
                message: password.password.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    //出力ファイル形式を大文字に変換
    const outputType = value[csvFormat4Endpoint.COL_IDX_FORMAT].toUpperCase();
    // プレビュー表示はbooleanか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_PREVIEW]) {
        const preview = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_PREVIEW] }, endpointModel.previewValidations(index + 1), endpointModel.newPreviewValidations);
        if (preview && preview.boo) {
            // エラー表示
            const error = {
                isOpen: true,
                message: preview.boo.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        // 出力ファイル形式が(MULTI-)COMPACT-PDFの場合、falseか
        if ((outputType === csvFormat4Endpoint.FORMAT[2] || outputType === csvFormat4Endpoint.FORMAT[5]) && value[csvFormat4Endpoint.COL_IDX_PREVIEW].toLowerCase() === 'true') {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08171, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PREVIEW + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    } else {
        // 未設定の場合
        value[csvFormat4Endpoint.COL_IDX_PREVIEW] = csvFormat4Endpoint.PREVIEW_DEF;
    }
    // 上書き警告はbooleanか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_WARNING]) {
        const warning = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_WARNING] }, endpointModel.warningValidations(index + 1), endpointModel.newWarningValidations);
        if (warning && warning.boo) {
            // エラー表示
            const error = {
                isOpen: true,
                message: warning.boo.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    } else {
        // 未設定の場合
        value[csvFormat4Endpoint.COL_IDX_WARNING] = csvFormat4Endpoint.WARNING_DEF;
    }
    // ファイル名衝突時は正しいか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_CONFLICT] && connectionType === schema.V1ObjectsServiceEnum.Dropbox) {
        const conflict = validator.Validate<{ conflict: string }>(
            { conflict: value[csvFormat4Endpoint.COL_IDX_CONFLICT] },
            endpointModel.conflictValidations(index + 1),
            endpointModel.newConflictValidations,
        );
        if (conflict && conflict.conflict) {
            // エラー表示
            const error = {
                isOpen: true,
                message: conflict.conflict.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    } else {
        // 未設定の場合
        value[csvFormat4Endpoint.COL_IDX_CONFLICT] = csvFormat4Endpoint.CONFLICT_DEF;
    }
    // 不定形サイズ1縦～3横は0または128~999の整数か
    const height1Str = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_HEIGHT1]);
    const width1Str = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_WIDTH1]);
    const height2Str = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_HEIGHT2]);
    const width2Str = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_WIDTH2]);
    const height3Str = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_HEIGHT3]);
    const width3Str = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_WIDTH3]);

    // 先に0の判定して、違ったらrangeでチェック
    //1縦
    if (!(height1Str === csvFormat4Endpoint.SIZE_ALLOW.toString())) {
        // 存在チェック
        if (!height1Str) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08068, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_HEIGHT1 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        if (Number(height1Str)) {
            const height1 = validator.Validate<{ size: string }>(
                { size: height1Str },
                endpointModel.irregularSizeValidations(index + 1, csvFormat4Endpoint.COL_IDX_HEIGHT1),
                endpointModel.newIrregularSizeValidations,
            );
            if (height1 && height1.size) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: height1.size.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            } else if (value[csvFormat4Endpoint.COL_IDX_HEIGHT1].match(/[.,．]/)) {
                // 小数エラー
                const error = {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08069, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_HEIGHT1 + 1,
                    }).message,
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
        } else {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08069, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_HEIGHT1 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    //1横
    if (!(width1Str === csvFormat4Endpoint.SIZE_ALLOW.toString())) {
        // 存在チェック
        if (!width1Str) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08070, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_WIDTH1 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        if (Number(width1Str)) {
            const width1 = validator.Validate<{ size: string }>(
                { size: width1Str },
                endpointModel.irregularSizeValidations(index + 1, csvFormat4Endpoint.COL_IDX_WIDTH1),
                endpointModel.newIrregularSizeValidations,
            );
            if (width1 && width1.size) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: width1.size.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            } else if (value[csvFormat4Endpoint.COL_IDX_WIDTH1].match(/[.,．]/)) {
                // 小数エラー
                const error = {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08071, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_WIDTH1 + 1,
                    }).message,
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
        } else {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08071, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_WIDTH1 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    //2縦
    if (!(height2Str === csvFormat4Endpoint.SIZE_ALLOW.toString())) {
        // 存在チェック
        if (!height2Str) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08072, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_HEIGHT2 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        if (Number(height2Str)) {
            const height2 = validator.Validate<{ size: string }>(
                { size: height2Str },
                endpointModel.irregularSizeValidations(index + 1, csvFormat4Endpoint.COL_IDX_HEIGHT2),
                endpointModel.newIrregularSizeValidations,
            );
            if (height2 && height2.size) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: height2.size.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            } else if (value[csvFormat4Endpoint.COL_IDX_HEIGHT2].match(/[.,．]/)) {
                // 小数エラー
                const error = {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08073, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_HEIGHT2 + 1,
                    }).message,
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
        } else {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08073, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_HEIGHT2 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    //2横
    if (!(width2Str === csvFormat4Endpoint.SIZE_ALLOW.toString())) {
        // 存在チェック
        if (!width2Str) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08074, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_WIDTH2 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        if (Number(width2Str)) {
            const width2 = validator.Validate<{ size: string }>(
                { size: width2Str },
                endpointModel.irregularSizeValidations(index + 1, csvFormat4Endpoint.COL_IDX_WIDTH2),
                endpointModel.newIrregularSizeValidations,
            );
            if (width2 && width2.size) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: width2.size.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            } else if (value[csvFormat4Endpoint.COL_IDX_WIDTH2].match(/[.,．]/)) {
                // 小数エラー
                const error = {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08075, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_WIDTH2 + 1,
                    }).message,
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
        } else {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08075, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_WIDTH2 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    //3縦
    if (!(height3Str === csvFormat4Endpoint.SIZE_ALLOW.toString())) {
        // 存在チェック
        if (!height3Str) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08076, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_HEIGHT3 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        if (Number(height3Str)) {
            const height3 = validator.Validate<{ size: string }>(
                { size: height3Str },
                endpointModel.irregularSizeValidations(index + 1, csvFormat4Endpoint.COL_IDX_HEIGHT3),
                endpointModel.newIrregularSizeValidations,
            );
            if (height3 && height3.size) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: height3.size.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            } else if (value[csvFormat4Endpoint.COL_IDX_HEIGHT3].match(/[.,．]/)) {
                // 小数エラー
                const error = {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08077, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_HEIGHT3 + 1,
                    }).message,
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
        } else {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08077, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_HEIGHT3 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    //3横
    if (!(width3Str === csvFormat4Endpoint.SIZE_ALLOW.toString())) {
        // 存在チェック
        if (!width3Str) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08078, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_WIDTH3 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
        if (Number(width3Str)) {
            const width3 = validator.Validate<{ size: string }>(
                { size: width3Str },
                endpointModel.irregularSizeValidations(index + 1, csvFormat4Endpoint.COL_IDX_WIDTH3),
                endpointModel.newIrregularSizeValidations,
            );
            if (width3 && width3.size) {
                // エラー表示
                const error = {
                    isOpen: true,
                    message: width3.size.toString(),
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            } else if (value[csvFormat4Endpoint.COL_IDX_WIDTH3].match(/[.,．]/)) {
                // 小数エラー
                const error = {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08079, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_WIDTH3 + 1,
                    }).message,
                    variant: Variants.error,
                };
                return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            }
        } else {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08079, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_WIDTH3 + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 出力ファイル形式は正しいか
    const saveFormat = validator.Validate<{ saveFormat: string }>({ saveFormat: outputType }, endpointModel.saveFormatValidations(index + 1), endpointModel.newSaveFormatValidations);
    if (saveFormat && saveFormat.saveFormat) {
        // エラー表示
        const error = {
            isOpen: true,
            message: saveFormat.saveFormat.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    //原稿方向セットを小文字に変換
    const rotate = value[csvFormat4Endpoint.COL_IDX_ORIENTATION].toLowerCase();
    // セット方向は正しいか
    const orientation = validator.Validate<{ orientation: string }>({ orientation: rotate }, endpointModel.orientationValidations(index + 1), endpointModel.newOrientationValidations);
    if (orientation && orientation.orientation) {
        // エラー表示
        const error = {
            isOpen: true,
            message: orientation.orientation.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 原稿面は正しいか
    const paperSide = validator.Validate<{ paperSide: string }>(
        { paperSide: value[csvFormat4Endpoint.COL_IDX_DOCUSIDE] },
        endpointModel.paperSideValidations(index + 1),
        endpointModel.newPaperSideValidations,
    );
    if (paperSide && paperSide.paperSide) {
        // エラー表示
        const error = {
            isOpen: true,
            message: paperSide.paperSide.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 大量原稿はbooleanか
    const sevenal = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_SEVENAL] }, endpointModel.sevenalValidations(index + 1), endpointModel.newSevenalValidations);
    if (sevenal && sevenal.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: sevenal.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // SADFはbooleanか
    const sadf = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_SADF] }, endpointModel.sadfValidations(index + 1), endpointModel.newSadfValidations);
    if (sadf && sadf.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: sadf.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (value[csvFormat4Endpoint.COL_IDX_SEVENAL].toLowerCase() === 'true' && value[csvFormat4Endpoint.COL_IDX_SADF].toLowerCase() === 'true') {
        // 大量原稿とSADFがともに[true]だったらエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08090, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SADF + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 読み取りサイズを大文字に変換
    const scanPaperSize = value[csvFormat4Endpoint.COL_IDX_SIZE].toUpperCase();
    // 読み取りサイズは正しいか
    const paperSize = validator.Validate<{ paperSize: string }>({ paperSize: scanPaperSize }, endpointModel.paperSizeValidations(index + 1), endpointModel.newPaperSizeValidations);
    if (paperSize && paperSize.paperSize) {
        // エラー表示
        const error = {
            isOpen: true,
            message: paperSize.paperSize.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (scanPaperSize === csvFormat4Endpoint.SIZE[7].toUpperCase() && (height1Str === '0' || width1Str === '0')) {
        // User0のとき不定形サイズ1縦かつ横が0はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08093, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
                N: '1',
                M: '0',
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (scanPaperSize === csvFormat4Endpoint.SIZE[8].toUpperCase() && (height2Str === '0' || width2Str === '0')) {
        // User1のとき不定形サイズ2縦かつ横が0はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08093, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
                N: '2',
                M: '1',
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (scanPaperSize === csvFormat4Endpoint.SIZE[9].toUpperCase() && (height3Str === '0' || width3Str === '0')) {
        // User2のとき不定形サイズ3縦かつ横が0はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08093, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
                N: '3',
                M: '2',
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 出力ファイル形式がCOMPACT-PDF / MULTI-COMPACTPDFの場合、不定形サイズはエラー
    if (outputType === csvFormat4Endpoint.FORMAT[2] || outputType === csvFormat4Endpoint.FORMAT[5]) {
        // 読み取りサイズが設定(User0 ~ 3)されている場合は、設定番号を表示。
        if (scanPaperSize === csvFormat4Endpoint.SIZE[7].toUpperCase() || scanPaperSize === csvFormat4Endpoint.SIZE[8].toUpperCase() || scanPaperSize === csvFormat4Endpoint.SIZE[9].toUpperCase()) {
            let N = '';
            if (scanPaperSize === csvFormat4Endpoint.SIZE[7].toUpperCase()) {
                N = '0';
            } else if (scanPaperSize === csvFormat4Endpoint.SIZE[8].toUpperCase()) {
                N = '1';
            } else if (scanPaperSize === csvFormat4Endpoint.SIZE[9].toUpperCase()) {
                N = '2';
            }
            // 不定形サイズの場合、保存形式が[COMPACT-PDF] /[MULTI-COMPACTPDF]はエラー
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08094, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
                    N: N,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
            // 不定形サイズ自動検知の場合もエラー
        } else if (scanPaperSize === csvFormat4Endpoint.SIZE[11].toUpperCase()) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08227, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 集約を半角に変換
    const summaryStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_DOCSUMMARY]);
    if (
        scanPaperSize === csvFormat4Endpoint.SIZE[10].toUpperCase() &&
        (value[csvFormat4Endpoint.COL_IDX_SEVENAL].toLowerCase() === 'true' ||
            value[csvFormat4Endpoint.COL_IDX_SADF].toLowerCase() === 'true' ||
            value[csvFormat4Endpoint.COL_IDX_MIX].toLowerCase() === 'true' ||
            summaryStr === csvFormat4Endpoint.DOCSUMMARY[1])
    ) {
        // [CARDH]の場合、「大量原稿」と「サイズ混載」と「SADF」が[FALSE]、「集約」が[1]
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08095, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // サイズ混載はbooleanか
    const mixed = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_MIX] }, endpointModel.mixedValidations(index + 1), endpointModel.newMixedValidations);
    if (mixed && mixed.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: mixed.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (value[csvFormat4Endpoint.COL_IDX_MIX].toLowerCase() === 'true' && (scanPaperSize !== csvFormat4Endpoint.SIZE[0].toUpperCase() && scanPaperSize !== csvFormat4Endpoint.SIZE[11].toUpperCase())) {
        // [TRUE]の場合、「読み取りサイズ」が[Auto]かつ[AutoI]以外はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08098, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_MIX + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 集約設定は正しいか
    const docSummary = validator.Validate<{ docSummary: string }>({ docSummary: summaryStr }, endpointModel.docSummaryValidations(index + 1), endpointModel.newDocSummaryValidations);
    if (docSummary && docSummary.docSummary) {
        // エラー表示
        const error = {
            isOpen: true,
            message: docSummary.docSummary.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (summaryStr === csvFormat4Endpoint.DOCSUMMARY[1] && outputType !== csvFormat4Endpoint.FORMAT[1] && outputType !== csvFormat4Endpoint.FORMAT[4]) {
        // 4の場合、「出力ファイル形式」が[PDF] / [MULTI-PDF]以外はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08101, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_DOCSUMMARY + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (
        summaryStr === csvFormat4Endpoint.DOCSUMMARY[1] &&
        (scanPaperSize === csvFormat4Endpoint.SIZE[0].toUpperCase() ||
            scanPaperSize === csvFormat4Endpoint.SIZE[7].toUpperCase() ||
            scanPaperSize === csvFormat4Endpoint.SIZE[8].toUpperCase() ||
            scanPaperSize === csvFormat4Endpoint.SIZE[9].toUpperCase() ||
            scanPaperSize === csvFormat4Endpoint.SIZE[10].toUpperCase() ||
            scanPaperSize === csvFormat4Endpoint.SIZE[11].toUpperCase())
    ) {
        // 4の場合、「読み取りサイズ」が[Auto]/[AutoI]/[User0]/[User1]/[User2]/[CARDH] はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08102, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_DOCSUMMARY + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 解像度を小文字に変換
    const scanResolution = value[csvFormat4Endpoint.COL_IDX_RESOLUTION].toLowerCase();
    // 解像度は正しいか
    const resolution = validator.Validate<{ resolution: string }>({ resolution: scanResolution }, endpointModel.resolutionValidations(index + 1), endpointModel.newResolutionValidations);
    if (resolution && resolution.resolution) {
        // エラー表示
        const error = {
            isOpen: true,
            message: resolution.resolution.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 解像度ロックはbooleanか
    const resolutionLock = validator.Validate<{ boo: string }>(
        { boo: value[csvFormat4Endpoint.COL_IDX_RESOLUTIONLOCK] },
        endpointModel.resolutionLockValidations(index + 1),
        endpointModel.newResolutionLockValidations,
    );
    if (resolutionLock && resolutionLock.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: resolutionLock.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 原稿種類を大文字に変換
    const colorType = value[csvFormat4Endpoint.COL_IDX_TYPE].toUpperCase();
    // 原稿種類は正しいか
    const type = validator.Validate<{ type: string }>({ type: colorType }, endpointModel.typeValidations(index + 1), endpointModel.newTypeValidations);
    if (type && type.type) {
        // エラー表示
        const error = {
            isOpen: true,
            message: type.type.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (
        (colorType === csvFormat4Endpoint.TYPE[4] ||
            colorType === csvFormat4Endpoint.TYPE[5] ||
            colorType === csvFormat4Endpoint.TYPE[8] ||
            colorType === csvFormat4Endpoint.TYPE[9] ||
            colorType === csvFormat4Endpoint.TYPE[6]) &&
        outputType === csvFormat4Endpoint.FORMAT[3]
    ) {
        // [GRAYSCALE]/[FULLCOLOR_MIXED]/[FULLCOLOR_PHOTO]/[GRAYSCALE_OCR]/[FULLCOLOR_OCR](白黒以外)の場合、「出力ファイル形式」が[MULTI-TIFF]はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08109, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_TYPE + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (
        (colorType === csvFormat4Endpoint.TYPE[0] || colorType === csvFormat4Endpoint.TYPE[1] || colorType === csvFormat4Endpoint.TYPE[2] || colorType === csvFormat4Endpoint.TYPE[3]) &&
        (outputType === csvFormat4Endpoint.FORMAT[2] || outputType === csvFormat4Endpoint.FORMAT[5])
    ) {
        // [MONO_OCR] /[MONO_TEXT]/[MONO_MIXED] /[MONO_PHOTO]の場合、「出力ファイル形式」が[COMPACT-PDF]/[MULTI-COMPACT-PDF]はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08110, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_TYPE + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (colorType === csvFormat4Endpoint.TYPE[7] && (outputType === csvFormat4Endpoint.FORMAT[2] || outputType === csvFormat4Endpoint.FORMAT[3] || outputType === csvFormat4Endpoint.FORMAT[5])) {
        // [ACS]の場合、「出力ファイル形式」が[COMPACT-PDF] /[MULTI-COMPACTPDF],[MULTI-TIFF]はエラー
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08111, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_TYPE + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 原稿種類ロックがbooleanか
    const typeLock = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_TYPELOCK] }, endpointModel.typeLockValidations(index + 1), endpointModel.newTypeLockValidations);
    if (typeLock && typeLock.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: typeLock.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 濃度を半角に変換
    const grayLevelStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_GRAYLEVEL]);
    // 濃度が2~8か
    const grayLevel = validator.Validate<{ grayLevel: string }>({ grayLevel: grayLevelStr }, endpointModel.grayLevelValidations(index + 1), endpointModel.newGrayLevelValidations);
    if (grayLevel && grayLevel.grayLevel) {
        //エラー表示
        const error = {
            isOpen: true,
            message: grayLevel.grayLevel.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    } else if (value[csvFormat4Endpoint.COL_IDX_GRAYLEVEL].match(/[.,．]/)) {
        //エラー表示
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08115, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_GRAYLEVEL + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 自動濃度がbooleanか
    const autoGray = validator.Validate<{ boo: string }>({ boo: value[csvFormat4Endpoint.COL_IDX_AUTOGRAYLEVEL] }, endpointModel.autoGrayValidations(index + 1), endpointModel.newAutoGrayValidations);
    if (autoGray && autoGray.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: autoGray.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 枠消去が正しいか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_FRAMEERASE]) {
        const erase = validator.Validate<{ erase: string }>({ erase: value[csvFormat4Endpoint.COL_IDX_FRAMEERASE] }, endpointModel.eraseValidations(index + 1), endpointModel.newEraseValidations);
        if (erase && erase.erase) {
            // エラー表示
            const error = {
                isOpen: true,
                message: erase.erase.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[枠消去しない]
    if (!value[csvFormat4Endpoint.COL_IDX_FRAMEERASE]) value[csvFormat4Endpoint.COL_IDX_FRAMEERASE] = csvFormat4Endpoint.ERASE_DEF;
    // 枠消去サイズを半角に変換
    const eraseSameStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_FRAMESAME]);
    // 枠消去サイズが2~99か（存在は任意）・同一幅
    if (value[csvFormat4Endpoint.COL_IDX_FRAMESAME]) {
        const eraseSame = validator.Validate<{ eraseSize: Number }>({ eraseSize: Number(eraseSameStr) }, endpointModel.eraseSizeValidations(), endpointModel.newEraseSizeValidations);
        // 小数を含めばエラー
        const isFloat = value[csvFormat4Endpoint.COL_IDX_FRAMESAME].match(/[.,．]/);
        if ((eraseSame && eraseSame.eraseSize) || isFloat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08119, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_FRAMESAME + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[10]
    if (!value[csvFormat4Endpoint.COL_IDX_FRAMESAME]) value[csvFormat4Endpoint.COL_IDX_FRAMESAME] = csvFormat4Endpoint.ERASESIZE_DEF;
    // 上
    const eraseUpStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_FRAMEUP]);
    if (value[csvFormat4Endpoint.COL_IDX_FRAMEUP]) {
        const eraseUp = validator.Validate<{ eraseSize: Number }>({ eraseSize: Number(eraseUpStr) }, endpointModel.eraseSizeValidations(), endpointModel.newEraseSizeValidations);
        // 小数を含めばエラー
        const isFloat = value[csvFormat4Endpoint.COL_IDX_FRAMEUP].match(/[.,．]/);
        if ((eraseUp && eraseUp.eraseSize) || isFloat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08120, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_FRAMEUP + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[10]
    if (!value[csvFormat4Endpoint.COL_IDX_FRAMEUP]) value[csvFormat4Endpoint.COL_IDX_FRAMEUP] = csvFormat4Endpoint.ERASESIZE_DEF;
    // 下
    const eraseUnderStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_FRAMEUNDER]);
    if (value[csvFormat4Endpoint.COL_IDX_FRAMEUNDER]) {
        const eraseUnder = validator.Validate<{ eraseSize: Number }>({ eraseSize: Number(eraseUnderStr) }, endpointModel.eraseSizeValidations(), endpointModel.newEraseSizeValidations);
        // 小数を含めばエラー
        const isFloat = value[csvFormat4Endpoint.COL_IDX_FRAMEUNDER].match(/[.,．]/);
        if ((eraseUnder && eraseUnder.eraseSize) || isFloat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08121, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_FRAMEUNDER + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[10]
    if (!value[csvFormat4Endpoint.COL_IDX_FRAMEUNDER]) value[csvFormat4Endpoint.COL_IDX_FRAMEUNDER] = csvFormat4Endpoint.ERASESIZE_DEF;
    // 左
    const eraseLeftStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_FRAMELEFT]);
    if (value[csvFormat4Endpoint.COL_IDX_FRAMELEFT]) {
        const eraseLeft = validator.Validate<{ eraseSize: Number }>({ eraseSize: Number(eraseLeftStr) }, endpointModel.eraseSizeValidations(), endpointModel.newEraseSizeValidations);
        // 小数を含めばエラー
        const isFloat = value[csvFormat4Endpoint.COL_IDX_FRAMELEFT].match(/[.,．]/);
        if ((eraseLeft && eraseLeft.eraseSize) || isFloat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08122, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_FRAMELEFT + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[10]
    if (!value[csvFormat4Endpoint.COL_IDX_FRAMELEFT]) value[csvFormat4Endpoint.COL_IDX_FRAMELEFT] = csvFormat4Endpoint.ERASESIZE_DEF;
    // 右
    const eraseRightStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_FRAMERIGHT]);
    if (value[csvFormat4Endpoint.COL_IDX_FRAMERIGHT]) {
        const eraseRight = validator.Validate<{ eraseSize: Number }>({ eraseSize: Number(eraseRightStr) }, endpointModel.eraseSizeValidations(), endpointModel.newEraseSizeValidations);
        // 小数を含めばエラー
        const isFloat = value[csvFormat4Endpoint.COL_IDX_FRAMERIGHT].match(/[.,．]/);
        if ((eraseRight && eraseRight.eraseSize) || isFloat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08123, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_FRAMERIGHT + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[10]
    if (!value[csvFormat4Endpoint.COL_IDX_FRAMERIGHT]) value[csvFormat4Endpoint.COL_IDX_FRAMERIGHT] = csvFormat4Endpoint.ERASESIZE_DEF;
    // センター
    const eraseCenterStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_CENTER]);
    if (value[csvFormat4Endpoint.COL_IDX_CENTER]) {
        const eraseCenter = validator.Validate<{ eraseSize: Number }>({ eraseSize: Number(eraseCenterStr) }, endpointModel.eraseSizeValidations(), endpointModel.newEraseSizeValidations);
        // 小数を含めばエラー
        const isFloat = value[csvFormat4Endpoint.COL_IDX_CENTER].match(/[.,．]/);
        if ((eraseCenter && eraseCenter.eraseSize) || isFloat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08124, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_CENTER + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[10]
    if (!value[csvFormat4Endpoint.COL_IDX_CENTER]) value[csvFormat4Endpoint.COL_IDX_CENTER] = csvFormat4Endpoint.ERASESIZE_DEF;
    // 白紙ページ削除がbooleanか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_BLANK]) {
        const blank = validator.Validate<{ boo: String }>({ boo: value[csvFormat4Endpoint.COL_IDX_BLANK] }, endpointModel.blankValidations(index + 1), endpointModel.newBlankValidations);
        if (blank && blank.boo) {
            // エラー表示
            const error = {
                isOpen: true,
                message: blank.boo.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合false
    if (!value[csvFormat4Endpoint.COL_IDX_BLANK]) value[csvFormat4Endpoint.COL_IDX_BLANK] = csvFormat4Endpoint.BLANK_DEF;
    // 印刷対象形式が正しいか（存在は任意）
    if (value[csvFormat4Endpoint.COL_IDX_PRINTFORMAT]) {
        const printFormat = validator.Validate<{ printFormat: string }>(
            { printFormat: value[csvFormat4Endpoint.COL_IDX_PRINTFORMAT].toUpperCase() },
            endpointModel.printFormatValidations(index + 1),
            endpointModel.newPrintFormatValidations,
        );
        if (printFormat && printFormat.printFormat) {
            // エラー表示
            const error = {
                isOpen: true,
                message: printFormat.printFormat.toString(),
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // 未設定の場合[PDF]
    if (!value[csvFormat4Endpoint.COL_IDX_PRINTFORMAT]) value[csvFormat4Endpoint.COL_IDX_PRINTFORMAT] = csvFormat4Endpoint.PRINT_DEF;
    //印刷条件：カラーを大文字に変換
    const printColor = value[csvFormat4Endpoint.COL_IDX_COLOR].toUpperCase();
    // 印刷条件：カラーが正しいか
    const color = validator.Validate<{ color: string }>({ color: printColor }, endpointModel.colorValidations(index + 1), endpointModel.newColorValidations);
    if (color && color.color) {
        // エラー表示
        const error = {
            isOpen: true,
            message: color.color.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：部数が1~999か
    const copiesStr = zenkaku2Hankaku(value[csvFormat4Endpoint.COL_IDX_COPIES]);
    // 存在チェック
    if (!copiesStr) {
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08129, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_COPIES + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    const copies = validator.Validate<{ copies: Number }>({ copies: Number(copiesStr) }, endpointModel.copiesValidations(index + 1), endpointModel.newCopiesValidations);
    if (copies && copies.copies) {
        // エラー表示
        const error = {
            isOpen: true,
            message: copies.copies.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    } else if (value[csvFormat4Endpoint.COL_IDX_COPIES].match(/[.,．]/)) {
        // エラー表示
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08130, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_COPIES + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：給紙トレイを大文字に変換
    const mediaTray = value[csvFormat4Endpoint.COL_IDX_TRAY].toUpperCase();
    // 印刷条件：給紙トレイが正しいか
    const tray = validator.Validate<{ tray: string }>({ tray: mediaTray }, endpointModel.trayValidations(index + 1), endpointModel.newTrayValidations);
    if (tray && tray.tray) {
        // エラー表示
        const error = {
            isOpen: true,
            message: tray.tray.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：面が正しいか
    const side = validator.Validate<{ side: string }>({ side: value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] }, endpointModel.sideValidations(index + 1), endpointModel.newSideValidations);
    if (side && side.side) {
        // エラー表示
        const error = {
            isOpen: true,
            message: side.side.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] !== csvFormat4Endpoint.PRINTSIDE[0] && mediaTray === csvFormat4Endpoint.TRAY[1]) {
        // 印刷面が片面じゃない、かつ手差しトレイ
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08135, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_TRAY + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：集約を大文字に変換
    const numberUp = value[csvFormat4Endpoint.COL_IDX_PRINTSUMMARY].toUpperCase();
    // 印刷条件：集約が正しいか
    const printSummary = validator.Validate<{ printSummary: string }>({ printSummary: numberUp }, endpointModel.printSummaryValidations(index + 1), endpointModel.newPrintSummaryValidations);
    if (printSummary && printSummary.printSummary) {
        // エラー表示
        const error = {
            isOpen: true,
            message: printSummary.printSummary.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (
        numberUp !== csvFormat4Endpoint.PRINTSUMMARY[0] &&
        (value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] || value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4])
    ) {
        // 集約アリかつ週刊誌綴じ
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08138, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_PRINTSUMMARY + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：集約方向を大文字に変換
    const presentationDirection = value[csvFormat4Endpoint.COL_IDX_DIRECTION].toUpperCase();
    // 印刷条件：集約方向が正しいか
    const direction = validator.Validate<{ direction: string }>({ direction: presentationDirection }, endpointModel.directionValidations(index + 1), endpointModel.newDirectionValidations);
    if (direction && direction.direction) {
        // エラー表示
        const error = {
            isOpen: true,
            message: direction.direction.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：集約仕切り線がbooleanか
    const line = validator.Validate<{ boo: String }>({ boo: value[csvFormat4Endpoint.COL_IDX_LINE] }, endpointModel.lineValidations(index + 1), endpointModel.newLineValidations);
    if (line && line.boo) {
        // エラー表示
        const error = {
            isOpen: true,
            message: line.boo.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：変倍を大文字に変換
    const mediaSizeName = value[csvFormat4Endpoint.COL_IDX_DOUBLING].toUpperCase();
    // 印刷条件：変倍が正しいか
    const doubling = validator.Validate<{ doubling: string }>({ doubling: mediaSizeName }, endpointModel.doublingValidations(index + 1), endpointModel.newDoublingValidations);
    if (doubling && doubling.doubling) {
        // エラー表示
        const error = {
            isOpen: true,
            message: doubling.doubling.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：ソートを大文字に変換
    const sheetCollate = value[csvFormat4Endpoint.COL_IDX_SORT].toUpperCase();
    // 印刷条件：ソートが正しいか
    const sort = validator.Validate<{ sort: string }>({ sort: sheetCollate }, endpointModel.sortValidations(index + 1), endpointModel.newSortValidations);
    if (sort && sort.sort) {
        // エラー表示
        const error = {
            isOpen: true,
            message: sort.sort.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (
        sheetCollate === csvFormat4Endpoint.SORT[0] &&
        (value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] || value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4])
    ) {
        // ソートがスタックかつ週刊誌綴じ
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08147, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SORT + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (sheetCollate === csvFormat4Endpoint.SORT[2] && mediaTray === csvFormat4Endpoint.TRAY[1]) {
        // ソートが回転ソートかつ手差しトレイ
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08148, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_SORT + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    // 印刷条件：ステープルを大文字に変換
    const printStaple = value[csvFormat4Endpoint.COL_IDX_STAPLE].toUpperCase();
    // 印刷条件：ステープルが正しいか
    const staple = validator.Validate<{ staple: string }>({ staple: printStaple }, endpointModel.stapleValidations(index + 1), endpointModel.newStapleValidations);
    if (staple && staple.staple) {
        // エラー表示
        const error = {
            isOpen: true,
            message: staple.staple.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (printStaple !== csvFormat4Endpoint.STAPLE[0] && mediaTray === csvFormat4Endpoint.TRAY[1]) {
        // NONE以外かつ手差しトレイ
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08151, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    // 「印刷条件：ステープル」と「印刷条件：片面/両面」の複合条件----------------------------
    // 印刷条件：ステープル：TOP_LEFT, TOP_LEFT_SLANTの場合、
    // 印刷条件：片面/両面： 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printStaple === csvFormat4Endpoint.STAPLE[1] || printStaple === csvFormat4Endpoint.STAPLE[2]) {
        if (value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] || value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]) {
            // 上２ヵ所または右２ヵ所かつ片面
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08152, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：ステープル：TOP_RIGHT, TOP_RIGHT_SLANT, DUAL_TOPの場合、
    // 印刷条件：片面/両面： 両面(左辺綴じ), 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printStaple === csvFormat4Endpoint.STAPLE[3] || printStaple === csvFormat4Endpoint.STAPLE[4] || printStaple === csvFormat4Endpoint.STAPLE[5]) {
        if (
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[1] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]
        ) {
            // 上２ヵ所または右２ヵ所かつ片面
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08155, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：ステープル：DUAL_LEFTの場合、
    // 印刷条件：片面/両面： 両面(上辺綴じ), 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printStaple === csvFormat4Endpoint.STAPLE[6]) {
        if (
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[2] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]
        ) {
            // 上２ヵ所または右２ヵ所かつ片面
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08156, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：ステープル：DUAL_RIGHTの場合、
    // 印刷条件：片面/両面： 両面(左辺綴じ), 両面(上辺綴じ), 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printStaple === csvFormat4Endpoint.STAPLE[7]) {
        if (
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[1] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[2] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]
        ) {
            // 上２ヵ所または右２ヵ所かつ片面
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08157, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // --------------------------------------------------------------------

    if (printStaple !== csvFormat4Endpoint.STAPLE[0] && (sheetCollate === csvFormat4Endpoint.SORT[0] || sheetCollate === csvFormat4Endpoint.SORT[2])) {
        // NONE以外かつスタックまたは回転ソート
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08153, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    // 印刷条件：パンチを大文字に変換
    const printPunch = value[csvFormat4Endpoint.COL_IDX_PUNCH].toUpperCase();
    // 印刷条件：パンチが正しいか
    const punch = validator.Validate<{ punch: string }>({ punch: printPunch }, endpointModel.punchValidations(index + 1), endpointModel.newPunchValidations);
    if (punch && punch.punch) {
        // エラー表示
        const error = {
            isOpen: true,
            message: punch.punch.toString(),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (printPunch !== csvFormat4Endpoint.PUNCH[0] && mediaTray === csvFormat4Endpoint.TRAY[1]) {
        // NONE以外かつ手差しトレイ
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08160, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    //「印刷条件：パンチ」と「印刷条件：片面/両面」の複合条件----------------------------
    // 印刷条件：パンチ: LEFT_TWOの場合
    // 印刷条件：片面/両面: 両面(上辺綴じ), 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printPunch === csvFormat4Endpoint.PUNCH[1]) {
        if (
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[2] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]
        ) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08164, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：パンチ: TOP_TWOの場合
    // 印刷条件：片面/両面: 両面(左辺綴じ), 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printPunch === csvFormat4Endpoint.PUNCH[2]) {
        if (
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[1] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]
        ) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08161, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：パンチ: RIGHT_TWOの場合
    // 印刷条件：片面/両面: 両面(左辺綴じ), 両面(上辺綴じ), 週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)はエラー
    if (printPunch === csvFormat4Endpoint.PUNCH[3]) {
        if (
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[1] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[2] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[3] ||
            value[csvFormat4Endpoint.COL_IDX_PRINTSIDE] === csvFormat4Endpoint.PRINTSIDE[4]
        ) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08166, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }
    // --------------------------------------------------------------------

    if (printPunch !== csvFormat4Endpoint.PUNCH[0] && sheetCollate === csvFormat4Endpoint.SORT[2]) {
        // NONE以外かつ回転ソート
        const error = {
            isOpen: true,
            message: errLocale.translate(errLocale.keys.E08162, {
                index: index + 1,
                row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
            }).message,
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    //「印刷条件：パンチ」と「印刷条件：ステープル」の複合条件----------------------------
    // 印刷条件：パンチ: LEFT_TWOの場合
    // 印刷条件：ステープル: TOP_RIGHT, TOP_RIGHT_SLANT, DUAL_TOP, DUAL_RIGHT, SADDLE_STITCHはエラー
    if (printPunch === csvFormat4Endpoint.PUNCH[1]) {
        if (
            printStaple === csvFormat4Endpoint.STAPLE[3] ||
            printStaple === csvFormat4Endpoint.STAPLE[4] ||
            printStaple === csvFormat4Endpoint.STAPLE[5] ||
            printStaple === csvFormat4Endpoint.STAPLE[7] ||
            printStaple === csvFormat4Endpoint.STAPLE[8]
        ) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08165, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：パンチ: TOP_TWOの場合
    // 印刷条件：ステープル: TOP_RIGHT, DUAL_LEFT, DUAL_RIGHT, SADDLE_STITCHはエラー
    if (printPunch === csvFormat4Endpoint.PUNCH[2]) {
        if (
            printStaple === csvFormat4Endpoint.STAPLE[3] ||
            printStaple === csvFormat4Endpoint.STAPLE[6] ||
            printStaple === csvFormat4Endpoint.STAPLE[7] ||
            printStaple === csvFormat4Endpoint.STAPLE[8]
        ) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08163, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // 印刷条件：パンチ: RIGHT_TWOの場合
    // 印刷条件：ステープル: TOP_LEFT, TOP_LEFT_SLANT, TOP_RIGHT, DUAL_TOP, DUAL_LEFT, SADDLE_STITCHはエラー
    if (printPunch === csvFormat4Endpoint.PUNCH[3]) {
        if (
            printStaple === csvFormat4Endpoint.STAPLE[1] ||
            printStaple === csvFormat4Endpoint.STAPLE[2] ||
            printStaple === csvFormat4Endpoint.STAPLE[3] ||
            printStaple === csvFormat4Endpoint.STAPLE[5] ||
            printStaple === csvFormat4Endpoint.STAPLE[6] ||
            printStaple === csvFormat4Endpoint.STAPLE[8]
        ) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08167, {
                    index: index + 1,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    // エラーがなければリクエストに追加
    return {
        success: true,
        endpoint: {
            //返却するデータのオブジェクトを作る。
            invitationEmail: value[csvFormat4Endpoint.COL_IDX_EMAIL],
            endpointName: value[csvFormat4Endpoint.COL_IDX_NAME],
            iconName: value[csvFormat4Endpoint.COL_IDX_ICON].toLowerCase(),
            buttonColor: labelcolor,
            uploadable: value[csvFormat4Endpoint.COL_IDX_SCAN],
            downloadable: value[csvFormat4Endpoint.COL_IDX_PRINT],
            completeNotification: value[csvFormat4Endpoint.COL_IDX_FINISHNOTICE],
            errorNotification: value[csvFormat4Endpoint.COL_IDX_ERRORNOTICE],
            service: connectionType,
            account: value[csvFormat4Endpoint.COL_IDX_ID],
            site: value[csvFormat4Endpoint.COL_IDX_SITE],
            library: value[csvFormat4Endpoint.COL_IDX_LIBRARY],
            directory: value[csvFormat4Endpoint.COL_IDX_DIRECTORY],
            itemList: value[csvFormat4Endpoint.COL_IDX_NAMEFORMAT],
            dateFormatYear: value[csvFormat4Endpoint.COL_IDX_YEAR],
            dateFormatMonth: value[csvFormat4Endpoint.COL_IDX_MONTH],
            dateFormatDay: value[csvFormat4Endpoint.COL_IDX_DAY],
            dateFormatTime: value[csvFormat4Endpoint.COL_IDX_TIME],
            fixed: value[csvFormat4Endpoint.COL_IDX_FIXED],
            fileOptional: value[csvFormat4Endpoint.COL_IDX_OPTIONAL],
            separator: separatorTrim,
            sequenceType: value[csvFormat4Endpoint.COL_IDX_SEQUENCE],
            docnameFormatLock: value[csvFormat4Endpoint.COL_IDX_FORMATLOCK],
            wordListName1: value[csvFormat4Endpoint.COL_IDX_WORD1],
            wordList1: wordList1,
            wordListName2: value[csvFormat4Endpoint.COL_IDX_WORD2],
            wordList2: wordList2,
            folderDepth: depthStr,
            folderIconVisible: value[csvFormat4Endpoint.COL_IDX_DISPLAY],
            autoSelect: value[csvFormat4Endpoint.COL_IDX_AUTO],
            folderDisplayFormat: value[csvFormat4Endpoint.COL_IDX_FORM],
            folderTitle1: value[csvFormat4Endpoint.COL_IDX_TITLE1],
            folderTitle2: value[csvFormat4Endpoint.COL_IDX_TITLE2],
            folderTitle3: value[csvFormat4Endpoint.COL_IDX_TITLE3],
            folderTitle4: value[csvFormat4Endpoint.COL_IDX_TITLE4],
            endpointPassword: value[csvFormat4Endpoint.COL_IDX_PASSWORD],
            scanPreview: value[csvFormat4Endpoint.COL_IDX_PREVIEW],
            scanOverwriteAlert: value[csvFormat4Endpoint.COL_IDX_WARNING],
            overwrite: value[csvFormat4Endpoint.COL_IDX_CONFLICT],
            preset1Vertical: height1Str,
            preset1Horizontal: width1Str,
            preset2Vertical: height2Str,
            preset2Horizontal: width2Str,
            preset3Vertical: height3Str,
            preset3Horizontal: width3Str,
            outputType: outputType,
            rotate: rotate,
            paperSide: value[csvFormat4Endpoint.COL_IDX_DOCUSIDE],
            batch: value[csvFormat4Endpoint.COL_IDX_SEVENAL],
            sadf: value[csvFormat4Endpoint.COL_IDX_SADF],
            paperSize: scanPaperSize,
            mixedSize: value[csvFormat4Endpoint.COL_IDX_MIX],
            aggregation: summaryStr,
            resolution: scanResolution,
            resolutionLock: value[csvFormat4Endpoint.COL_IDX_RESOLUTIONLOCK],
            colorType: colorType,
            colorTypeLock: value[csvFormat4Endpoint.COL_IDX_TYPELOCK],
            density: grayLevelStr,
            autoDensity: value[csvFormat4Endpoint.COL_IDX_AUTOGRAYLEVEL],
            eraseBorder: value[csvFormat4Endpoint.COL_IDX_FRAMEERASE],
            eraseBorderWidthSame: eraseSameStr,
            eraseBorderWidthTop: eraseUpStr,
            eraseBorderWidthBottom: eraseUnderStr,
            eraseBorderWidthLeft: eraseLeftStr,
            eraseBorderWidthRight: eraseRightStr,
            eraseCenterWidth: eraseCenterStr,
            omitBlankPage: value[csvFormat4Endpoint.COL_IDX_BLANK],
            printDocumentFormat: value[csvFormat4Endpoint.COL_IDX_PRINTFORMAT].toUpperCase(),
            printColor: printColor,
            copies: copiesStr,
            mediaTray: mediaTray,
            sides: value[csvFormat4Endpoint.COL_IDX_PRINTSIDE],
            numberUp: numberUp,
            presentationDirection: presentationDirection,
            separatorLine: value[csvFormat4Endpoint.COL_IDX_LINE],
            mediaSizeName: mediaSizeName,
            sheetCollate: sheetCollate,
            staple: printStaple,
            punch: printPunch,
        },
        blank: blankLine,
        error: { isOpen: false, message: '', variant: Variants.error },
    };
};

const validateV1_9_0 = (value: string[], index: number) => {
    // 日付セパレーター
    const inputDateSeparator = value[csvFormat4Endpoint.COL_IDX_DATESEPARATOR];
    // 未設定を許容
    if (inputDateSeparator !== '') {
        const dateSeparatorValidateResult = validator.Validate<{ dateSeparator: string }>(
            { dateSeparator: inputDateSeparator },
            endpointModel.dateSeparatorValidations(),
            endpointModel.newDateSeparatorValidations,
        );
        if (dateSeparatorValidateResult && dateSeparatorValidateResult.dateSeparator) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08181, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_DATESEPARATOR + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        }
    }

    // リスト表示形式
    const inputListDisplayFormat = value[csvFormat4Endpoint.COL_IDX_FORM];
    // リスト表示列数
    const inputNumberOfListDisplayColumns = value[csvFormat4Endpoint.COL_IDX_NUMBER_OF_LIST_DISPLAY_COLUMNS];
    // 未設定を許容
    if (inputNumberOfListDisplayColumns !== '' && inputListDisplayFormat === csvFormat4Endpoint.FORM[1]) {
        const numberOfListDisplayColumnsValidateResult = validator.Validate<{ numberOfListDisplayColumns: string }>(
            { numberOfListDisplayColumns: inputNumberOfListDisplayColumns },
            endpointModel.numberOfListDisplayColumnsValidations(),
            endpointModel.newNumberOfListDisplayColumnsValidations,
        );
        if (numberOfListDisplayColumnsValidateResult && numberOfListDisplayColumnsValidateResult.numberOfListDisplayColumns) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08182, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_NUMBER_OF_LIST_DISPLAY_COLUMNS + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        }
    } else {
        value[csvFormat4Endpoint.COL_IDX_NUMBER_OF_LIST_DISPLAY_COLUMNS] = '1';
    }

    // 原稿厚さ
    const inputDocumentThickness = value[csvFormat4Endpoint.COL_IDX_DOCUMENT_THICKNESS];
    // 未設定を許容
    if (inputDocumentThickness !== '') {
        const documentThicknessValidateResult = validator.Validate<{ documentThickness: string }>(
            { documentThickness: inputDocumentThickness },
            endpointModel.documentThicknessValidations(),
            endpointModel.newDocumentThicknessValidations,
        );
        if (documentThicknessValidateResult && documentThicknessValidateResult.documentThickness) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08183, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_DOCUMENT_THICKNESS + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        }
    }

    return {
        success: true,
        endpoint: {
            dateSeparator: value[csvFormat4Endpoint.COL_IDX_DATESEPARATOR],
            numberOfListDisplayColumns: value[csvFormat4Endpoint.COL_IDX_NUMBER_OF_LIST_DISPLAY_COLUMNS],
            documentThickness: value[csvFormat4Endpoint.COL_IDX_DOCUMENT_THICKNESS],
        },
        blank: false,
        error: { isOpen: false, message: '', variant: Variants.error },
    };
};

const validateV1_10_0 = (value: string[], index: number) => {
    // サイズ混載モード
    const inputMixedSizeMode = value[csvFormat4Endpoint.COL_IDX_MIXED_SIZE_MODE];
    // サイズ混載が有効の時のみ検証
    if (value[csvFormat4Endpoint.COL_IDX_MIX].toLowerCase() === 'true') {
        // 未設定を許容
        if (inputMixedSizeMode !== '') {
            const mixedSizeModeValidateResult = validator.Validate<{ mixedSizeMode: string }>(
                { mixedSizeMode: inputMixedSizeMode },
                endpointModel.mixedSizeModeValidations(),
                endpointModel.newMixedSizeModeValidations,
            );
            if (mixedSizeModeValidateResult && mixedSizeModeValidateResult.mixedSizeMode) {
                return {
                    success: false,
                    endpoint: endpoint,
                    blank: false,
                    error: {
                        isOpen: true,
                        message: errLocale.translate(errLocale.keys.E08203, {
                            index: index + 1,
                            row: csvFormat4Endpoint.COL_IDX_MIXED_SIZE_MODE + 1,
                        }).message,
                        variant: Variants.error,
                    },
                };
            }
        }
    }

    // 画像切出し
    const inputDoCutImage = value[csvFormat4Endpoint.COL_IDX_DO_CUT_IMAGE];
    // 未設定を許容
    if (inputDoCutImage !== '') {
        const message = endpointModel.doCutImageValidations(
            index,
            value[csvFormat4Endpoint.COL_IDX_DO_CUT_IMAGE],
            value[csvFormat4Endpoint.COL_IDX_FORMAT],
            value[csvFormat4Endpoint.COL_IDX_DOCUSIDE],
            value[csvFormat4Endpoint.COL_IDX_SEVENAL],
            value[csvFormat4Endpoint.COL_IDX_SADF],
            value[csvFormat4Endpoint.COL_IDX_SIZE],
            value[csvFormat4Endpoint.COL_IDX_DOCSUMMARY],
            value[csvFormat4Endpoint.COL_IDX_RESOLUTION],
            value[csvFormat4Endpoint.COL_IDX_TYPE],
        );
        if (message) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message,
                    variant: Variants.error,
                },
            };
        }
    }

    return {
        success: true,
        endpoint: {
            mixedSizeMode: value[csvFormat4Endpoint.COL_IDX_MIXED_SIZE_MODE],
            doCutImage: value[csvFormat4Endpoint.COL_IDX_DO_CUT_IMAGE],
        },
        blank: false,
        error: { isOpen: false, message: '', variant: Variants.error },
    };
};

const validateV1_11_0 = (value: string[], index: number, tenant: schema.Brand = schema.Brand.Otsuka) => {
    // アプリケーション利用制限設定
    const applicationPermissionSetting = value[csvFormat4Endpoint.COL_IDX_APPLICATION_PERMISSION_SETTINGS].toLowerCase();
    if (applicationPermissionSetting !== '') {
        const validateApplicationPermissionSettings = ['true', 'false'].includes(value[csvFormat4Endpoint.COL_IDX_APPLICATION_PERMISSION_SETTINGS].toLowerCase());
        if (!validateApplicationPermissionSettings) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08222, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_APPLICATION_PERMISSION_SETTINGS + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        }
    }

    // 利用可能アプリケーション
    let application = '';
    const clientAppList =
        value[csvFormat4Endpoint.COL_IDX_PERMITED_APPLICATIONS] !== ''
            ? splitString(value[csvFormat4Endpoint.COL_IDX_PERMITED_APPLICATIONS], csvFormat4Endpoint.PERMITED_APPLICATIONS_SPLIT).map((app) => app.toLowerCase())
            : '';
    if (clientAppList !== '') {
        const validatePermittedApplications = clientAppList.every((app) => [CLIENT_APP.SCAN, CLIENT_APP.FAX].includes(app));
        const isUnique = clientAppList.length === clientAppList.filter((v, i, a) => a.indexOf(v) === i).length;
        if (!validatePermittedApplications || !isUnique) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08223, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_PERMITED_APPLICATIONS + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        } else {
            application = clientAppList.map((app) => convertClientApp(app, tenant));
        }
    }

    // 複合機利用制限設定
    const mfpPermissionSetting = value[csvFormat4Endpoint.COL_IDX_MFP_PERMISSION_SETTINGS].toLowerCase();
    if (mfpPermissionSetting !== '') {
        const validateMfpPermissionSettings = ['true', 'false'].includes(value[csvFormat4Endpoint.COL_IDX_MFP_PERMISSION_SETTINGS].toLowerCase());
        if (!validateMfpPermissionSettings) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08224, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_MFP_PERMISSION_SETTINGS + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        }
    }

    // 利用可能複合機
    // 最初に全て大文字に変換する
    const mfps = value[csvFormat4Endpoint.COL_IDX_PERMITED_MFPS] !== '' ? splitString(value[csvFormat4Endpoint.COL_IDX_PERMITED_MFPS].toUpperCase(), csvFormat4Endpoint.PERMITED_MFPS_SPLIT) : '';
    if (mfps !== '') {
        const validatePermittedMfps = !mfps.every((mfp) => !validateMfpNumberFormat(mfp));
        const isUnique = mfps.length === mfps.filter((v, i, a) => a.indexOf(v) === i).length;
        if (!validatePermittedMfps || !isUnique) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08225, {
                        index: index + 1,
                        row: csvFormat4Endpoint.COL_IDX_PERMITED_MFPS + 1,
                    }).message,
                    variant: Variants.error,
                },
            };
        }
    }

    return {
        success: true,
        endpoint: {
            applicationPermissionSetting,
            application,
            mfpPermissionSetting,
            mfps,
        },
        blank: false,
        error: { isOpen: false, message: '', variant: Variants.success },
    };
};

const validateV1_14_0 = (value: string[], index: number) => {
    // 天地識別
    const inputVerticalJudge = value[csvFormat4Endpoint.COL_IDX_VERTICAL_JUDGE].toUpperCase();
    // 未設定を許容
    if (inputVerticalJudge !== '') {
        // 天地識別がTRUEのとき、出力ファイル形式がPDF、MULTI-PDFではない場合と画像切出しがTRUEの場合はFALSEを返す。
        if(inputVerticalJudge === 'TRUE') {
            if(!(value[csvFormat4Endpoint.COL_IDX_FORMAT] === csvFormat4Endpoint.FORMAT[1] || value[csvFormat4Endpoint.COL_IDX_FORMAT] === csvFormat4Endpoint.FORMAT[4]) ||
                value[csvFormat4Endpoint.COL_IDX_DO_CUT_IMAGE].toUpperCase() === 'TRUE')
            {
                value[csvFormat4Endpoint.COL_IDX_VERTICAL_JUDGE] = 'FALSE';
            }
        }
        const message = endpointModel.verticalJudgeValidations(
            index,
            value[csvFormat4Endpoint.COL_IDX_VERTICAL_JUDGE],
            value[csvFormat4Endpoint.COL_IDX_FORMAT],
            value[csvFormat4Endpoint.COL_IDX_DO_CUT_IMAGE],
        );
        if (message) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message,
                    variant: Variants.error,
                },
            };
        }
    }

    // 余白付きスキャン
    const inputLargerScan = value[csvFormat4Endpoint.COL_IDX_LARGER_SCAN].toUpperCase();
    // 未設定を許容
    if (inputLargerScan !== '') {
        // 余白付きスキャンがTRUEのとき、原稿サイズがUser0、User1、User2、CARDHではない場合はFALSEを返す。
        if(inputLargerScan === 'TRUE') {
            if(!(value[csvFormat4Endpoint.COL_IDX_SIZE] === csvFormat4Endpoint.SIZE[7] || value[csvFormat4Endpoint.COL_IDX_SIZE] === csvFormat4Endpoint.SIZE[8] ||
                value[csvFormat4Endpoint.COL_IDX_SIZE] === csvFormat4Endpoint.SIZE[9] || value[csvFormat4Endpoint.COL_IDX_SIZE] === csvFormat4Endpoint.SIZE[10]))
            {
                value[csvFormat4Endpoint.COL_IDX_LARGER_SCAN] = 'FALSE';
            }
        }
        const message = endpointModel.largerScanValidations(
            index,
            value[csvFormat4Endpoint.COL_IDX_LARGER_SCAN],
            value[csvFormat4Endpoint.COL_IDX_SIZE],
        );
        if (message) {
            return {
                success: false,
                endpoint: endpoint,
                blank: false,
                error: {
                    isOpen: true,
                    message,
                    variant: Variants.error,
                },
            };
        }
    }

    return {
        success: true,
        endpoint: {
            verticalJudge: value[csvFormat4Endpoint.COL_IDX_VERTICAL_JUDGE],
            largerScan: value[csvFormat4Endpoint.COL_IDX_LARGER_SCAN],
        },
        blank: false,
        error: { isOpen: false, message: '', variant: Variants.error },
    };
};

/**
 * csvのチェック
 * @param blankLine 空白行を示すフラグ
 * @param value １行ずつ
 * @param index 何行目
 */

export const csvImportCheck = (blankLine: boolean, value: string[], index: number, tenant?: schema.Brand, isMigrate: boolean = false) => {
    // 一行ずつチェック
    // 空行のチェック
    if ((value.length === 1 && !value[0]) || value.every((v) => v === '')) {
        blankLine = true;
        const error = {
            isOpen: false,
            message: '',
            variant: Variants.error,
        };
        return { success: true, endpoint: endpoint, blank: blankLine, error: error };
    }
    if (blankLine) {
        // 空白行の次の行にデータがある場合
        const error = {
            isOpen: true,
            message: locale.t(locale.keys.validation.csvmincolumnerror, { row: index }),
            variant: Variants.error,
        };
        return { success: false, endpoint: endpoint, blank: blankLine, error: error };
    }

    // カラム数エラー
    // 各バージョンごとの通常業務CSVカラム数に一致しない場合はエラーを返却する
    if (!Object.values(csvColumnLimitEachVersion).includes(value.length)) {
        const maxColumn = Object.values(csvColumnLimitEachVersion).reduce((a, b) => Math.max(a, b));
        if (maxColumn < value.length) {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08185, { index: index + 1 }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        } else {
            const error = {
                isOpen: true,
                message: errLocale.translate(errLocale.keys.E08184, { index: index + 1 }).message,
                variant: Variants.error,
            };
            return { success: false, endpoint: endpoint, blank: blankLine, error: error };
        }
    }

    let validatedEndpoint = { ...endpoint };

    // v1.8.3までのバージョンのCSVの検証
    const validatedV1_8_3 = validateV1_8_3(blankLine, value, index, isMigrate);
    if (!validatedV1_8_3.success) {
        return validatedV1_8_3;
    }
    if (value.length === csvColumnLimitEachVersion.V1_8_3) {
        return validatedV1_8_3;
    }

    // v1.9.0までのバージョンのCSVの検証
    if (value.length < csvColumnLimitEachVersion.V1_9_0) {
        return {
            success: true,
            endpoint: validatedEndpoint,
            blank: false,
            error: { isOpen: false, message: '', variant: Variants.error },
        };
    }
    const validatedV1_9_0 = validateV1_9_0(value, index);
    if (!validatedV1_9_0.success) {
        return {
            success: validatedV1_9_0.success,
            endpoint: endpoint, // 型を合わせるための空オブジェクト（使用しない）
            blank: false,
            error: validatedV1_9_0.error,
        };
    }
    validatedEndpoint = {
        ...validatedV1_8_3.endpoint,
        ...validatedV1_9_0.endpoint,
    };

    // v1.10.0までのバージョンのCSVの検証
    if (value.length < csvColumnLimitEachVersion.V1_10_0) {
        return {
            success: true,
            endpoint: validatedEndpoint,
            blank: false,
            error: { isOpen: false, message: '', variant: Variants.error },
        };
    }
    const validatedV1_10_0 = validateV1_10_0(value, index);
    if (!validatedV1_10_0.success) {
        return {
            success: validatedV1_10_0.success,
            endpoint: endpoint, // 型を合わせるための空オブジェクト（使用しない）
            blank: false,
            error: validatedV1_10_0.error,
        };
    }
    validatedEndpoint = {
        ...validatedV1_8_3.endpoint,
        ...validatedV1_9_0.endpoint,
        ...validatedV1_10_0.endpoint,
    };

    // v1.11.0までのバージョンのCSVの検証
    if (value.length < csvColumnLimitEachVersion.V1_11_0) {
        return {
            success: true,
            endpoint: validatedEndpoint,
            blank: false,
            error: { isOpen: false, message: '', variant: Variants.error },
        };
    }
    const validatedV1_11_0 = validateV1_11_0(value, index, tenant);
    if (!validatedV1_11_0.success) {
        return {
            success: validatedV1_11_0.success,
            endpoint: endpoint, // 型を合わせるための空オブジェクト（使用しない）
            blank: false,
            error: validatedV1_11_0.error,
        };
    }
    validatedEndpoint = {
        ...validatedV1_8_3.endpoint,
        ...validatedV1_9_0.endpoint,
        ...validatedV1_10_0.endpoint,
        ...validatedV1_11_0.endpoint,
    };

    // v1.14.0までのバージョンのCSVの検証
    if (value.length < csvColumnLimitEachVersion.V1_14_0) {
        return {
            success: true,
            endpoint: validatedEndpoint,
            blank: false,
            error: { isOpen: false, message: '', variant: Variants.error },
        };
    }
    const validatedV1_14_0 = validateV1_14_0(value, index);
    if (!validatedV1_14_0.success) {
        return {
            success: validatedV1_14_0.success,
            endpoint: endpoint, // 型を合わせるための空オブジェクト（使用しない）
            blank: false,
            error: validatedV1_14_0.error,
        };
    }
    validatedEndpoint = {
        ...validatedV1_8_3.endpoint,
        ...validatedV1_9_0.endpoint,
        ...validatedV1_10_0.endpoint,
        ...validatedV1_11_0.endpoint,
        ...validatedV1_14_0.endpoint,
    };


    return {
        success: true,
        endpoint: validatedEndpoint,
        blank: false,
        error: { isOpen: false, message: '', variant: Variants.error },
    };
};
