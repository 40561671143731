import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { EmailValidate } from '@/common/constants/email';
import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import * as workspace from '@/common/api/workspace/workspace';
import dayjs from 'dayjs';
import { DateFormat } from '@/common/constants/dateFormat';
import { csvFormat } from '@/common/constants/csvFormat';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

/**
 * メンバー一括登録
 * @param req
 * @param auth
 */
export const collectiveRegistor = async (req: schema.V1WorkspaceuserInviteCollectiveCreateRequest, auth: string): Promise<schema.V1WorkspaceuserInviteCollectiveCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserInviteCollectiveCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/collective`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        timeout: 60 * 60000,
    });
    return response.data;
};

// 新規メンバ招待
export const inviteNewMember = async (req: schema.V1WorkspaceuserCreateRequest, displayId: string, auth: string): Promise<schema.V1WorkspaceuserCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/invite`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// メンバ招待再送信
export const resendInviteNewMember = async (req: schema.V1WorkspaceuserInviteResendCreateRequest, auth: string): Promise<schema.V1WorkspaceuserInviteResendCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserInviteResendCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/invite/resend`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ワークスペースユーザー取得
export const findWorkspaceUser = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceuserIndexResponse> => {
    return workspace.findWorkspaceUser(workspaceId, auth);
};

export interface Form {
    emails: string[];
    limit: number;
    Rows: Row[];
    validateInit: {
        emails: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    emails: [],
    limit: 30,
    Rows: [],
    validateInit: {
        emails: false,
    },
    // Initialize error holder.
    errors: NewValidation(),
});

export interface Row {
    id: number;
    userId: string;
    email: string;
    invitationUser: string;
    invitationDate: string;
    limit: string;
    limitDays: number;
    invited: boolean;
}

export const validations = (): validator.Constraints => {
    return {
        emails: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        emails: null,
    };
};

export const emailValidations = (): validator.Constraints => {
    return {
        email: {
            userEmail: {
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewEmailValidation = (): validator.ValidationOutput => {
    return {
        email: null,
    };
};

export const nameValidations = (): validator.Constraints => {
    return {
        name: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            length: {
                maximum: csvFormat.NAME_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.NAME_MAX_LENGTH }),
            },
        },
    };
};

export const newNameValidations = (): validator.ValidationOutput => {
    return {
        name: null,
    };
};

export const phoneticNameValidations = (): validator.Constraints => {
    return {
        phoneticName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            length: {
                maximum: csvFormat.PHONETIC_NAME_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.PHONETIC_NAME_MAX_LENGTH }),
            },
        },
    };
};

export const newPhoneticNameValidations = (): validator.ValidationOutput => {
    return {
        phoneticName: null,
    };
};

export const userLangValidations = (): validator.Constraints => {
    return {
        userLang: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            csvEnumFormat: {
                allow: csvFormat.LANGUAGE,
                message: locale.t(locale.keys.validation.language),
            },
        },
    };
};

export const newUserLangValidations = (): validator.ValidationOutput => {
    return {
        userLang: null,
    };
};

export const userRoleValidations = (): validator.Constraints => {
    return {
        userRole: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            csvEnumFormat: {
                allow: csvFormat.USER_ROLE,
                message: locale.t(locale.keys.validation.role),
            },
        },
    };
};

export const newUserRoleValidations = (): validator.ValidationOutput => {
    return {
        userRole: null,
    };
};

export const passwordSettingValidations = (): validator.Constraints => {
    return {
        password: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            passwordPolicy: {
                message: locale.t(locale.keys.validation.passwordPolicy),
            },
        },
    };
};

export const NewPasswordSettingValidation = (): validator.ValidationOutput => {
    return {
        password: null,
    };
};

export const deviceLoginUserValidations = (): validator.Constraints => {
    return {
        deviceLoginUser: {
            length: {
                maximum: csvFormat.DEVICELOGINUSER_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.DEVICELOGINUSER_MAX_LENGTH }),
            },
            format: {
                // https://validatejs.org/#validators-format
                pattern: csvFormat.DEVICELOGINUSER,
                flags: 'g', // 正規表現の検索オプション
                message: locale.t(locale.keys.validation.deviceLoginUser),
            },
        },
    };
};

export const NewDeviceLoginUserValidation = (): validator.ValidationOutput => {
    return {
        deviceLoginUser: null,
    };
};

export const pinValidations = (): validator.Constraints => {
    return {
        pin: {
            pinPolicy: {
                allow: false,
                message: locale.t(locale.keys.validation.pinPolicy),
            },
        },
    };
};

export const NewPinValidation = (): validator.ValidationOutput => {
    return {
        pin: null,
    };
};

export const mfpNumberValidations = (): validator.Constraints => {
    return {
        mfpNumber: {
            mfpNumberFormat: {
                allow: false,
                message: locale.t(locale.keys.validation.mfpNumber),
            },
        },
    };
};

export const NewMfpNumberValidation = (): validator.ValidationOutput => {
    return {
        mfpNumber: null,
    };
};

export const contactEmailValidations = (): validator.Constraints => {
    return {
        contactEmail: {
            userEmail: {
                allowEmpty: true,
                allowPlus: true,
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewContactEmailValidation = (): validator.ValidationOutput => {
    return {
        contactEmail: null,
    };
};

/**
 * 未ログインのユーザリストをフィルタし、表示用のデータを整形する
 * @param users ワークスペースのユーザリスト
 * @param now 現在日時(単体テストで日付を固定するためにパラメータを切出し)
 * @returns 表示されるユーザリスト
 */
export const notVerifiedUsers = (users: schema.V1WorkspaceuserIndexResponse, now: dayjs.Dayjs): Row[] => {
    const notVerifiedUsers = users.users.filter((user) => !user.invitationVerified);
    const rows: Row[] = [];
    for (const user of notVerifiedUsers) {
        const invitedUser = users.users
            .filter((u) => {
                return user.invitedUser === u.id;
            })
            .find((u) => u != null);
        const endDate = new Date(user.invitationEndDate);
        const isIndefinite = user.invitationEndDate && dayjs('9999-12-30 00:00:00').isBefore(endDate, 'day');
        const dayDiff = dayjs(endDate)
            .startOf('days')
            .diff(now.startOf('days'), 'days');
        const minuteDiff = dayjs(endDate)
            .startOf('minutes')
            .diff(now.startOf('minutes'), 'minutes');
        const limitString = isIndefinite
            ? infinityString()
            : dayDiff > 0
            ? // day diff(remain n days)
              dayLimitString(dayDiff)
            : minuteDiff > 0
            ? minuteDiff > 60
                ? // hour minute diff(remain x hours and y minutes)
                  hourMinuteLimitString(minuteDiff)
                : // minute diff(remain n minutes)
                  minuteLimitString(minuteDiff)
            : // expired
              expiredString();
        rows.push({
            id: rows.length + 1,
            userId: user.id,
            email: user ? user.invitationEmail : '',
            invitationUser: invitedUser != null ? invitedUser.name : '',
            invitationDate: dayjs(new Date(user.invitationDate)).format(DateFormat.fullDateTimeWithDot),
            limit: limitString,
            limitDays: dayDiff,
            invited: user && user.invited !== undefined ? user.invited : false,
        });
    }
    return rows;
};

export const createData = async (workspaceId: string, authorization: string): Promise<Row[]> => {
    // ワークスペースのユーザーリストを取得
    const users = await findWorkspaceUser(workspaceId, authorization);

    return notVerifiedUsers(users, dayjs());
};

const expiredString = () => locale.t(locale.keys.memberInvitation.invitationLimit.expired);
const infinityString = () => locale.plural('memberInvitation.invitationUnit.infinity', 1, { n: 0 });
const dayLimitString = (dayDiff: number) => {
    return `${locale.t(locale.keys.memberInvitation.invitationLimit.remain) + locale.plural('memberInvitation.invitationLimit.day', dayDiff, { n: dayDiff })}`;
};
const hourMinuteLimitString = (minuteDiff: number) => {
    return `${locale.t(locale.keys.memberInvitation.invitationLimit.remain) +
        locale.plural('memberInvitation.invitationLimit.hour', (minuteDiff / 60) | 0, { n: (minuteDiff / 60) | 0 }) +
        locale.plural('memberInvitation.invitationLimit.minute', minuteDiff % 60 | 0, { n: minuteDiff % 60 | 0 })}`;
};
const minuteLimitString = (minuteDiff: number) => {
    return `${locale.t(locale.keys.memberInvitation.invitationLimit.remain) + locale.plural('memberInvitation.invitationLimit.minute', minuteDiff, { n: minuteDiff })}`;
};
