import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import '@/common/css/app.css';
import { default as commonConsts } from '@/common/constants';
import routes from '@/admin/constants/routes';
import components from '@/admin/components';
import Analytics from '@/admin/components/analytics/List';
import DashboardHeader from '@/admin/components/common/headers/DashboardHeader';
import CommonMenu from '@/common/components/CommonMenu';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { default as CovasDropAndCrop } from '@/common/components/file/CovasDropAndCrop';
import UserCollectiveInvite from '@/admin/components/member/collective-invite/Edit';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            paddingTop: commonConsts.ui.headerHeight,
        },
        content: {
            flexGrow: 1,
            padding: 0,
            height: '95vh',
            minHeight: '95vh',
            maxHeight: '95vh',
            overflowX: 'auto',
        },
        min: {
            minWidth: '960px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
}

export const Component: React.FC<Props> = (props) => {
    const [onMenu, toggleOpen] = React.useState(false);
    const appContainer = AdminAppContainer.useContainer();

    const onMenuOpen = () => {
        toggleOpen(true);
    };

    const onMenuClose = () => {
        toggleOpen(false);
    };
    const onCropAndDropCallback = () => {
        appContainer.setValues({
            ...appContainer.values,
            cropAndDropProps: {
                ...appContainer.values.cropAndDropProps,
                visibility: 'hidden',
            },
        });
    };

    return (
        <div className={`App ${props.classes.min}`}>
            {appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? (
                <CovasDropAndCrop {...appContainer.values.cropAndDropProps} onClose={onCropAndDropCallback} visibility={'show'} dependAppContainer={AdminAppContainer.useContainer} isAdminApp />
            ) : (
                <></>
            )}
            <div style={{ display: appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? 'none' : '' }}>
                <DashboardHeader onMenuOpen={onMenuOpen} appContainerValues={appContainer.values} />
                <div className={props.classes.root}>
                    <CommonMenu isAdminApp={true} isAdminUser={true} commonMenuOpen={onMenu} commonMenuCloseFunc={onMenuClose} />
                    <main className={props.classes.content}>
                        <Switch>
                            {/* アナリティクスのルート */}
                            <Route exact path={routes.analytics.index}>
                                <Analytics />
                            </Route>
                            {/* アナウンスのルート */}
                            <Route exact path={routes.announcement.index}>
                                <components.announcement.List />
                            </Route>
                            {/* サイトトップのルート */}
                            <Route exact path={routes.dashboard.index}>
                                <components.dashboard.List />
                            </Route>
                            {/* 申込一覧画面のルート */}
                            <Route exact path={routes.entry.index}>
                                <components.entry.List />
                            </Route>
                            {/* 申込一覧画面のルート */}
                            <Route exact path={routes.entry.detail}>
                                <components.entry.detail.Edit />
                            </Route>
                            {/* メンバー管理のルート */}
                            <Route exact path={routes.user.index}>
                                <components.member.List />
                            </Route>
                            <Redirect exact from={routes.user.old.index} to={routes.user.index} /> {/* 旧URLからのリダイレクト */}
                            {/* 新規メンバー招待のルート */}
                            <Route exact path={routes.user.invite.index}>
                                <components.member.invite.List />
                            </Route>
                            <Redirect exact from={routes.user.old.invite.index} to={routes.user.invite.index} /> {/* 旧URLからのリダイレクト */}
                            {/* メンバー一括登録画面のルート */}
                            <Route exact path={routes.user.collective.index}>
                                <UserCollectiveInvite />
                            </Route>
                            <Redirect exact from={routes.user.old.collective.index} to={routes.user.collective.index} /> {/* 旧URLからのリダイレクト */}
                            {/* メンバー詳細のルート */}
                            <Route exact path={routes.user.edit}>
                                <components.member.detail.Edit />
                            </Route>
                            <Redirect exact from={routes.user.old.edit} to={routes.user.edit} /> {/* 旧URLからのリダイレクト */}
                            {/* ワークスペース編集のルート */}
                            <Route exact path={routes.workspace.index}>
                                <components.workspace.Edit />
                            </Route>
                            {/* サブワークスペース一覧のルート */}
                            <Route exact path={routes.subWorkspace.index}>
                                <components.subWorkspace.List />
                            </Route>
                            {/* グリッド内デバイス管理のルート */}
                            <Route exact path={routes.subWorkspace.mfps}>
                                <components.subWorkspace.mfps.List />
                            </Route>
                            {/* サブワークスペース詳細画面のルート */}
                            <Route exact path={routes.subWorkspace.edit}>
                                <components.subWorkspace.detail.Edit />
                            </Route>
                            {/* ワークスペース招待画面のルート */}
                            <Route exact path={routes.subWorkspace.invite.index}>
                                <components.subWorkspace.invite.List />
                            </Route>
                            {/* デバイス管理のルート */}
                            <Route exact path={routes.device.index}>
                                <components.device.List />
                            </Route>
                            {/* 新規デバイス登録のルート */}
                            <Route exact path={routes.device.new}>
                                <components.device.New />
                            </Route>
                            {/* デバイス一括登録のルート */}
                            <Route exact path={routes.device.collective.index}>
                                <components.device.Register />
                            </Route>
                            {/* デバイス詳細のルート */}
                            <Route exact path={routes.device.edit}>
                                <components.device.Edit />
                            </Route>
                            {/* 通常業務/転送先の一括登録のルート */}
                            <Route exact path={routes.registrationEndpoint.index}>
                                <components.registrationEndpoint.Register />
                            </Route>
                            {/* クラウド接続のルート */}
                            <Route exact path={routes.cloudSetting.index}>
                                <components.cloudSetting.List />
                            </Route>
                            {/* クラウド接続詳細のルート */}
                            <Route exact path={routes.cloudSetting.edit}>
                                <components.cloudSetting.Edit />
                            </Route>
                            {/* メンバー認可管理のルート */}
                            <Route exact path={routes.authManagement.index}>
                                <components.authManagement.List />
                            </Route>
                            {/* メンバー認可詳細画面のルート */}
                            <Route exact path={routes.authManagement.detail}>
                                <components.authManagement.detail.List />
                            </Route>
                            {/* QS,SpeedocのGoogle通常業務移行 */}
                            <Route exact path={routes.migration.google}>
                                <components.googleMigration />
                            </Route>
                            {/* AI-OCRライセンス発行のルート*/}
                            <Route exact path={routes.license.order}>
                                <components.license.List />
                            </Route>
                            {/* AI-OCRライセンス発行確認画面のルート */}
                            <Route exact path={routes.license.detail}>
                                <components.license.detail.Detail />
                            </Route>
                            {/* AI-OCRのライセンス一覧のルート */}
                            <Route exact path={routes.aiOcr.index}>
                                <components.aiOcrManagement.AIocr />
                            </Route>
                        </Switch>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
