import { parse } from 'query-string';
import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import logger from '@/common/utils/logger';
import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

// ワークスペースユーザー取得
export const findWorkspaceUser = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceuserIndexResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceuserIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/workspaceusers`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ワークスペース単体取得
export const showWorkspace = async (displayId: string, auth: string): Promise<schema.V1WorkspaceShowResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${displayId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// サブワークスペース一覧取得
export const findSubWorkspaces = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceIndexResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/list`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// サブワークスペースに紐づくmfp一覧取得
export const findSubWorkspaceMfpCombinations = async (req: schema.V1WorkspaceMfpsListIndexRequest, auth: string): Promise<schema.V1WorkspaceMfpsListIndexResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceMfpsListIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/mfps/list`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ワークスペース更新
export const updateWorkspace = async (id: string, auth: string, req: schema.V1WorkspaceUpdateRequest): Promise<schema.V1WorkspaceUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${id}`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

// ワークスペース一時停止
export const suspendWorkspace = async (id: string, auth: string, original: schema.V1ObjectsWorkspace): Promise<schema.V1WorkspaceUpdateResponse> => {
    const req: schema.V1WorkspaceUpdateRequest = {
        displayId: original.displayId,
        displayName: original.displayName,
        enableSubWorkspace: original.enableSubWorkspace!,
        language: original.language!,
        logoUrl: original.logoUrl!,
        active: false,
    };
    const response = await updateWorkspace(id, auth, req);
    return response;
};

// ワークスペース一時停止解除
export const resumeWorkspace = async (id: string, auth: string, original: schema.V1ObjectsWorkspace): Promise<schema.V1WorkspaceUpdateResponse> => {
    const req: schema.V1WorkspaceUpdateRequest = {
        displayId: original.displayId,
        displayName: original.displayName,
        enableSubWorkspace: original.enableSubWorkspace!,
        language: original.language!,
        logoUrl: original.logoUrl!,
        active: true,
    };
    const response = await updateWorkspace(id, auth, req);
    return response;
};

// ワークスペース有効化（招待Eメールを送信せず利用開始）
export const activateWorkspace = async (id: string, auth: string, original: schema.V1ObjectsWorkspace): Promise<schema.V1WorkspaceUpdateResponse> => {
    const req: schema.V1WorkspaceUpdateRequest = {
        displayId: original.displayId,
        displayName: original.displayName,
        enableSubWorkspace: original.enableSubWorkspace!,
        language: original.language!,
        logoUrl: original.logoUrl!,
        active: true,
    };
    const response = await updateWorkspace(id, auth, req);
    return response;
};

// ワークスペース削除
export const deleteWorkspace = async (id: string, auth: string): Promise<schema.V1WorkspaceDestroyResponse> => {
    const response = await axiosFactory.delete<schema.V1WorkspaceDestroyResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ワークスペース招待Eメール送信
export const workspaceInviteMail = async (auth: string, req: schema.V1WorkspaceInviteMailCreateRequest): Promise<schema.V1WorkspaceInviteMailCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceInviteMailCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/invite/mail`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// TODO saml
export const saml = async (workspaceId: string): Promise<string> => {
    if (!workspaceId) {
        throw new Error('workspaceId input is empty');
    }

    // const client = new restm.RestClient('workspace');
    // Sending request to API service.
    // This will implicitly throw on error.
    // const response = await client.create<schema.V1WorkspacePasswordResetResponse>(`https://${consts.environment.api.hostname}/v1/workspace/reset`, {
    //   workspaceId: workspaceId,
    // });

    // return response.result!.xxxxx;
    return 'TODO';
};

// location.search からワークスペースメンバ招待検証APIのRequestを作成する
export const parseWorkspaceInviteVerifyQueryString = (given: string): schema.V1WorkspaceInviteVerifyCreateRequest => {
    const got = parse(given);
    if (!got) {
        throw new Error('invalid');
    }
    if (!got.email || typeof got.email !== 'string') {
        throw new Error('given email is invalid');
    }
    if (!got.token || typeof got.token !== 'string') {
        throw new Error('given token is invalid');
    }
    const req: schema.V1WorkspaceInviteVerifyCreateRequest = {
        email: got.email as string,
        token: got.token as string,
    };
    return req;
};

// 招待メール無しのフラグ取得
export const parseWithoutEmailInvite = (given: string): boolean => {
    const got = parse(given);
    if (got && got.withoutEmail && got.withoutEmail === 'true') {
        return true;
    }
    return false;
};

// ワークスペースメンバ招待検証API
export const workspaceInviteVerify = async (req: schema.V1WorkspaceInviteVerifyCreateRequest): Promise<schema.V1WorkspaceInviteVerifyCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceInviteVerifyCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/invite/verify`, req);
    return response.data;
};

// displayId, displayName が初期値かどうか
export const isInitialValue = (str: string): boolean => {
    return ['WORKSPACE-', 'SPACE-', 'USERSPACE-'].some((prefix) => str.startsWith(prefix));
};

// ワークスペースサインアップAPIのRequestを作成する
export const parseWorkspaceSignupRequest = (form: model.Form): schema.V1WorkspaceInviteVerifyUpdateRequest => {
    logger.debug('parseWorkspaceSignupRequest');

    const user: schema.V1WorkspaceuserUpdateRequest = {
        active: form.active,
        language: form.userLang as schema.Language,
        name: form.name,
        phoneticName: form.phoneticName,
        role: form.role,
        deviceLoginUser: form.deviceLoginUser,
        pin: form.pin,
        contactEmail: form.contactEmail,
    };

    const organizationDetail: schema.V1ObjectsWorkspacesOrganizationDetail = {
        address1: form.address1,
        address2: form.address2,
        country: form.country,
        customerId: form.customerId,
        name: form.organizationName,
        phoneNumber: form.phoneNumber,
        zipCode: form.zipCode,
    };

    const workspace: schema.V1WorkspaceUpdateRequest = {
        organizationDetail,
        displayId: form.workspaceId,
        displayName: form.workspaceName,
        enableSubWorkspace: form.enableSubWorkspace,
        language: form.workspaceLang as schema.Language,
        logoUrl: form.logoUrl,
    };

    const req: schema.V1WorkspaceInviteVerifyUpdateRequest = {
        user,
        workspace,
        displayId: form.workspaceId,
        email: form.mail,
        password: form.password,
        token: form.token,
        withoutEmail: form.withoutEmail,
    };
    return req;
};

// ワークスペースサインアップAPI
export const workspaceSignup = async (req: schema.V1WorkspaceInviteVerifyUpdateRequest, completeMail: boolean): Promise<schema.V1WorkspaceInviteVerifyUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceInviteVerifyUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/invite/verify?completeMail=${completeMail}`, req);
    return responce.data;
};

// export const getExtensions = async (workspaceId: string, auth: string): Promise<schema.V1ExtensionsIndexResponse> => {
//     if (!workspaceId || !auth) {
//         throw new Error('input is empty');
//     }
//     const client = new restm.RestClient('extension-list');
//     const response = await client.get<schema.V1ExtensionsIndexResponse>(`https://${consts.environment.api.hostname}/v1/extensions?id=${workspaceId}`, authorizationHeader(auth));
//     return response.result!;
// };

export const getEventLogAggregateResults = async (req: schema.V1WorkspaceEventlogAggregateResultsIndexRequest, auth: string): Promise<schema.V1WorkspaceEventlogAggregateResultsIndexResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceEventlogAggregateResultsIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/eventlog-aggregate-results`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ワークスペースのライセンス一覧取得
export const findWorkspaceLicenses = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceLicensesIndexResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceLicensesIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/licenses`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
