import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as schema from '@/bundles/schema/typescript/schema';
import { csvFormat4Endpoint } from '@/common/constants/csvFormat';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import * as errLocale from '@/common/utils/locale/error-locale';
import { zenkaku2Hankaku } from '@/common/utils/webappUtil';
import { axiosFactory } from '@/common/utils/axiosFactory';

// エンドポイント一括登録
export const createCollectiveEndpoint = async (request: schema.V1EndpointsCollectiveCreateRequest, auth: string) => {
    const response = await axiosFactory.post(`https://${consts.environment.api.hostname}/v1/endpoints/collective`, request, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// エンドポイントの総件数取得
export const getCollectiveEndpointCount = async (workspace: string, users: string[], auth: string): Promise<schema.V1EndpointsCollectiveExportCountCreateResponse> => {
    const body: schema.V1EndpointsCollectiveExportCreateRequest = {
        workspace: workspace,
        users: users,
    };

    const response = await axiosFactory.post<schema.V1EndpointsCollectiveExportCountCreateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/collective/export/count`, body, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
// エンドポイントエクスポート
export const exportCsvRequest = async (workspace: string, users: string[], auth: string, limit: number = 0, offset: number = 0): Promise<schema.V1EndpointsCollectiveExportCreateResponse> => {
    const body: schema.V1EndpointsCollectiveExportCreateRequest = {
        workspace: workspace,
        users: users,
        limit,
        offset,
    };

    const response = await axiosFactory.post<schema.V1EndpointsCollectiveExportCreateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/collective/export`, body, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// エンドポイント移行
export const migrateCollectiveEndpoint = async (request: schema.V1EndpointsCollectiveMigrateCreateRequest, auth: string) => {
    const response = await axiosFactory.post(`https://${consts.environment.api.hostname}/v1/endpoints/collective/migrate`, request, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * バリデーションチェック
 */

// 存在のチェック
export const validations = (): validator.Constraints => {
    return {
        existence: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        existence: null,
    };
};

// メールアドレスチェック（形式・長さ）
export const emailValidations = (index: number): validator.Constraints => {
    return {
        email: {
            userEmail: {
                message: errLocale.translate(errLocale.keys.E08005, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_EMAIL + 1,
                }).message,
            },
            length: {
                maximum: csvFormat4Endpoint.MAIL_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08004, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_EMAIL + 1,
                }).message,
            },
        },
    };
};

export const NewEmailValidation = (): validator.ValidationOutput => {
    return {
        email: null,
    };
};

// 通常業務名前のチェック（存在・長さ）
export const endpointNameValidations = (index: number): validator.Constraints => {
    return {
        endpointName: {
            presenceSpaceOk: {
                message: errLocale.translate(errLocale.keys.E08007, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_NAME + 1,
                }).message,
            },
            strByteLength: {
                maximum: csvFormat4Endpoint.NAME_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08008, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_NAME + 1,
                }).message,
            },
        },
    };
};

export const newEndpointNameValidations = (): validator.ValidationOutput => {
    return {
        endpointName: null,
    };
};

// アイコンのチェック（存在・形式）
export const endpointIconValidations = (index: number): validator.Constraints => {
    return {
        endpointIcon: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08009, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ICON + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.ICON,
                message: errLocale.translate(errLocale.keys.E08010, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ICON + 1,
                }).message,
            },
        },
    };
};

export const newEndpointIconValidations = (): validator.ValidationOutput => {
    return {
        endpointIcon: null,
    };
};

// ラベル色のチェック（存在・形式）
export const labelColorValidations = (index: number): validator.Constraints => {
    return {
        labelColor: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08011, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_LABELCOLOR + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.LABELCOLOR,
                message: errLocale.translate(errLocale.keys.E08012, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_LABELCOLOR + 1,
                }).message,
            },
        },
    };
};

export const newLabelColorValidations = (): validator.ValidationOutput => {
    return {
        labelColor: null,
    };
};

// スキャン可否のチェック
export const scanValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08013, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SCAN + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08014, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SCAN + 1,
                }).message,
            },
        },
    };
};

export const newScanValidations = (): validator.ValidationOutput => {
    return {
        scan: null,
    };
};

// プリント可否のチェック
export const printValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08015, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINT + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08016, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINT + 1,
                }).message,
            },
        },
    };
};

export const newPrintValidations = (): validator.ValidationOutput => {
    return {
        print: null,
    };
};

// Eメール通知：操作完了時のチェック
export const finishNoticeValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08017, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FINISHNOTICE + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08018, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FINISHNOTICE + 1,
                }).message,
            },
        },
    };
};

export const newFinishNoticeValidations = (): validator.ValidationOutput => {
    return {
        finishNotice: null,
    };
};

// Eメール通知：エラー発生時のチェック
export const errorNoticeValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08019, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ERRORNOTICE + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08020, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ERRORNOTICE + 1,
                }).message,
            },
        },
    };
};

export const newErrorNoticeValidations = (): validator.ValidationOutput => {
    return {
        errorNotice: null,
    };
};

//接続方法のチェック（存在・形式）
export const connectionValidations = (index: number): validator.Constraints => {
    return {
        connection: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08021, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_CONNECTION + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.CONNECTION,
                message: errLocale.translate(errLocale.keys.E08022, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_CONNECTION + 1,
                }).message,
            },
        },
    };
};

export const newConnectionValidations = (): validator.ValidationOutput => {
    return {
        connection: null,
    };
};

// 認可IDのチェック（存在・長さ・形式）
export const idValidations = (index: number): validator.Constraints => {
    return {
        id: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08023, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ID + 1,
                }).message,
            },
            userEmail: {
                message: errLocale.translate(errLocale.keys.E08025, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ID + 1,
                }).message,
            },
            length: {
                maximum: csvFormat4Endpoint.ID_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08024, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ID + 1,
                }).message,
            },
        },
    };
};

export const newIdValidations = (): validator.ValidationOutput => {
    return {
        id: null,
    };
};

// サイト名のチェック（長さのみ）
export const siteValidations = (index: number): validator.Constraints => {
    return {
        site: {
            strByteLength: {
                maximum: csvFormat4Endpoint.SITE_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08026, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SITE + 1,
                }).message,
            },
        },
    };
};

export const newSiteValidations = (): validator.ValidationOutput => {
    return {
        site: null,
    };
};

// サイト名の存在チェック（SharePointのみの追加条件）
export const siteValidationsSharePoint = (index: number): validator.Constraints => {
    return {
        existence: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08186, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SITE + 1,
                }).message,
            },
        },
    };
};

export const newSiteValidationsSharePoint = (): validator.ValidationOutput => {
    return {
        existence: null,
    };
};

// ライブラリ名のチェック（長さのみ）
export const libraryValidations = (index: number): validator.Constraints => {
    return {
        library: {
            strByteLength: {
                maximum: csvFormat4Endpoint.LIBRARY_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08027, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_LIBRARY + 1,
                }).message,
            },
        },
    };
};

export const newLibraryValidations = (): validator.ValidationOutput => {
    return {
        library: null,
    };
};

// ライブラリ名の存在チェック（SharePointのみの追加条件）
export const libraryValidationsSharePoint = (index: number): validator.Constraints => {
    return {
        existence: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08187, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_LIBRARY + 1,
                }).message,
            },
        },
    };
};

export const newLibraryValidationsSharePoint = (): validator.ValidationOutput => {
    return {
        existence: null,
    };
};

// ディレクトリ名のチェック（長さ、 \ : , ; * ? " < > | は入力不可）
export const directoryValidations = (index: number): validator.Constraints => {
    return {
        directory: {
            strByteLength: {
                maximum: csvFormat4Endpoint.DIRECTORY_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08028, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DIRECTORY + 1,
                }).message,
            },
            regularExpression: {
                allow: csvFormat4Endpoint.DIRECTORY,
                message: errLocale.translate(errLocale.keys.E08188, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DIRECTORY + 1,
                }).message,
            },
        },
    };
};

export const newDirectoryValidations = (): validator.ValidationOutput => {
    return {
        directory: null,
    };
};

// ディレクトリ名の存在チェック（googleteamdriveのみの追加条件）
export const directoryValidationsGoogleShareDrive = (index: number): validator.Constraints => {
    return {
        existence: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08189, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DIRECTORY + 1,
                }).message,
            },
        },
    };
};

export const newDirectoryValidationsGoogleShareDrive = (): validator.ValidationOutput => {
    return {
        existence: null,
    };
};

// ファイル名形式のチェック（形式）
export const nameFormatValidations = (index: number): validator.Constraints => {
    return {
        nameFormat: {
            nameFormatWord: {
                allow: csvFormat4Endpoint.NAMEFORMAT,
                message: errLocale.translate(errLocale.keys.E08169, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_NAMEFORMAT + 1,
                }).message,
            },
            sameItem: {
                separator: csvFormat4Endpoint.NAMEFORMAT_SPLIT,
                message: errLocale.translate(errLocale.keys.E08193, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_NAMEFORMAT + 1,
                }).message,
            },
        },
    };
};

export const newNameFormatValidations = (): validator.ValidationOutput => {
    return {
        nameFormat: null,
    };
};

// 日付_年のチェック
export const yearValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08033, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_YEAR + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08034, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_YEAR + 1,
                }).message,
            },
        },
    };
};

export const newYearValidations = (): validator.ValidationOutput => {
    return {
        year: null,
    };
};

// 日付_月のチェック
export const monthValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08035, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_MONTH + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08036, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_MONTH + 1,
                }).message,
            },
        },
    };
};

export const newMonthValidations = (): validator.ValidationOutput => {
    return {
        month: null,
    };
};

// 日付_日のチェック
export const dayValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08037, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DAY + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08038, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DAY + 1,
                }).message,
            },
        },
    };
};

export const newDayValidations = (): validator.ValidationOutput => {
    return {
        day: null,
    };
};

// 日付_時分秒のチェック
export const timeValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08039, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TIME + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08040, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TIME + 1,
                }).message,
            },
        },
    };
};

export const newTimeValidations = (): validator.ValidationOutput => {
    return {
        time: null,
    };
};

// 固定文字のチェック（重複禁止）
export const fixedSameValidations = (index: number): validator.Constraints => {
    return {
        fixedSame: {
            sameItem: {
                separator: csvFormat4Endpoint.FIXED_SPLIT,
                message: errLocale.translate(errLocale.keys.E08190, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FIXED + 1,
                }).message,
            },
        },
    };
};

export const newFixedSameValidations = (): validator.ValidationOutput => {
    return {
        fixedSame: null,
    };
};

// 先頭の固定文字のチェック
export const startFixedValidations = (index: number): validator.Constraints => {
    return {
        startFixed: {
            regularExpression: {
                allow: csvFormat4Endpoint.FIXED_START,
                message: errLocale.translate(errLocale.keys.E08192, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FIXED + 1,
                }).message,
            },
        },
    };
};

export const newStartFixedValidations = (): validator.ValidationOutput => {
    return {
        startFixed: null,
    };
};

// 固定文字のチェック（_vti_ ..(連続したドット) \ ¥ : , ; * ? “ < > | を含まない）
export const fixedValidations = (index: number): validator.Constraints => {
    return {
        fixed: {
            regularExpression: {
                allow: csvFormat4Endpoint.FIXED,
                message: errLocale.translate(errLocale.keys.E08191, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FIXED + 1,
                }).message,
            },
        },
    };
};

export const newFixedValidations = (): validator.ValidationOutput => {
    return {
        fixed: null,
    };
};

// 任意文字未入力時読み取り許可がbooleanかチェック
export const optionalFixedValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08044, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_OPTIONAL + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08045, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_OPTIONAL + 1,
                }).message,
            },
        },
    };
};

export const newOptionalFixedValidations = (): validator.ValidationOutput => {
    return {
        optionalFixed: null,
    };
};

// セパレーターのチェック（形式）
export const separatorValidations = (index: number): validator.Constraints => {
    return {
        separator: {
            regularExpression: {
                allow: csvFormat4Endpoint.SEPARATOR,
                message: errLocale.translate(errLocale.keys.E08047, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SEPARATOR + 1,
                }).message,
            },
        },
    };
};

export const newSeparatorValidations = (): validator.ValidationOutput => {
    return {
        separator: null,
    };
};

// 連番付のチェック（存在・形式）
export const sequenceValidations = (index: number): validator.Constraints => {
    return {
        sequence: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08048, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SEQUENCE + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.SEQUENCE,
                message: errLocale.translate(errLocale.keys.E08049, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SEQUENCE + 1,
                }).message,
            },
        },
    };
};

export const newSequenceValidations = (): validator.ValidationOutput => {
    return {
        sequence: null,
    };
};

// 表示形式ロックがbooleanかチェック
export const formatLockValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08050, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FORMATLOCK + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08051, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FORMATLOCK + 1,
                }).message,
            },
        },
    };
};

export const newFormatLockValidations = (): validator.ValidationOutput => {
    return {
        formatLock: null,
    };
};

// 単語帳のチェック（長さのみ）
export const wordListValidations = (index: number, row: number, wordListIdx: number): validator.Constraints => {
    return {
        wordlist: {
            strByteLength: {
                maximum: csvFormat4Endpoint.WORD_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08052, {
                    index: index,
                    row: row,
                    N: wordListIdx,
                }).message,
            },
        },
    };
};

export const newWordListValidations = (): validator.ValidationOutput => {
    return {
        sequence: null,
    };
};

// 単語のチェック（形式）
export const wordValidations = (index: number, row: number, wordListIdx: number, wordIdx: number): validator.Constraints => {
    return {
        word: {
            regularExpression: {
                allow: csvFormat4Endpoint.WORD,
                message: errLocale.translate(errLocale.keys.E08053, {
                    index: index,
                    row: row,
                    N: wordListIdx,
                    M: wordIdx,
                }).message,
            },
        },
    };
};

export const newWordValidations = (): validator.ValidationOutput => {
    return {
        word: null,
    };
};

// フォルダ階層数のチェック（存在・形式）
export const depthValidations = (index: number): validator.Constraints => {
    return {
        depth: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08054, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DEPTH + 1,
                }).message,
            },
            regularExpression: {
                allow: csvFormat4Endpoint.DEPTH,
                message: errLocale.translate(errLocale.keys.E08055, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DEPTH + 1,
                }).message,
            },
        },
    };
};

export const newDepthValidations = (): validator.ValidationOutput => {
    return {
        depth: null,
    };
};

// アイコン表示がbooleanかチェック
export const displayValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08056, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DISPLAY + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08057, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DISPLAY + 1,
                }).message,
            },
        },
    };
};

export const newDisplayValidations = (): validator.ValidationOutput => {
    return {
        display: null,
    };
};

// 自動選択表示がbooleanかチェック
export const autoValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08058, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_AUTO + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08059, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_AUTO + 1,
                }).message,
            },
        },
    };
};

export const newAutoValidations = (): validator.ValidationOutput => {
    return {
        auto: null,
    };
};

// フォルダ表示形式のチェック（形式のみ）
export const formValidations = (index: number): validator.Constraints => {
    return {
        form: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.FORM,
                message: errLocale.translate(errLocale.keys.E08060, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FORM + 1,
                }).message,
            },
        },
    };
};

export const newFormValidations = (): validator.ValidationOutput => {
    return {
        form: null,
    };
};

// 階層タイトル名のチェック（長さのみ）
export const titleValidations = (index: number, titleIdx: number): validator.Constraints => {
    let message = '';
    switch (titleIdx) {
        case csvFormat4Endpoint.COL_IDX_TITLE1:
            message = errLocale.translate(errLocale.keys.E08061, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_TITLE1 + 1,
            }).message;
            break;
        case csvFormat4Endpoint.COL_IDX_TITLE2:
            message = errLocale.translate(errLocale.keys.E08062, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_TITLE2 + 1,
            }).message;
            break;
        case csvFormat4Endpoint.COL_IDX_TITLE3:
            message = errLocale.translate(errLocale.keys.E08063, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_TITLE3 + 1,
            }).message;
            break;
        case csvFormat4Endpoint.COL_IDX_TITLE4:
            message = errLocale.translate(errLocale.keys.E08064, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_TITLE4 + 1,
            }).message;
            break;
    }
    return {
        title: {
            strByteLength: {
                maximum: csvFormat4Endpoint.TITLE_MAX_LENGTH,
                message: message,
            },
        },
    };
};

export const newTitleValidations = (): validator.ValidationOutput => {
    return {
        title: null,
    };
};

// パスワード（暗号化アリ）のチェック（長さ・形式）
export const passwordCipValidations = (index: number): validator.Constraints => {
    return {
        password: {
            strByteLength: {
                maximum: csvFormat4Endpoint.PASSWORD_CIP_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08065, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PASSWORD + 1,
                }).message,
            },
            regularExpression: {
                allow: csvFormat4Endpoint.PASSWORD,
                message: errLocale.translate(errLocale.keys.E08170, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PASSWORD + 1,
                }).message,
            },
        },
    };
};

// パスワード（暗号化ナシ）のチェック（長さ・形式）
export const passwordValidations = (index: number): validator.Constraints => {
    return {
        password: {
            strByteLength: {
                maximum: csvFormat4Endpoint.PASSWORD_MAX_LENGTH,
                message: errLocale.translate(errLocale.keys.E08065, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PASSWORD + 1,
                }).message,
            },
            regularExpression: {
                allow: csvFormat4Endpoint.PASSWORD,
                message: errLocale.translate(errLocale.keys.E08170, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PASSWORD + 1,
                }).message,
                allowSpace: true,
            },
        },
    };
};

export const newPasswordValidations = (): validator.ValidationOutput => {
    return {
        password: null,
    };
};

// プレビュー表示がbooleanかチェック
export const previewValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08066, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PREVIEW + 1,
                }).message,
            },
        },
    };
};

export const newPreviewValidations = (): validator.ValidationOutput => {
    return {
        preview: null,
    };
};

// 上書き警告がbooleanかチェック
export const warningValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08067, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_WARNING + 1,
                }).message,
            },
        },
    };
};

export const newWarningValidations = (): validator.ValidationOutput => {
    return {
        warning: null,
    };
};

// ファイル名衝突時のチェック（形式のみ）
export const conflictValidations = (index: number): validator.Constraints => {
    return {
        conflict: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.CONFLICT,
                message: errLocale.translate(errLocale.keys.E08172, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_CONFLICT + 1,
                }).message,
            },
        },
    };
};

export const newConflictValidations = (): validator.ValidationOutput => {
    return {
        conflict: null,
    };
};

// 不定形サイズのチェック（数値）
export const irregularSizeValidations = (index: number, idx: number): validator.Constraints => {
    let numericalityMessage = '';
    switch (idx) {
        case csvFormat4Endpoint.COL_IDX_HEIGHT1:
            numericalityMessage = errLocale.translate(errLocale.keys.E08069, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_HEIGHT1 + 1,
            }).message;

            break;
        case csvFormat4Endpoint.COL_IDX_WIDTH1:
            numericalityMessage = errLocale.translate(errLocale.keys.E08071, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_WIDTH1 + 1,
            }).message;

            break;
        case csvFormat4Endpoint.COL_IDX_HEIGHT2:
            numericalityMessage = errLocale.translate(errLocale.keys.E08073, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_HEIGHT2 + 1,
            }).message;

            break;
        case csvFormat4Endpoint.COL_IDX_WIDTH2:
            numericalityMessage = errLocale.translate(errLocale.keys.E08075, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_WIDTH2 + 1,
            }).message;

            break;
        case csvFormat4Endpoint.COL_IDX_HEIGHT3:
            numericalityMessage = errLocale.translate(errLocale.keys.E08077, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_HEIGHT3 + 1,
            }).message;

            break;
        case csvFormat4Endpoint.COL_IDX_WIDTH3:
            numericalityMessage = errLocale.translate(errLocale.keys.E08079, {
                index: index,
                row: csvFormat4Endpoint.COL_IDX_WIDTH3 + 1,
            }).message;

            break;
    }

    return {
        size: {
            numericality: {
                greaterThanOrEqualTo: csvFormat4Endpoint.SIZE_MIN,
                lessThanOrEqualTo: csvFormat4Endpoint.SIZE_MAX,
                message: numericalityMessage,
            },
        },
    };
};

export const newIrregularSizeValidations = (): validator.ValidationOutput => {
    return {
        size: null,
    };
};

// 出力ファイルのチェック（形式）
export const saveFormatValidations = (index: number): validator.Constraints => {
    return {
        saveFormat: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.FORMAT,
                message: errLocale.translate(errLocale.keys.E08081, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FORMAT + 1,
                }).message,
            },
        },
    };
};

export const newSaveFormatValidations = (): validator.ValidationOutput => {
    return {
        saveFormat: null,
    };
};

//原稿セット方向のチェック
export const orientationValidations = (index: number): validator.Constraints => {
    return {
        orientation: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.ORIENTATION,
                message: errLocale.translate(errLocale.keys.E08083, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_ORIENTATION + 1,
                }).message,
            },
        },
    };
};

export const newOrientationValidations = (): validator.ValidationOutput => {
    return {
        orientation: null,
    };
};

//原稿面のチェック
export const paperSideValidations = (index: number): validator.Constraints => {
    return {
        paperSide: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.DOCUMENT,
                message: errLocale.translate(errLocale.keys.E08085, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DOCUSIDE + 1,
                }).message,
            },
        },
    };
};

export const newPaperSideValidations = (): validator.ValidationOutput => {
    return {
        paperSide: null,
    };
};

// 大量原稿のbooleanのチェック
export const sevenalValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08086, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SEVENAL + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08087, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SEVENAL + 1,
                }).message,
            },
        },
    };
};

export const newSevenalValidations = (): validator.ValidationOutput => {
    return {
        sevenal: null,
    };
};

// SADFのbooleanのチェック
export const sadfValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08088, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SADF + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08089, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SADF + 1,
                }).message,
            },
        },
    };
};

export const newSadfValidations = (): validator.ValidationOutput => {
    return {
        sadf: null,
    };
};

// 読み取りサイズのチェック
export const paperSizeValidations = (index: number): validator.Constraints => {
    return {
        paperSize: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.SIZE.map((value) => value.toUpperCase()),
                message: errLocale.translate(errLocale.keys.E08092, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
                }).message,
            },
        },
    };
};

export const newPaperSizeValidations = (): validator.ValidationOutput => {
    return {
        paperSize: null,
    };
};

// サイズ混載のbooleanのチェック
export const mixedValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08096, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_MIX + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08097, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_MIX + 1,
                }).message,
            },
        },
    };
};

export const newMixedValidations = (): validator.ValidationOutput => {
    return {
        mixed: null,
    };
};

// 集約設定のチェック
export const docSummaryValidations = (index: number): validator.Constraints => {
    return {
        docSummary: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.DOCSUMMARY,
                message: errLocale.translate(errLocale.keys.E08100, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DOCSUMMARY + 1,
                }).message,
            },
        },
    };
};

export const newDocSummaryValidations = (): validator.ValidationOutput => {
    return {
        docSummary: null,
    };
};

// 解像度のチェック
export const resolutionValidations = (index: number): validator.Constraints => {
    return {
        resolution: {
            regularExpression: {
                allow: csvFormat4Endpoint.RESOLUTION,
                message: errLocale.translate(errLocale.keys.E08104, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_RESOLUTION + 1,
                }).message,
            },
        },
    };
};

export const newResolutionValidations = (): validator.ValidationOutput => {
    return {
        resolution: null,
    };
};

// 解像度ロックのbooleanのチェック
export const resolutionLockValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08105, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_RESOLUTIONLOCK + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08106, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_RESOLUTIONLOCK + 1,
                }).message,
            },
        },
    };
};

export const newResolutionLockValidations = (): validator.ValidationOutput => {
    return {
        resolutionLock: null,
    };
};

// 原稿種類のチェック
export const typeValidations = (index: number): validator.Constraints => {
    return {
        type: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.TYPE,
                message: errLocale.translate(errLocale.keys.E08108, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TYPE + 1,
                }).message,
            },
        },
    };
};

export const newTypeValidations = (): validator.ValidationOutput => {
    return {
        type: null,
    };
};

// 原稿種類ロックのbooleanのチェック
export const typeLockValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08112, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TYPELOCK + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08113, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TYPELOCK + 1,
                }).message,
            },
        },
    };
};

export const newTypeLockValidations = (): validator.ValidationOutput => {
    return {
        typeLock: null,
    };
};

// 濃度のチェック（存在・数値）
export const grayLevelValidations = (index: number): validator.Constraints => {
    return {
        grayLevel: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08114, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_GRAYLEVEL + 1,
                }).message,
            },
            numericality: {
                greaterThanOrEqualTo: csvFormat4Endpoint.LEVEL_MIN,
                lessThanOrEqualTo: csvFormat4Endpoint.LEVEL_MAX,
                message: errLocale.translate(errLocale.keys.E08115, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_GRAYLEVEL + 1,
                }).message,
            },
        },
    };
};

export const newGrayLevelValidations = (): validator.ValidationOutput => {
    return {
        grayLevel: null,
    };
};

// 自動濃度のbooleanのチェック
export const autoGrayValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08116, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_AUTOGRAYLEVEL + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08117, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_AUTOGRAYLEVEL + 1,
                }).message,
            },
        },
    };
};

export const newAutoGrayValidations = (): validator.ValidationOutput => {
    return {
        autoGray: null,
    };
};

// 枠消去のチェック
export const eraseValidations = (index: number): validator.Constraints => {
    return {
        erase: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.FRAMEERASE,
                message: errLocale.translate(errLocale.keys.E08118, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_FRAMEERASE + 1,
                }).message,
            },
        },
    };
};

export const newEraseValidations = (): validator.ValidationOutput => {
    return {
        erase: null,
    };
};

// 枠消去サイズのチェック（値のrangeのみ）
export const eraseSizeValidations = (): validator.Constraints => {
    return {
        eraseSize: {
            numericality: {
                greaterThanOrEqualTo: csvFormat4Endpoint.ERASE_MIN,
                lessThanOrEqualTo: csvFormat4Endpoint.ERASE_MAX,
                message: 'dummy message',
            },
        },
    };
};

export const newEraseSizeValidations = (): validator.ValidationOutput => {
    return {
        eraseSize: null,
    };
};

// 白紙ページ削除のbooleanのチェック
export const blankValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08125, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_BLANK + 1,
                }).message,
            },
        },
    };
};

export const newBlankValidations = (): validator.ValidationOutput => {
    return {
        blank: null,
    };
};

// 印刷対象形式のチェック
export const printFormatValidations = (index: number): validator.Constraints => {
    return {
        printFormat: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.PRINTFORMAT,
                message: errLocale.translate(errLocale.keys.E08126, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINTFORMAT + 1,
                }).message,
            },
        },
    };
};

export const newPrintFormatValidations = (): validator.ValidationOutput => {
    return {
        printFormat: null,
    };
};

// 印刷条件：カラー/白黒のチェック
export const colorValidations = (index: number): validator.Constraints => {
    return {
        color: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.COLOR,
                message: errLocale.translate(errLocale.keys.E08128, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_COLOR + 1,
                }).message,
            },
        },
    };
};

export const newColorValidations = (): validator.ValidationOutput => {
    return {
        color: null,
    };
};

// 印刷条件：部数のチェック（存在・range）
export const copiesValidations = (index: number): validator.Constraints => {
    return {
        copies: {
            numericality: {
                greaterThanOrEqualTo: csvFormat4Endpoint.COPIES_MIN,
                lessThanOrEqualTo: csvFormat4Endpoint.COPIES_MAX,
                message: errLocale.translate(errLocale.keys.E08130, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_COPIES + 1,
                }).message,
            },
        },
    };
};

export const newCopiesValidations = (): validator.ValidationOutput => {
    return {
        copies: null,
    };
};

// 印刷条件：給紙トレイのチェック
export const trayValidations = (index: number): validator.Constraints => {
    return {
        tray: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08131, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TRAY + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.TRAY,
                message: errLocale.translate(errLocale.keys.E08132, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_TRAY + 1,
                }).message,
            },
        },
    };
};

export const newTrayValidations = (): validator.ValidationOutput => {
    return {
        tray: null,
    };
};

// 印刷条件：面の確認
export const sideValidations = (index: number): validator.Constraints => {
    return {
        side: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08133, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINTSIDE + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.PRINTSIDE,
                message: errLocale.translate(errLocale.keys.E08134, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINTSIDE + 1,
                }).message,
            },
        },
    };
};

export const newSideValidations = (): validator.ValidationOutput => {
    return {
        side: null,
    };
};

// 印刷条件：集約の確認
export const printSummaryValidations = (index: number): validator.Constraints => {
    return {
        printSummary: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08136, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINTSUMMARY + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.PRINTSUMMARY,
                message: errLocale.translate(errLocale.keys.E08137, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PRINTSUMMARY + 1,
                }).message,
            },
        },
    };
};

export const newPrintSummaryValidations = (): validator.ValidationOutput => {
    return {
        printSummary: null,
    };
};

// 集約方向の確認
export const directionValidations = (index: number): validator.Constraints => {
    return {
        direction: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08139, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DIRECTION + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.DIRECTION,
                message: errLocale.translate(errLocale.keys.E08140, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DIRECTION + 1,
                }).message,
            },
        },
    };
};

export const newDirectionValidations = (): validator.ValidationOutput => {
    return {
        direction: null,
    };
};

// 印刷条件：集約仕切り線のbooleanのチェック
export const lineValidations = (index: number): validator.Constraints => {
    return {
        boo: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08141, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_LINE + 1,
                }).message,
            },
            csvBooFormat: {
                message: errLocale.translate(errLocale.keys.E08142, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_LINE + 1,
                }).message,
            },
        },
    };
};

export const newLineValidations = (): validator.ValidationOutput => {
    return {
        line: null,
    };
};

// 変倍の確認
export const doublingValidations = (index: number): validator.Constraints => {
    return {
        doubling: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08143, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DOUBLING + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.DOUBLING,
                message: errLocale.translate(errLocale.keys.E08144, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_DOUBLING + 1,
                }).message,
            },
        },
    };
};

export const newDoublingValidations = (): validator.ValidationOutput => {
    return {
        doubling: null,
    };
};

// ソートの確認
export const sortValidations = (index: number): validator.Constraints => {
    return {
        sort: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08145, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SORT + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.SORT,
                message: errLocale.translate(errLocale.keys.E08146, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_SORT + 1,
                }).message,
            },
        },
    };
};

export const newSortValidations = (): validator.ValidationOutput => {
    return {
        sort: null,
    };
};

// ステープルの確認
export const stapleValidations = (index: number): validator.Constraints => {
    return {
        staple: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08149, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.STAPLE,
                message: errLocale.translate(errLocale.keys.E08150, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_STAPLE + 1,
                }).message,
            },
        },
    };
};

export const newStapleValidations = (): validator.ValidationOutput => {
    return {
        staple: null,
    };
};

// パンチの確認
export const punchValidations = (index: number): validator.Constraints => {
    return {
        punch: {
            presence: {
                allowEmpty: false,
                message: errLocale.translate(errLocale.keys.E08158, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
            },
            csvEnumFormat: {
                allow: csvFormat4Endpoint.PUNCH,
                message: errLocale.translate(errLocale.keys.E08159, {
                    index: index,
                    row: csvFormat4Endpoint.COL_IDX_PUNCH + 1,
                }).message,
            },
        },
    };
};

export const newPunchValidations = (): validator.ValidationOutput => {
    return {
        punch: null,
    };
};

// 日付セパレーターの確認
export const dateSeparatorValidations = (): validator.Constraints => {
    return {
        dateSeparator: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.DATE_SEPARATOR,
                message: 'error', // flag用のメッセージ（使用しない）
            },
        },
    };
};

export const newDateSeparatorValidations = (): validator.ValidationOutput => {
    return {
        dateSeparator: null,
    };
};

// リスト表示列数の確認
export const numberOfListDisplayColumnsValidations = (): validator.Constraints => {
    return {
        numberOfListDisplayColumns: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.NUMBER_OF_LIST_DISPLAY_COLUMNS,
                message: 'error',
            },
        },
    };
};

export const newNumberOfListDisplayColumnsValidations = (): validator.ValidationOutput => {
    return {
        numberOfListDisplayColumns: null,
    };
};

// 原稿厚さの確認
export const documentThicknessValidations = (): validator.Constraints => {
    return {
        documentThickness: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.DOCUMENT_THICKNESS,
                message: 'error',
            },
        },
    };
};

export const newDocumentThicknessValidations = (): validator.ValidationOutput => {
    return {
        documentThickness: null,
    };
};

// サイズ混載モードの確認
export const mixedSizeModeValidations = (): validator.Constraints => {
    return {
        mixedSizeMode: {
            csvEnumFormat: {
                allow: csvFormat4Endpoint.MIXED_SIZE_MODE,
                message: 'error',
            },
        },
    };
};

export const newMixedSizeModeValidations = (): validator.ValidationOutput => {
    return {
        mixedSizeMode: null,
    };
};

// 画像切出し
export const doCutImageValidations = (
    index: number,
    doCutImage: string,
    outputType: string,
    docSide: string,
    batch: string,
    sadf: string,
    size: string,
    docSummary: string,
    resolution: string,
    colorType: string,
) => {
    if (!['TRUE', 'FALSE'].includes(doCutImage.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08202, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_DO_CUT_IMAGE + 1,
        }).message;
    }

    if ('FALSE' === doCutImage.toUpperCase()) return '';

    if ([csvFormat4Endpoint.FORMAT[2], csvFormat4Endpoint.FORMAT[3], csvFormat4Endpoint.FORMAT[5]].includes(outputType.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08196, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_FORMAT + 1,
        }).message;
    }

    if (![csvFormat4Endpoint.DOCUMENT[0]].includes(docSide)) {
        return errLocale.translate(errLocale.keys.E08198, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_DOCUSIDE + 1,
        }).message;
    }

    if (['TRUE'].includes(batch.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08200, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_SEVENAL + 1,
        }).message;
    }

    if (['TRUE'].includes(sadf.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08201, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_SADF + 1,
        }).message;
    }

    if ([csvFormat4Endpoint.SIZE[0].toUpperCase(), csvFormat4Endpoint.SIZE[11].toUpperCase()].includes(size.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08194, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_SIZE + 1,
        }).message;
    }

    if ([csvFormat4Endpoint.DOCSUMMARY[1]].includes(zenkaku2Hankaku(docSummary))) {
        return errLocale.translate(errLocale.keys.E08199, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_DOCSUMMARY + 1,
        }).message;
    }

    if (['200dpi', '400dpi', '600dpi'].includes(resolution.toLowerCase())) {
        return errLocale.translate(errLocale.keys.E08195, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_RESOLUTION + 1,
        }).message;
    }

    if (![csvFormat4Endpoint.TYPE[4], csvFormat4Endpoint.TYPE[5], csvFormat4Endpoint.TYPE[6], csvFormat4Endpoint.TYPE[8], csvFormat4Endpoint.TYPE[9]].includes(colorType.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08197, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_TYPE + 1,
        }).message;
    }

    return '';
};

//天地識別
export const verticalJudgeValidations = (index: number, verticalJudge: string, outputType: string, doCutImage: string) => {
    if (!['TRUE', 'FALSE'].includes(verticalJudge.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08238, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_VERTICAL_JUDGE + 1,
        }).message;
    }

    if ('FALSE' === verticalJudge.toUpperCase()) return '';

    return '';
};

//余白付きスキャン
export const largerScanValidations = (index: number, largerScan: string, size: string) => {
    if (!['TRUE', 'FALSE'].includes(largerScan.toUpperCase())) {
        return errLocale.translate(errLocale.keys.E08240, {
            index: index + 1,
            row: csvFormat4Endpoint.COL_IDX_LARGER_SCAN + 1,
        }).message;
    }

    if ('FALSE' === largerScan.toUpperCase()) return '';

    return '';
};