import { Validate, ValidationOutput, Constraints } from '@/common/utils/validator';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';

const companyValidations = (): Constraints => {
    return {
        clientCompany: {
            length: {
                maximum: 128,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 128 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.companyName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        clientDepartment: {
            length: {
                maximum: 128,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 128 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.departmentName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        clientLastName: {
            length: {
                maximum: 100,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 100 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.lastName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        clientFirstName: {
            length: {
                maximum: 100,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 100 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.firstName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        clientMail: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.email) }),
            },
            userEmail: {
                message: locale.t(locale.keys.applicationList.validation.incorrectFormat),
            },
            length: {
                maximum: 54,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 54 }),
            },
        },
        clientPhone: {
            length: {
                maximum: 40,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 40 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.phoneNumber) }),
            },
            phoneNumber: {
                message: locale.t(locale.keys.applicationList.validation.incorrectFormat),
            },
        },
        otsSalesDepartment: {
            length: {
                maximum: 128,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 128 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.departmentName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        otsSalesLastName: {
            length: {
                maximum: 100,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 100 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.lastName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        otsSalesFirstName: {
            length: {
                maximum: 100,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 100 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.firstName) }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        otsSalesMail: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.email) }),
            },
            userEmail: {
                message: locale.t(locale.keys.applicationList.validation.incorrectFormat),
            },
            length: {
                maximum: 54,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 54 }),
            },
        },
        otsSalesPhone: {
            length: {
                maximum: 40,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 40 }),
            },
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.applicationList.validation.requiredSometing, { str: locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.phoneNumber) }),
            },
            phoneNumber: {
                message: locale.t(locale.keys.applicationList.validation.incorrectFormat),
            },
        },
        otsEngineerFirstName: {
            length: {
                maximum: 100,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 100 }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        otsEngineerLastName: {
            length: {
                maximum: 100,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 100 }),
            },
            inputLimit: {
                message: locale.t(locale.keys.applicationList.validation.inputLimit),
            },
        },
        otsEngineerMail: {
            userEmail: {
                allowEmpty: true,
                message: locale.t(locale.keys.applicationList.validation.incorrectFormat),
            },
            length: {
                maximum: 54,
                message: locale.t(locale.keys.applicationList.validation.tooLong, { num: 54 }),
            },
        },
    };
};

export type EntryDetailValidate = {
    clientCompany: null | string;
    clientDepartment: null | string;
    clientLastName: null | string;
    clientFirstName: null | string;
    clientMail: null | string;
    clientPhone: null | string;
    otsSalesDepartment: null | string;
    otsSalesLastName: null | string;
    otsSalesFirstName: null | string;
    otsSalesMail: null | string;
    otsSalesPhone: null | string;
    otsEngineerFirstName: null | string;
    otsEngineerLastName: null | string;
    otsEngineerMail: null | string;
};

export const initValidation = {
    clientCompany: '',
    clientDepartment: '',
    clientLastName: '',
    clientFirstName: '',
    clientMail: '',
    clientPhone: '',
    otsSalesDepartment: '',
    otsSalesLastName: '',
    otsSalesFirstName: '',
    otsSalesMail: '',
    otsSalesPhone: '',
    otsEngineerFirstName: '',
    otsEngineerLastName: '',
    otsEngineerMail: '',
};

const NewCompanyValidation = (): ValidationOutput => {
    return {
        clientCompany: null,
        clientDepartment: null,
        clientLastName: null,
        clientFirstName: null,
        clientMail: null,
        clientPhone: null,
        otsSalesDepartment: null,
        otsSalesLastName: null,
        otsSalesFirstName: null,
        otsSalesMail: null,
        otsSalesPhone: null,
        otsEngineerFirstName: null,
        otsEngineerLastName: null,
        otsEngineerMail: null,
    };
};

export const validateInfo = (values: schema.V1ObjectsEntryIndex) => {
    const msg = Validate<schema.V1ObjectsEntryIndex>(values, companyValidations(), NewCompanyValidation);
    if (msg === null) {
        return { msg: initValidation, saveButtonDisabled: false };
    } else {
        return { msg: msg as EntryDetailValidate, saveButtonDisabled: true };
    }
};

export type EntryDetailProductsValidate = {
    products: null | string;
};

export const initProductsValidation: EntryDetailProductsValidate = {
    products: null,
};

// ネストしたobjectはvalidationInfoに組込むのが難しいため別で処理する
export const validateProducts = (values: schema.V1ObjectsEntryProducts) => {
    if (!values.easyFax && !values.qs) {
        return { msg: { products: '製品を1つ以上選択してください。' }, saveButtonDisabled: true };
    }
    return { msg: initProductsValidation, saveButtonDisabled: false };
};

export type EntryDetailCloudValidate = {
    cloud: null | string;
};

export const initCloudValidation: EntryDetailCloudValidate = {
    cloud: null,
};

// ネストしたobjectはvalidationInfoに組込むのが難しいため別で処理する
export const validateCloud = (values: schema.V1ObjectsEntryCloud) => {
    if (!values.box && !values.dropbox && !values.googleDrive && !values.googleShareDrive && !values.oneDrive && !values.sharePoint && !values.aiOcr) {
        return { msg: { cloud: 'サービスを1つ以上選択してください。' }, saveButtonDisabled: true };
    }
    return { msg: initCloudValidation, saveButtonDisabled: false };
};
