import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
    createStyles({
        actionButton: {
            marginRight: theme.spacing.unit * 2,
            '&& button': {
                textTransform: 'unset',
            },
        },
        okBtn: {
            color: '#ff7300',
            border: 'none',
        },
        body: {
            '& *': {
                fontSize: '1.25rem',
                fontWeight: 400,
            },
        },
        title: {
            '& h6': {
                fontWeight: 600,
                color: '#FFFFFF',
                fontSize: '0,875rem',
            },
        },
        closeButton: {
            color: '#FFFFFF',
            position: 'absolute',
            right: 0,
            top: 0,
        },
    });

export interface LicenseDialogBaseProps {
    callBack: (value?: string) => void;
    onClose: () => void;
    isOpen: boolean;
    title: string;
    renderChildren: () => JSX.Element;
    type?: 'alert' | 'confirm' | 'notice';
}

export interface Props extends WithStyles<typeof styles>, LicenseDialogBaseProps {}

const LicenseDialogBase: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    // 本文が空状態のコンポーネント表示を防ぐため
    if (!props.isOpen) return <></>;

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle
                id="alert-dialog-title"
                className={classes.title}
                style={{
                    textAlign: 'center',
                    backgroundColor: '#143b4e',
                    padding: '5px',
                    maxWidth: 'lg',
                }}
            >
                {props.title}
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.body}>{props.renderChildren()}</DialogContent>
        </Dialog>
    );
};

export default withStyles(styles)(LicenseDialogBase);
