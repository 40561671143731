import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';
import querystring from 'querystring';

// ライセンスアクティベート
export const licenseActivate = async (req: schema.V1LicensesActivateCreateRequest, auth: string): Promise<schema.V1LicensesActivateCreateResponse> => {
    const response = await axiosFactory.patch<schema.V1LicensesActivateCreateResponse>(`https://${consts.environment.api.hostname}/v1/licenses/activate`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
// ライセンス更新
export const licenseReplace = async (req: schema.V1LicensesReplaceRequest, auth: string): Promise<schema.V1LicensesReplaceResponse> => {
    const response = await axiosFactory.put<schema.V1LicensesReplaceResponse>(`https://${consts.environment.api.hostname}/v1/licenses/${req.licenseId}`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ライセンス発行
export const createLicenseOrder = async (req: schema.V1LicensesCreateRequest, auth: string): Promise<schema.V1ObjectsLicenseCreateRequestInfo> => {
    const response = await axiosFactory.post<schema.V1ObjectsLicenseCreateRequestInfo>(`https://${consts.environment.api.hostname}/v1/licenses`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// 製品・プラン一覧取得
export const indexLicensePlans = async (auth: string, offset?: number, limit?: number): Promise<schema.V1LicensesPlansIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
    });
    const response = await axiosFactory.get<schema.V1LicensesPlansIndexResponse>(`https://${consts.environment.api.hostname}/v1/licenses/plans?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
}

// ライセンス作成依頼一覧取得
export const indexOrderLicenses = async (auth: string, offset?: string, limit?: string): Promise<schema.V1LicensesRequestsIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
    });
    const response = await axiosFactory.get<schema.V1LicensesRequestsIndexResponse>(`https://${consts.environment.api.hostname}/v1/licenses/requests`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
}

// ライセンス一覧取得
export const indexLicenses = async (auth: string, licenseCreateRequestId: string, offset?: number, product?: string, limit?: number, target?: string): Promise<schema.V1LicensesListIndexActiveResponse | schema.V1LicensesListIndexNewResponse | schema.V1LicensesListIndexAddResponse> => {
    const query = querystring.stringify({
        licenseCreateRequestId: licenseCreateRequestId,
        offset,
        limit,
        product,
        target,
    });
    const response = await axiosFactory.get<schema.V1LicensesListIndexActiveResponse | schema.V1LicensesListIndexNewResponse | schema.V1LicensesListIndexAddResponse>(`https://${consts.environment.api.hostname}/v1/licenses/list?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
}