import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

// ライセンスアクティベート
export const licenseActivate = async (
    req: schema.V1LicensesActivateCreateRequest,
    auth: string,
): Promise<schema.V1LicensesActivateCreateResponse> => {
    const response = await axiosFactory.patch<schema.V1LicensesActivateCreateResponse>(
        `https://${consts.environment.api.hostname}/v1/licenses/activate`,
        req,
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};

// ライセンス更新
export const licenseReplace = async (
    req: schema.V1LicensesReplaceRequest,
    auth: string,
): Promise<schema.V1LicensesReplaceResponse> => {
    const response = await axiosFactory.put<schema.V1LicensesReplaceResponse>(
        `https://${consts.environment.api.hostname}/v1/licenses/${req.licenseId}`,
        req,
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};

// ライセンスチェック
export const licenseCheck = async (
    mfpNumber: string,
    product: string,
    amount: number,
): Promise<schema.V1LicensesCheckShowResponse> => {
    const response = await axiosFactory.get<schema.V1LicensesCheckShowResponse>(
        `https://${consts.environment.api.hostname}/v1/licenses/check`,
        {
            params: { mfpNumber, product, amount },
        },
    );
    return response.data;
};

// ライセンス削除
export const licenseDelete = async (licenseId: string, precheck: boolean, auth: string): Promise<void> => {
    await axiosFactory.delete<string>(`https://${consts.environment.api.hostname}/v1/licenses/${licenseId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        params: { precheck },
    });
};
