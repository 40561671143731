// @ts-nocheck
import * as schema from '@/bundles/schema/typescript/schema';
import encoding from 'encoding-japanese';
import { csvFormat4Endpoint } from '@/common/constants/csvFormat';
import { convertAbstractProperty } from '@/common/constants/clientApp';

/**
 * データの整形
 * ダブルクォーテーションのエスケープ処理 & 全角項目・自由記述項目をダブルクォーテーションで囲む
 */
const dataShaping = (csvData: string[], tenant: schema.Brand) => {
    // ダブルクォーテーションのエスケープ処理
    if (csvData[csvFormat4Endpoint.COL_IDX_NAME].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_NAME] = csvData[csvFormat4Endpoint.COL_IDX_NAME].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_SITE] && csvData[csvFormat4Endpoint.COL_IDX_SITE].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_SITE] = csvData[csvFormat4Endpoint.COL_IDX_SITE].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_LIBRARY] && csvData[csvFormat4Endpoint.COL_IDX_LIBRARY].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_LIBRARY] = csvData[csvFormat4Endpoint.COL_IDX_LIBRARY].replaceAll('"', '""');
    }
    // ディレクトリ名に「"」は使えないのでエスケープは要らない
    // 固定文字に「"」は使えないので固定文字のエスケープは要らない
    if (csvData[csvFormat4Endpoint.COL_IDX_WORD1] && csvData[csvFormat4Endpoint.COL_IDX_WORD1].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_WORD1] = csvData[csvFormat4Endpoint.COL_IDX_WORD1].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_WORD2] && csvData[csvFormat4Endpoint.COL_IDX_WORD2].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_WORD2] = csvData[csvFormat4Endpoint.COL_IDX_WORD2].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_TITLE1] && csvData[csvFormat4Endpoint.COL_IDX_TITLE1].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_TITLE1] = csvData[csvFormat4Endpoint.COL_IDX_TITLE1].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_TITLE2] && csvData[csvFormat4Endpoint.COL_IDX_TITLE2].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_TITLE2] = csvData[csvFormat4Endpoint.COL_IDX_TITLE2].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_TITLE3] && csvData[csvFormat4Endpoint.COL_IDX_TITLE3].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_TITLE3] = csvData[csvFormat4Endpoint.COL_IDX_TITLE3].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_TITLE4] && csvData[csvFormat4Endpoint.COL_IDX_TITLE4].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_TITLE4] = csvData[csvFormat4Endpoint.COL_IDX_TITLE4].replaceAll('"', '""');
    }
    if (csvData[csvFormat4Endpoint.COL_IDX_PASSWORD] && csvData[csvFormat4Endpoint.COL_IDX_PASSWORD].match(/"/)) {
        csvData[csvFormat4Endpoint.COL_IDX_PASSWORD] = csvData[csvFormat4Endpoint.COL_IDX_PASSWORD].replaceAll('"', '""');
    }
    // 印刷対象形式に「"」は使えないのでエスケープは要らない

    // ダブルクォーテーションで囲む
    csvData[csvFormat4Endpoint.COL_IDX_NAME] = '"' + csvData[csvFormat4Endpoint.COL_IDX_NAME] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_SITE] = '"' + csvData[csvFormat4Endpoint.COL_IDX_SITE] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_LIBRARY] = '"' + csvData[csvFormat4Endpoint.COL_IDX_LIBRARY] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_DIRECTORY] = '"' + csvData[csvFormat4Endpoint.COL_IDX_DIRECTORY] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_FIXED] = '"' + csvData[csvFormat4Endpoint.COL_IDX_FIXED] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_WORD1] = '"' + csvData[csvFormat4Endpoint.COL_IDX_WORD1] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_WORD2] = '"' + csvData[csvFormat4Endpoint.COL_IDX_WORD2] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_TITLE1] = '"' + csvData[csvFormat4Endpoint.COL_IDX_TITLE1] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_TITLE2] = '"' + csvData[csvFormat4Endpoint.COL_IDX_TITLE2] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_TITLE3] = '"' + csvData[csvFormat4Endpoint.COL_IDX_TITLE3] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_TITLE4] = '"' + csvData[csvFormat4Endpoint.COL_IDX_TITLE4] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_PASSWORD] = '"' + csvData[csvFormat4Endpoint.COL_IDX_PASSWORD] + '"';
    csvData[csvFormat4Endpoint.COL_IDX_PRINTFORMAT] = '"' + csvData[csvFormat4Endpoint.COL_IDX_PRINTFORMAT] + '"';

    // 利用可能アプリケーションのフォーマット
    if (csvData[csvFormat4Endpoint.COL_IDX_PERMITED_APPLICATIONS]) {
        csvData[csvFormat4Endpoint.COL_IDX_PERMITED_APPLICATIONS] = csvData[csvFormat4Endpoint.COL_IDX_PERMITED_APPLICATIONS].map((app) => convertAbstractProperty(app, tenant)).join('|');
    }

    // 利用可能複合機のフォーマット
    if (csvData[csvFormat4Endpoint.COL_IDX_PERMITED_MFPS]) {
        csvData[csvFormat4Endpoint.COL_IDX_PERMITED_MFPS] = csvData[csvFormat4Endpoint.COL_IDX_PERMITED_MFPS].join('|');
    }

    return csvData;
};

export const exportCsv = (result: schema.V1ObjectsEndpointsCollectiveSetting[], lang: string, tenant: schema.Brand): { data: string[][]; blob: Blob } => {
    let datas = [];
    if (lang === schema.Language.Ja) {
        // ヘッダー定義　日本語
        const docName = tenant === schema.Brand.Ricoh ? "Speedoc" : "Quickスキャン";
        datas = [
            [
                'ユーザーEメールアドレス',
                '通常業務名',
                '通常業務のアイコン',
                'ラベル色',
                'スキャン機能/ファクス機能可否',
                'プリント機能可否',
                'Eメール通知：スプールの操作完了時',
                'Eメール通知：スプールのエラー発生時',
                '接続方法',
                '認可ID',
                'サイト名',
                'ライブラリ名',
                'ディレクトリ名',
                docName + 'のファイル名形式',
                '日付_年',
                '日付_月',
                '日付_日',
                '日付_時分秒',
                '固定文字',
                '任意文字未入力時読み取り',
                'セパレーター',
                '複数枚原稿連番付け',
                '表示形式ロック',
                '単語帳1名称',
                '単語1-1',
                '単語1-2',
                '単語1-3',
                '単語1-4',
                '単語1-5',
                '単語1-6',
                '単語1-7',
                '単語1-8',
                '単語1-9',
                '単語1-10',
                '単語1-11',
                '単語1-12',
                '単語1-13',
                '単語1-14',
                '単語1-15',
                '単語1-16',
                '単語1-17',
                '単語1-18',
                '単語1-19',
                '単語1-20',
                '単語1-21',
                '単語1-22',
                '単語1-23',
                '単語1-24',
                '単語1-25',
                '単語1-26',
                '単語1-27',
                '単語1-28',
                '単語1-29',
                '単語1-30',
                '単語1-31',
                '単語1-32',
                '単語1-33',
                '単語1-34',
                '単語1-35',
                '単語1-36',
                '単語1-37',
                '単語1-38',
                '単語1-39',
                '単語1-40',
                '単語1-41',
                '単語1-42',
                '単語1-43',
                '単語1-44',
                '単語1-45',
                '単語帳2名称',
                '単語2-1',
                '単語2-2',
                '単語2-3',
                '単語2-4',
                '単語2-5',
                '単語2-6',
                '単語2-7',
                '単語2-8',
                '単語2-9',
                '単語2-10',
                '単語2-11',
                '単語2-12',
                '単語2-13',
                '単語2-14',
                '単語2-15',
                '単語2-16',
                '単語2-17',
                '単語2-18',
                '単語2-19',
                '単語2-20',
                '単語2-21',
                '単語2-22',
                '単語2-23',
                '単語2-24',
                '単語2-25',
                '単語2-26',
                '単語2-27',
                '単語2-28',
                '単語2-29',
                '単語2-30',
                '単語2-31',
                '単語2-32',
                '単語2-33',
                '単語2-34',
                '単語2-35',
                '単語2-36',
                '単語2-37',
                '単語2-38',
                '単語2-39',
                '単語2-40',
                '単語2-41',
                '単語2-42',
                '単語2-43',
                '単語2-44',
                '単語2-45',
                'フォルダ表示階層数',
                'フォルダアイコン表示',
                '自動選択表示',
                'フォルダ表示形式',
                '階層タイトル名1',
                '階層タイトル名2',
                '階層タイトル名3',
                '階層タイトル名4',
                '通常業務パスワード',
                'スキャン時のプレビュー表示',
                'スキャン開始前の上書き警告',
                'ファイル名衝突時',
                '不定形サイズ1縦',
                '不定形サイズ1横',
                '不定形サイズ2縦',
                '不定形サイズ2横',
                '不定形サイズ3縦',
                '不定形サイズ3横',
                '出力ファイル形式',
                '原稿セット方向',
                '原稿面',
                '大量原稿',
                'SADF',
                '読み取りサイズ',
                'サイズ混載',
                '集約',
                '解像度',
                '解像度ロック',
                '原稿種類',
                '原稿種類ロック',
                '濃度',
                '自動濃度',
                '枠消去',
                '枠消去(同一幅)サイズ',
                '枠消去(個別指定上)サイズ',
                '枠消去(個別指定下)サイズ',
                '枠消去(個別指定左)サイズ',
                '枠消去(個別指定右)サイズ',
                'センター消去サイズ',
                '白紙ページ削除',
                '印刷対象形式',
                '印刷条件：カラー/白黒',
                '印刷条件：部数',
                '印刷条件：給紙トレイ',
                '印刷条件：片面/両面',
                '印刷条件：集約',
                '印刷条件：集約方向',
                '印刷条件：集約仕切り線',
                '印刷条件：変倍',
                '印刷条件：ソート',
                '印刷条件：ステープル',
                '印刷条件：パンチ',
                '日付セパレーター',
                'リスト表示列数',
                '原稿厚さ',
                'サイズ混載モード',
                '画像切出し',
                'アプリケーション利用制限設定',
                '利用可能アプリケーション',
                '複合機利用制限設定',
                '利用可能複合機',
                '天地識別',
                '余白付きスキャン',
            ],
        ];
        // サンプルデータ　日本語
        // リコーの場合は単語帳1の13～15が空欄になる
        if (tenant === schema.Brand.Ricoh) {
            datas.push([
                '(記載例)user1@covas.com',
                '"なまえ"',
                'folder',
                'white',
                'true',
                'true',
                'false',
                'true',
                'googledrive',
                'user1@covas.com',
                '""',
                '""',
                '"/"',
                '%Docname.%Date',
                'true',
                'true',
                'true',
                'true',
                '""',
                'true',
                '.',
                '枝番',
                'false',
                '"単語帳1"',
                '見積書',
                '注文書',
                '請求書',
                '報告書',
                '申請書',
                '受領書',
                '納品書',
                '契約書',
                '領収書',
                '議事録',
                '稟議書',
                '提案書',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '"単語帳2"',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '2階層',
                'true',
                'false',
                'カラム',
                '"フォルダ1"',
                '"フォルダ2"',
                '"フォルダ3"',
                '"フォルダ4"',
                '""',
                'true',
                'true',
                '子番',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                'MULTI-PDF',
                'left',
                '片面',
                'false',
                'false',
                'Auto',
                'false',
                '1',
                '200dpi',
                'false',
                'ACS',
                'false',
                '5',
                'true',
                '枠消去しない',
                '10',
                '10',
                '10',
                '10',
                '10',
                '10',
                'false',
                '"PDF"',
                'PRINT_MONO',
                '1',
                'TRAY_AUTO',
                '片面',
                'NONE',
                'Z',
                'false',
                'NONE',
                'SORT_NORMAL',
                'NONE',
                'NONE',
                'c',
                '1',
                '標準',
                '同幅',
                'false',
                'false',
                '',
                'false',
                '',
                'false',
                'false',
            ]);
        } else {
            datas.push([
                '(記載例)user1@covas.com',
                '"なまえ"',
                'folder',
                'white',
                'true',
                'true',
                'false',
                'true',
                'googledrive',
                'user1@covas.com',
                '""',
                '""',
                '"/"',
                '%Docname.%Date',
                'true',
                'true',
                'true',
                'true',
                '""',
                'true',
                '.',
                '枝番',
                'false',
                '"単語帳1"',
                '見積書',
                '注文書',
                '請求書',
                '報告書',
                '申請書',
                '受領書',
                '納品書',
                '契約書',
                '領収書',
                '議事録',
                '稟議書',
                '提案書',
                '会議資料',
                '日報',
                '指示書',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '"単語帳2"',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '2階層',
                'true',
                'false',
                'カラム',
                '"フォルダ1"',
                '"フォルダ2"',
                '"フォルダ3"',
                '"フォルダ4"',
                '""',
                'true',
                'true',
                '子番',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                'MULTI-PDF',
                'left',
                '片面',
                'false',
                'false',
                'Auto',
                'false',
                '1',
                '200dpi',
                'false',
                'ACS',
                'false',
                '5',
                'true',
                '枠消去しない',
                '10',
                '10',
                '10',
                '10',
                '10',
                '10',
                'false',
                '"PDF"',
                'PRINT_MONO',
                '1',
                'TRAY_AUTO',
                '片面',
                'NONE',
                'Z',
                'false',
                'NONE',
                'SORT_NORMAL',
                'NONE',
                'NONE',
                'c',
                '1',
                '標準',
                '同幅',
                'false',
                'false',
                '',
                'false',
                '',
                'false',
                'false',
            ]);
        }
    } else {
        // ヘッダー定義　英語
        datas = [
            [
                "User's email address",
                'Endpoint name',
                'Icon name',
                'Button color',
                'Uploadable',
                'Downloadable',
                'Email notification:spooler is completed',
                'Email notification:spooler error',
                'Cloud type',
                'Cloud account',
                'Site',
                'Library',
                'Directory',
                'DocName format',
                'Date format year',
                'Date format month',
                'Date format day',
                'Date format time',
                'Fixed',
                'File optional',
                'Separator',
                'Sequence type',
                'DocName format lock change',
                'Word list name01',
                'Word list01-01',
                'Word list01-02',
                'Word list01-03',
                'Word list01-04',
                'Word list01-05',
                'Word list01-06',
                'Word list01-07',
                'Word list01-08',
                'Word list01-09',
                'Word list01-10',
                'Word list01-11',
                'Word list01-12',
                'Word list01-13',
                'Word list01-14',
                'Word list01-15',
                'Word list01-16',
                'Word list01-17',
                'Word list01-18',
                'Word list01-19',
                'Word list01-20',
                'Word list01-21',
                'Word list01-22',
                'Word list01-23',
                'Word list01-24',
                'Word list01-25',
                'Word list01-26',
                'Word list01-27',
                'Word list01-28',
                'Word list01-29',
                'Word list01-30',
                'Word list01-31',
                'Word list01-32',
                'Word list01-33',
                'Word list01-34',
                'Word list01-35',
                'Word list01-36',
                'Word list01-37',
                'Word list01-38',
                'Word list01-39',
                'Word list01-40',
                'Word list01-41',
                'Word list01-42',
                'Word list01-43',
                'Word list01-44',
                'Word list01-45',
                'Word list name02',
                'Word list02-01',
                'Word list02-02',
                'Word list02-03',
                'Word list02-04',
                'Word list02-05',
                'Word list02-06',
                'Word list02-07',
                'Word list02-08',
                'Word list02-09',
                'Word list02-10',
                'Word list02-11',
                'Word list02-12',
                'Word list02-13',
                'Word list02-14',
                'Word list02-15',
                'Word list02-16',
                'Word list02-17',
                'Word list02-18',
                'Word list02-19',
                'Word list02-20',
                'Word list02-21',
                'Word list02-22',
                'Word list02-23',
                'Word list02-24',
                'Word list02-25',
                'Word list02-26',
                'Word list02-27',
                'Word list02-28',
                'Word list02-29',
                'Word list02-30',
                'Word list02-31',
                'Word list02-32',
                'Word list02-33',
                'Word list02-34',
                'Word list02-35',
                'Word list02-36',
                'Word list02-37',
                'Word list02-38',
                'Word list02-39',
                'Word list02-40',
                'Word list02-41',
                'Word list02-42',
                'Word list02-43',
                'Word list02-44',
                'Word list02-45',
                'Folder depth',
                'Display folder icon',
                'Display auto selection',
                'Display form',
                'Title1 of folder',
                'Title2 of folder',
                'Title3 of folder',
                'Title4 of folder',
                'Endpoint Password',
                'Display scan preview',
                'Overwrite warning before starting scanning',
                'Conflict file name',
                'Size1 Height',
                'Size1 Width',
                'Size2 Height',
                'Size2 Width',
                'Size3 Height',
                'Size3 Width',
                'Save file format',
                'Paper orientation',
                'Paper side',
                'Sevenal pages docs',
                'SADF',
                'Paper size',
                'Mixed size',
                'Summary',
                'Resolution',
                'Resolution Lock change',
                'Document type',
                'Document type Lock change',
                'Gray level',
                'Gray level automatically',
                'Frame erase',
                'Specifying the same width',
                'Frame erase:Up',
                'Frame erase:Under',
                'Frame erase:Left',
                'Frame erase:Right',
                'Erase Center',
                'Remove blank pages',
                'Print object format',
                'Color/Black white',
                'Copies',
                'Paper feed tray',
                '1 side/ 2 sides',
                'Summary',
                'Presentation direction',
                'Display Divider Line',
                'Specifying Paper Size',
                'Sort method',
                'Staple',
                'Punch',
                'Date Separator',
                'Number Of List Display Columns',
                'Document Thickness',
                'Mixed Size Mode',
                'Image Cropping',
                'Application Permission Setting',
                'Permitted Applications',
                'Mfp Permission Setting',
                'Permitted Mfps',
                'Orientation detection',
                'Scan with margins',
            ],
        ];
        // サンプルデータ　英語
        // リコーの場合はWordbook1(単語帳1)の13～15が空欄になる
        if (tenant === schema.Brand.Ricoh) {
            datas.push([
                '(Example)user1@covas.com',
                '"name"',
                'folder',
                'white',
                'true',
                'true',
                'false',
                'true',
                'googledrive',
                'user1@covas.com',
                '""',
                '""',
                '"/"',
                '%Docname.%Date',
                'true',
                'true',
                'true',
                'true',
                '""',
                'true',
                '.',
                '枝番',
                'false',
                '"Wordbook1"',
                'Quotation',
                'Order Form',
                'Bill',
                'Report',
                'Application Form',
                'Delivery note',
                'Delivery slips',
                'Contracts',
                'Receipts',
                'Minutes',
                'Approval documents',
                'Proposals',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '"Wordbook2"',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '2階層',
                'true',
                'false',
                'カラム',
                '"Folder1"',
                '"Folder2"',
                '"Folder3"',
                '"Folder4"',
                '""',
                'true',
                'true',
                '子番',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                'MULTI-PDF',
                'left',
                '片面',
                'false',
                'false',
                'Auto',
                'false',
                '1',
                '200dpi',
                'false',
                'ACS',
                'false',
                '5',
                'true',
                '枠消去しない',
                '10',
                '10',
                '10',
                '10',
                '10',
                '10',
                'false',
                '"PDF"',
                'PRINT_MONO',
                '1',
                'TRAY_AUTO',
                '片面',
                'NONE',
                'Z',
                'false',
                'NONE',
                'SORT_NORMAL',
                'NONE',
                'NONE',
                'c',
                '1',
                '標準',
                '同幅',
                'false',
                'false',
                '',
                'false',
                '',
                'false',
                'false',
            ]);
        } else {
            datas.push([
                '(Example)user1@covas.com',
                '"name"',
                'folder',
                'white',
                'true',
                'true',
                'false',
                'true',
                'googledrive',
                'user1@covas.com',
                '""',
                '""',
                '"/"',
                '%Docname.%Date',
                'true',
                'true',
                'true',
                'true',
                '""',
                'true',
                '.',
                '枝番',
                'false',
                '"Wordbook1"',
                'Quotation',
                'Order Form',
                'Bill',
                'Report',
                'Application Form',
                'Delivery note',
                'Delivery slips',
                'Contracts',
                'Receipts',
                'Minutes',
                'Approval documents',
                'Proposals',
                'Meeting materials',
                'Daily reports',
                'Instructions',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '"Wordbook2"',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '2階層',
                'true',
                'false',
                'カラム',
                '"Folder1"',
                '"Folder2"',
                '"Folder3"',
                '"Folder4"',
                '""',
                'true',
                'true',
                '子番',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                'MULTI-PDF',
                'left',
                '片面',
                'false',
                'false',
                'Auto',
                'false',
                '1',
                '200dpi',
                'false',
                'ACS',
                'false',
                '5',
                'true',
                '枠消去しない',
                '10',
                '10',
                '10',
                '10',
                '10',
                '10',
                'false',
                '"PDF"',
                'PRINT_MONO',
                '1',
                'TRAY_AUTO',
                '片面',
                'NONE',
                'Z',
                'false',
                'NONE',
                'SORT_NORMAL',
                'NONE',
                'NONE',
                'c',
                '1',
                '標準',
                '同幅',
                'false',
                'false',
                '',
                'false',
                '',
                'false',
                'false',
            ]);
        }
    }

    const dataTemp: schema.V1ObjectsEndpointsCollectiveSetting = {
        invitationEmail: '',
        endpointName: '',
        iconName: '',
        buttonColor: '',
        uploadable: '',
        downloadable: '',
        completeNotification: '',
        errorNotification: '',
        service: '',
        account: '',
        site: '',
        library: '',
        directory: '',
        itemList: '',
        dateFormatYear: '',
        dateFormatMonth: '',
        dateFormatDay: '',
        dateFormatTime: '',
        fixed: '',
        fileOptional: '',
        separator: '',
        sequenceType: '',
        docnameFormatLock: '',
        wordListName1: '',
        wordList1: [],
        wordListName2: '',
        wordList2: [],
        folderDepth: '',
        folderIconVisible: '',
        autoSelect: '',
        folderDisplayFormat: '',
        folderTitle1: '',
        folderTitle2: '',
        folderTitle3: '',
        folderTitle4: '',
        endpointPassword: '',
        scanPreview: '',
        scanOverwriteAlert: '',
        overwrite: '',
        preset1Vertical: '',
        preset1Horizontal: '',
        preset2Vertical: '',
        preset2Horizontal: '',
        preset3Vertical: '',
        preset3Horizontal: '',
        outputType: '',
        rotate: '',
        paperSide: '',
        batch: '',
        sadf: '',
        paperSize: '',
        mixedSize: '',
        aggregation: '',
        resolution: '',
        resolutionLock: '',
        colorType: '',
        colorTypeLock: '',
        density: '',
        autoDensity: '',
        eraseBorder: '',
        eraseBorderWidthSame: '',
        eraseBorderWidthTop: '',
        eraseBorderWidthBottom: '',
        eraseBorderWidthLeft: '',
        eraseBorderWidthRight: '',
        eraseCenterWidth: '',
        omitBlankPage: '',
        printDocumentFormat: '',
        printColor: '',
        copies: '',
        mediaTray: '',
        sides: '',
        numberUp: '',
        presentationDirection: '',
        separatorLine: '',
        mediaSizeName: '',
        sheetCollate: '',
        staple: '',
        punch: '',
        dateSeparator: '',
        numberOfListDisplayColumns: '',
        documentThickness: '',
        mixedSizeMode: '',
        doCutImage: '',
    };

    // レスポンスをdatasに追加。単語リストは配列展開。
    for (let n = 0; n < result.length; n++) {
        const data = result[n]; // エンドポイント1件分のデータ
        const dataList: schema.V1ObjectsEndpointsCollectiveSetting = { ...dataTemp, ...data }; // 不足項目を空文字で補った１件分のデータ
        if (dataList['buttonColor'] === csvFormat4Endpoint.LABELCOLOR[13]) dataList['buttonColor'] = csvFormat4Endpoint.LABELCOLOR[12]; // dark_khakiを置き換え
        const wordList1: string[] = dataList['wordList1'] || [];
        const wordList2: string[] = dataList['wordList2'] || [];
        delete dataList['wordList1'];
        delete dataList['wordList2']; // 単語帳データだけ抜き出したら元データからは消しておく
        const csvData: string[] = Object.values(dataList); // objectを値だけの配列(単語帳抜き)に変換
        const wordList1r = wordList1.concat(new Array(csvFormat4Endpoint.WORDLIST_MAX - wordList1.length));
        const wordList2r = wordList2.concat(new Array(csvFormat4Endpoint.WORDLIST_MAX - wordList2.length)); // 単語帳の空欄部分を作成
        csvData.splice(csvFormat4Endpoint.COL_IDX_WORD1_1, 0, ...wordList1r);
        csvData.splice(csvFormat4Endpoint.COL_IDX_WORD2_1, 0, ...wordList2r); // 単語帳を書き込みデータに挿入

        datas.push(dataShaping(csvData, tenant));
    }

    //作った二次元配列をCSV文字列に直す。
    let ctrlCode = '\n'; // 改行コードのデフォルトはLF
    if (window.navigator.userAgent.toLocaleLowerCase().indexOf('windows nt') !== -1) {
        ctrlCode = '\r\n'; // WindowsのときだけCR+LF
    }
    let csv_string = '';
    for (let d of datas) {
        csv_string += d.join(',');
        csv_string += ctrlCode;
    }

    // 文字コード変換系
    const str = encoding.stringToCode(csv_string);
    const sjisString = encoding.convert(str, 'SJIS');
    const u8a = new Uint8Array(sjisString);

    //CSVのバイナリデータを作る
    let blob = new Blob([u8a], { type: 'text/csv' });

    return { data: datas, blob: blob };
};
