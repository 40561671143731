import environment from '@/common/constants/environment';
import theme from '@/common/css/theme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import * as Sentry from '@sentry/browser';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

/**
 * Initialize Sentry at the very beginning of entire process.
 */
Sentry.init({
    dsn: ['develop', 'test'].includes(environment.env!) ? undefined : environment.webapp.admin.sentryDsn,
    debug: ['develop', 'test'].includes(environment.env!) ? true : false,
    release: environment.gitRevision,
    environment: environment.env,
});

const Root: React.FC = () => (
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </BrowserRouter>
);

ReactDOM.render(<Root />, document.getElementById('root'));
